<template>
  <div class="bg-white">
    <!-- circle steps -->
    <div class="w-11/12 mx-auto">
      <div class="progress-wrap mt-7 mx-auto">
        <div class="progress" id="progress"></div>
        <div class="step active font-regular">1</div>
        <div class="step font-regular">2</div>
        <div class="step font-regular">3</div>
      </div>
    </div>
    <!-- Step 1 -->
    <div id="firstStep" class="w-full">
      <h3
        class="w-11/12 lg:w-3/12 mx-auto text-center text-lg lg:text-xl font-regular"
      >
        Please enter a name and select a brand for this quote
      </h3>
      <div class="form-group mt-4 w-10/12 mx-auto">
        <label
          for="nameStep"
          class="form-label inline-block mb-2 color-grey font-regular px-3 text-md "
          >Name:</label
        >
        <input
          v-model="quote_name"
          autocomplete="off"
          type="text"
          class="form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
          id="nameStep"
          v-bind:class="{
            'form-control': true,
            'is-invalid': quote_name == null && already_submitted,
          }"
        />
        <div class="invalid-feedback">Enter a name</div>
        <label
          for="brandStep"
          class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-lg mt-3 lg:mt-8"
          >Brand:</label
        >
        <select
          v-model="brand_id"
          type="select"
          class="form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
          id="brandStep"
          v-bind:class="{
            'form-control': true,
            'is-invalid': brand_id == null && already_submitted,
          }"
        >
          <option
            v-for="brand in brands"
            v-bind:key="brand.id"
            :value="brand.id"
          >
            {{ brand.name }}
          </option>
        </select>
        <div class="invalid-feedback">Select type of quote</div>
        <label
          for="quoteStep"
          class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-lg mt-3 lg:mt-8"
          >Quote type:</label
        >
        <select
          v-model="quote_type"
          type="select"
          class="form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
          id="quoteStep"
          v-bind:class="{
            'form-control': true,
            'is-invalid': quote_type == null && already_submitted,
          }"
        >
          <option value="hire_purchase">Hire purchase</option>
          <option value="lease">Lease</option>
        </select>
        <div class="invalid-feedback">Select a qutoe type</div>
      </div>
      <div class="fixed bottom-0 mt-10 w-full bg-secondary">
        <div class="w-11/12 lg:w-10/12 mx-auto flex justify-end items-center">
          <button
            @click="submit"
            class="btn-secondary text-lg lg:text-xl rounded-full py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
            id="next"
          >
            Next
            <i class="fas fa-chevron-circle-right pl-3 color-text"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandService from "../../../services/af-quote/BrandService";

export default {
  name: "name-step",

  data() {
    return {
      quote_name: null,
      brand_id: null,
      quote_type: null,
      brands: null,
      already_submitted: false,
    };
  },

  async created() {
    let brand_service = new BrandService();
    this.brands = await brand_service.getUserBrands();
  },

  methods: {
    submit() {
      this.already_submitted = true;
      if (
        this.quote_name != null &&
        this.brand_id != null &&
        this.quote_type != null
      ) {
        this.$emit("qname", [this.quote_name, this.brand_id, this.quote_type]);
        this.$emit("next");
      }
    },
    setQuote(name, brand_id, quote_type) {
      this.quote_name = name;
      this.brand_id = brand_id;
      this.quote_type = quote_type;
    },
  },
};
</script>
