<template>
  <div v-if="monthly_history != null" class="p-2 rounded-t-lg text-left bg-color overflow-x-auto">
    <table class="min-w-full">
      <thead>
        <tr :class="{'text-xs': pdf, 'py-0': pdf}" class="border-b">
          <th class="px-4 py-2 text-sm lg:text-lg font-semibold text-gray-900">Month</th>
          <th class="px-4 py-2 text-sm lg:text-lg font-semibold text-gray-900">Payments</th>
          <th class="px-4 py-2 text-sm lg:text-lg font-semibold text-gray-900">Interest</th>
          <th class="px-4 py-2 text-sm lg:text-lg font-semibold text-gray-900">Capital Balance</th>
          <th class="px-4 py-2 text-sm lg:text-lg font-semibold text-gray-900">Balance</th>
        </tr>
      </thead>
      <tbody :class="{'text-xs': pdf, 'py-0': pdf}">
        <tr v-for="(month, index) in monthly_history" v-bind:key="month.month" :style="functionmB(index)" class="border-t">
          <td class="px-4 py-2 text-gray-900">{{ month.month }}</td>
          <td class="px-4 py-2 text-gray-900">
            {{
              formatNumber(
                parseFloat(month.payments) +
                (quote_type != "lease" || month.payments == 0
                  ? 0
                  : month.month == 0
                    ? lease_vat * parseFloat(q_data.advances)
                    : lease_vat)
              )
            }}
          </td>
          <td class="px-4 py-2 text-gray-900">{{ formatNumber(month.interest) }}</td>
          <td class="px-4 py-2 text-gray-900">{{ formatNumber(month.capital_balance) }}</td>
          <td class="px-4 py-2 text-gray-900">{{ formatNumber(month.balance) }}</td>
        </tr>
        <tr v-if="index != 0 && index % 32 == 0" class="html2pdf__page-break"></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['monthly_history', 'quote_type', 'pdf'],
  data() {
    return {
      mgbottom: "mb-4",
    };
  },
  methods: {
    functionmB(index) {
      if (index != 0 && index % 20 == 0) {
        return 'border-bottom: 0.6px solid #b0b0b0;';
      } else {
        return 'border-bottom: 0.6px solid #b0b0b0;';
      }

    },
    formatNumber(x) {
      // console.log("type x");
      // console.log(x);
      if (isNaN(x) || x == null) {
        x = 0.0;
      } else {
        x = parseFloat(x);
      }
      x = parseFloat(x);

      let nf = new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      nf.resolvedOptions().minimumFractionDigits = 2;
      // console.log(nf.format(x));
      return nf.format(x);
    },
    date(value) {
      return moment(value).format("DD/MM/yyyy");
    },

  },
};
</script>

