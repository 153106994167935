<template>
  <div style="min-height: 80vh" class="bg-white pb-20 lg:pb-auto">
    <div
      v-if="has_error"
      class="bg-red-100 border font-medium border-red-400 text-red-700 px-4 py-2 rounded relative"
      role="alert"
    >
      An error has ocurred and could not create the quote, please try again.
    </div>
    <!-- circle steps -->
    <div class="w-11/12 mx-auto">
      <div class="progress-wrap mt-7 mx-auto">
        <div class="progress" id="progress" style="width: 100%"></div>
        <div class="step font-regular">1</div>
        <div class="step font-regular">2</div>
        <div class="step active font-regular">3</div>
      </div>
    </div>
    <!-- Step 3 -->
    <div id="thirdStep" class="w-full bg-white h-full">
      <div class="flex justify-center">
        <div class="w-3/5 lg:w-1/6 bg-color rounded-full py-2 lg:py-4">
          <h1 class="text-2xl font-bold text-center color-grey">
            {{ this.code }}
          </h1>
        </div>
      </div>
      <p
        class="w-3/6 lg:w-6/6 mx-auto color-grey text-sm lg:text-md font-medium text-center"
      >
        This is the reference code to track this quote
      </p>

      <div v-if="this.q_data != null" class="bg-color rounded-lg mt-8 py-5">
        <div class="w-10/12 mx-auto lg:flex">
          <!--Cost-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Cost:</p>
            <p>£ {{ formatNumber(this.q_data.cost) }}</p>
          </div>
          <!--VAT-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">VAT:</p>
            <p>£ {{ formatNumber(this.q_data.vat) }}</p>
          </div>
        </div>
        <!-- total net -->
        <div v-if="this.q_data.not_vat_items" class="w-10/12 mx-auto lg:flex mt-3">
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Total Net:</p>
            <p>£ {{ totalNetCost }}</p>
          </div>
          <!--Not VAT items-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Not vat items:</p>
            <p>£ {{ formatNumber(this.q_data.not_vat_items) }}</p>
          </div>

        </div>

        <!--Desposit-->
        <div class="w-10/12 mx-auto lg:flex mt-3">
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Deposit:</p>
            <!--Lease Quotes-->
            <div v-if="this.quote_type == 'lease'">
              <p>
                £
                {{
                  this.q_data.deposit
                    ? formatNumber(this.q_data.deposit) +
                      " + " +
                      formatNumber(vatPayment(this.q_data.deposit)) +
                      " VAT"
                    : 0
                }}
              </p>
            </div>
            <!--Hire Purchase Quotes-->
            <div v-else-if="this.quote_type == 'hire_purchase'">
              <!--VAT deferral-->
              <div v-if="this.q_data.vat_deferal">
                <p>
                  £
                  {{
                    this.q_data.deposit
                      ? `${formatNumber(parseFloat(this.q_data.deposit))}`
                      : 0
                  }}
                </p>
              </div>
              <!--No VAT deferral-->
              <div v-else-if="!this.q_data.vat_deferal">
                <!--VAT set manually to 0 // Certains goods may be VAT free-->
                <p v-if="this.q_data.vat == 0">
                  £
                  {{
                    this.q_data.deposit
                      ? `${formatNumber(
                          parseFloat(this.q_data.deposit)
                        )}`
                      : `${formatNumber(this.q_data.vat)}`
                  }}
                </p>
                <!--Deposit set manually-->
                <p v-else-if="this.q_data.net_deposit">
                  £
                  {{
                    this.q_data.deposit
                      ? `${formatNumber(
                          parseFloat(this.q_data.deposit) + parseFloat(this.q_data.vat)
                        )} inc VAT`
                      : `${formatNumber(this.q_data.vat)}`
                  }}
                </p>
                <p v-else>
                  £
                  {{
                    this.q_data.deposit
                      ? `${formatNumber(
                          parseFloat(this.q_data.deposit) + parseFloat(this.q_data.vat)
                        )} inc VAT`
                      : `${formatNumber(this.q_data.vat)}`
                  }}
                </p>
              </div>
            </div>
          </div>

          <!--Terms-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Terms:</p>
            <p>{{ this.q_data.term }} months</p>
          </div>
          <!--Interest to lender-->
          <!-- <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Interest to lender:</p>
            <p>{{ formatNumber(this.q_data.interest) }}%</p>
          </div> -->
        </div>

        <div class="w-10/12 mx-auto lg:flex mt-3">
          <!--Monthly Payments-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold mr-2 w-auto">Monthly payment:</p>
            <p class="">
              £ {{ formatNumber(this.q_payment) }}
              {{
                this.quote_type == "lease"
                  ? " + " + formatNumber(lease_vat) + " VAT"
                  : ""
              }}
            </p>
          </div>
          <!--Ballon-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Balloon:</p>
            <p>
              £
              {{ this.q_data.balloon ? formatNumber(this.q_data.balloon) : 0 }}
            </p>
          </div>
        </div>

        <div class="w-10/12 mx-auto lg:flex mt-3">
          <!--Total interest-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Total interest:</p>
            <p>£ {{ formatNumber(parseFloat(this.total_interest)) }}</p>
          </div>
          <!--Commission-->
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Commission:</p>
            <p>
              £
              {{
                this.q_data.commission ? formatNumber(this.q_data.commission) : 0
              }}
              {{
                this.q_data.commission ? `( ${((parseFloat(this.q_data.commission) /(parseFloat(this.q_data.cost) + parseFloat(q_data.not_vat_items ? q_data.not_vat_items : 0)  - parseFloat(this.q_data.deposit))) * 100).toFixed(2)} %)`: ""
              }}
              <!-- {{
                this.q_data.commission ? `( ${((this.q_data.commission /(this.q_data.cost - this.q_data.deposit + this.q_data.vat)) * 100).toFixed(2)} %)`: ""
                
              }} -->

              <!-- £ {{ formatNumber(this.q_data.commission) }} &nbsp;&nbsp; -->
              <!-- ({{ this.q_data.commission ? ((this.q_data.commission /(this.q_data.cost + this.q_data.vat - (this.q_data.deposit))) *100).toFixed(2): 0 }}%) -->
            </p>
          </div>
        </div>

        <div class="w-10/12 mx-auto lg:flex mt-3">
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">APR:</p>
            <p>{{ this.q_apr }}%</p>
          </div>
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Gross Yield:</p>
            <p>{{ formatNumber(this.q_gross_yield) }}%</p>
          </div>
        </div>

        <div class="w-10/12 mx-auto lg:flex mt-3">
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Flat Rate:</p>
            <p>{{ formatNumber(this.q_flat_rate) }}%</p>
          </div>
          <div class="flex w-full xl:w-1/2 mt-3 xl:mt-auto">
            <p class="font-bold pr-2">Net Yield:</p>
            <p>{{ formatNumber(this.q_data.interest) }}%</p>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center pt-3 lg:pt-10 pb-4 ">
        <button
          class="btn-primary text-center inline-block py-2 px-3 font-regular text-lg leading-tight uppercase rounded-full shadow-md hover:shadow-lg active:bg-color active:shadow-lg transition duration-150 ease-in-out lg:w-3/12 xl:w-3/12"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
          @click="show_monthly_quote = !show_monthly_quote"
        >
          MONTHLY SUMMARY
          <i
            v-if="!show_monthly_quote"
            class="fas fa-chevron-circle-down color-text pl-3"
          ></i>
        </button>
        <button
          @click="exportMonthly"
          class="btn-primary ml-5 inline-block py-2 font-regular text-black text-sm leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
        >
          <i class="fas fa-download text-center text-white h-5 px-5"></i>
        </button>
      </div>

      <div
        v-if="monthly_history != null && show_monthly_quote"
        class="container m-2 p-2 mx-auto"
      >
        <monthly-summary
          :monthly_history="monthly_history"
          :pdf="false"
        ></monthly-summary>
      </div>

      <div
        class="fixed bottom-0 mt-10 w-full flex justify-between items-center bg-secondary px-2"
      >
        <div class="w-11/12 mx-auto flex justify-between items-center">
          <button
            @click="back"
            class="mr-2 btn-back rounded-full font-regular text-lg lg:text-xl py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
            id="next"
          >
            <i class="fas fa-chevron-circle-left pr-1 lg:pr-3"></i>
            Back
          </button>
          <button
            @click="getOffer(q_data.id)"
            data-bs-toggle="modal"
            data-bs-target="#modalOffer"
            class="mr-2 btn-primary rounded-full font-regular text-lg lg:text-xl py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
            id="next"
          >
            Offer PDF
          </button>
          <button
            @click="submit"
            class="btn-secondary mr-2 rounded-full font-regular text-lg lg:text-xl py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
            id="next"
          >
            Save
            <i class="fas fa-chevron-circle-right pl-1 lg:pl-3"></i>
          </button>
        </div>
      </div>
      <div
        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="modalOffer"
        tabindex="-1"
        aria-labelledby="modalOffer"
        aria-modal="true"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Offer
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative py-2 px-2">
              <div class="modal-dialog">
                <label
                  class="pr-8 lg:pr-auto form-label inline-block color-grey font-regular px-3 text-lg lg:text-xl"
                  for="assets"
                  >Assets</label
                >
                <textarea
                  id="assets"
                  v-model="offer_asset"
                  value=""
                  class="form-control block w-full px-3 py-0 lg:py-4 text-sm font-normal text-gray-800 bg-transparent border-input rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  v-bind:class="{
                    'form-control': true,
                    'is-invalid': offer_asset == '' || offer_asset == null,
                  }"
                />
                <p
                  class="text-sm lg:text-md font-medium absolute top-4 lg:top-5 right-4 text-gray-500"
                ></p>
                <div class="invalid-feedback">This field cannot be empty</div>
                <label
                  for="conditions"
                  class="mt-3 pr-8 lg:pr-auto form-label inline-block color-grey font-regular px-3 text-lg lg:text-xl"
                  >Conditions</label
                >
                <textarea
                  id="conditions"
                  v-model="offer_conditions"
                  class="form-control block w-full px-3 py-0 lg:py-4 text-sm font-normal text-gray-800 bg-transparent border-input rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                />
              </div>
            </div>
            <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                data-bs-dismiss="modal"
                @click="submitOffer()"
                class="btn-primary inline-block px-2 py-2 font-regular text-black text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
              >
                Save and download
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- 
         <div v-if="export_pdf == true" style="height:0px; overflow:hidden;">
          <OfferPdf
              :quote="form_export"
              :offer="{assets:offer_asset, conditions:offer_conditions}"
              @downloaded="offerDownloaded"
          />
      </div> -->
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="pdfFileName"
      :pdf-quality="2"
      :quality="0.2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="800px"
      margin:0
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="m-0 p-0">
        <monthly-summary
          v-if="show_monthly_quote_pdf && monthly_history != null"
          :monthly_history="monthly_history"
          :quote_type="quote_type"
          :pdf="true"
        ></monthly-summary>
        <OfferPdf
          v-if="export_pdf && !show_monthly_quote_pdf"
          :quote="form_export"
          :offer="{ assets: offer_asset, conditions: offer_conditions }"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import BrandService from "../../../services/af-quote/BrandService";
import QuoteService from "../../../services/af-quote/QuoteService";
import OfferPdf from "../OfferPdf.vue";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
import MonthlySummary from "./monthly-summary/monthly-summary.vue";

export default {
  name: "result-step",
  components: {
    VueHtml2pdf,
    OfferPdf,
    MonthlySummary,
  },
  data() {
    return {
      q_name: null,
      q_data: null,
      q_payment: null,
      q_apr: null,
      q_gross_yield: null,
      q_flat_rate: null,
      monthly_history: null,
      code: null,
      length_code: 6,
      total_interest: null,
      quote_type: null,

      show_monthly_quote: false,
      show_monthly_quote_pdf: false,

      modify_offer: false,
      save_offer: false,
      offer_asset: "",
      offer_conditions: "",
      has_error: false,
      lease_vat: 0,

      export_pdf: false,
      form_export: null,
      id_create_offer: null,
    };
  },
  computed: {
    totalNetCost:function(){
      var not_vat_items = parseFloat(this.q_data.not_vat_items);
      var cost = parseFloat(this.q_data.cost);
      //console.log(not_vat_items+cost);
      return this.formatNumber(not_vat_items+cost);
    },
    pdfFileName: function () {
      var date = this.date(Date.now());
      return (
        "Offer" +
        "-" +
        (this.q_name != null ? this.q_name : "pdf") +
        "-" +
        date +
        ".pdf"
      );
    },
  },
  methods: {
    async submit() {
      this.$emit("submit", this.code);
    },
    back() {
      this.$emit("backwards");
    },
    async getOffer(id) {
      this.show_monthly_quote_pdf = false;
      if (id != null || this.id_create_offer != null) {
        var quote_id;
        if (id != null) {
          quote_id = id;
        } else {
          quote_id = this.id_create_offer;
        }
        try {
          this.modify_offer = false;
          this.offer_asset = "";
          this.offer_conditions = "";
          const quote_service = new QuoteService();

          const data = await quote_service.getOffer(quote_id);
          if (data.quote_id != null || data.quote_id) {
            this.offer_asset = data.assets;
            this.offer_conditions = data.conditions;
            this.modify_offer = true;

            
          } else {
            if (!data.success) {
              this.modify_offer = false;
            }
          }
        } catch (error) {
          this.modify_offer = false;
        } finally {
          //console.log(this.modify_offer);
        }
      } else {
        //console.log("Este quota aun no está creada");
      }
    },
    setIdCreate(id) {
      // console.log("creado correctamente offfer mas quote");
      // alert("creado correctamente offfer mas quote");
      this.id_create_offer = id;
    },
    async setQuote(code) {
      this.code = code;
      const quote_service = new QuoteService();

      //busca el quoteoffer de la cuota que estamos modificando
      try {
        const offer_data = await quote_service.getOffer(
          this.$parent.$parent.$parent.id
        );
        if (offer_data.success) {
          this.offer_asset = offer_data.assets;
          this.offer_conditions = offer_data.conditions;
          this.modify_offer = true;
        }
      } catch (error) {
        this.modify_offer = false;
        console.error(error);
      }

      this.modify_offer = true;
    },

    setType(quote_type) {
      this.quote_type = quote_type;
    },

    hasFailed(error) {
      console.error(error);
      this.has_error = true;
    },

    async submitOffer() {
      try {
        const quote_service = new QuoteService();
        if (this.q_data.id != null || this.id_create_offer != null) {
          var id;
          if (this.q_data.id != null) {
            id = this.q_data.id;
          } else {
            id = this.id_create_offer;
          }
          // alert("este es una quota modificandose.... + un offer");
          if (this.modify_offer || this.id_create_offer != null) {
            // alert("update offer.....");
            await quote_service.updateOffer(
              {
                assets: this.offer_asset,
                conditions: this.offer_conditions,
              },
              id
            );
          } else {
            // alert("create offer.....");
            const data = await quote_service.createOffer({
              quote_id: id,
              assets: this.offer_asset,
              conditions: this.offer_conditions,
            });
          }
        } else {
          let quote = {
            quote_code: this.code,
            offer: {
              assets: this.offer_asset,
              conditions: this.offer_conditions,
            },
          };
          this.$emit("create_offer", quote);
        }

        this.exportPDF();
      } catch (error) {
        console.error(error);
      }
    },

    async setData(q_name, q_type, q_data, loan_data, monthly_history) {
      this.q_name = q_name;
      this.q_data = q_data;
      this.quote_type = q_type;
      this.q_payment = loan_data["payment"];
      this.q_apr = loan_data["apr"];
      this.q_gross_yield = loan_data["gross_yield"];
      this.q_flat_rate = loan_data["flat_rate"];
      this.total_interest = loan_data["total_interest"];
      this.monthly_history = monthly_history;

      this.lease_vat = this.vatPayment(this.q_payment);

      if (!this.code) this.generateCode();

      //console.log(this.q_data, 'que tiene el q_data')
      //console.log(loan_data, 'lo que tiene el loan data')
    },

    offerDownloaded() {
      this.export_pdf = false;
      this.$emit("submit_offer", [this.offer_asset, this.offer_conditions]);
    },

    generateCode() {
      let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      this.code = "#";
      for (let i = 0; i < this.length_code; ++i)
        this.code += chars.charAt(Math.floor(Math.random() * chars.length));
    },

    vatPayment(payment) {
      return parseFloat(
        parseFloat(payment) * parseFloat(process.env.VUE_APP_UK_VAT)
      );
    },
    exportMonthly() {
      this.show_monthly_quote_pdf = true;
      this.generateReport();
    },
    async exportPDF() {
      // Get brand data and image
      const brand_service = new BrandService();
      let brand = await brand_service.getBrand(
        this.$parent.$parent.$parent.brand_id
      );
      brand.img = await brand_service.getImg(
        this.$parent.$parent.$parent.brand_id
      );

      this.form_export = {
        name: this.q_name,
        brand: brand,
        type: this.quote_type,
        cost: this.q_data.cost,
        commission: this.q_data.commission,
        term: this.q_data.term,
        deposit: this.q_data.deposit,
        vat: this.q_data.vat,
        advanced_pay: this.q_data.advances,
        payment: this.q_payment,
        not_vat_items:this.q_data.not_vat_items
      };

      this.export_pdf = this.offer_asset != null && this.offer_asset != "";

      this.generateReport();
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    formatNumber(x) {
      if (isNaN(x) || x == null) {
        x = 0.0;
      } else {
        x = parseFloat(x);
      }
      x = parseFloat(x);

      let nf = new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      nf.resolvedOptions().minimumFractionDigits = 2;
      return nf.format(x);
    },
    date(value) {
      return moment(value).format("DD/MM/yyyy");
    },
  },
};
</script>
