import Vue from "vue";
import store from "@/store/index";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import RegisterQuote from "../views/Manager/RegisterQuote/RegisterQuote.vue";
import Login from "../views/Login.vue";
import ListQuotes from "../views/Manager/ListQuotes.vue";
import ListUsers from "../views/Admin/ListUsers.vue";
import Register from "../views/Admin/Register.vue";
import RegisterBrand from "../views/Admin/RegisterBrand.vue";
import RegisterProposal from "../views/Manager/RegisterProposal.vue";
import NotFound from "../views/NotFound.vue";
import ListBrand from "../views/Admin/ListBrand.vue";
import OfferPdf from "../views/Manager/OfferPdf.vue";
import TestPdf from "../views/Manager/TestPdf.vue";

import Forgot from "../components/Forgot.vue";
import ResetPassword from "../components/ResetPassword.vue";
import CreateForm from "../views/Admin/CreateForm.vue";
import ListForm from "../views/Admin/ListForm.vue";
import EditForm from "../views/Admin/EditForm.vue";
import ListLead from "../views/Admin/ListLead.vue";
import InfoLead from "../views/Admin/InfoLead.vue";

Vue.use(VueRouter);



const routes = [{
        path: "/",
        name: "home",
        component: Home,
    },
    {
        path: "/login",
        name: "login",
        meta: { requiresNotLogged: true },
        component: Login
    },
    {
        path: "/registerquote",
        name: "registerQuote",
        meta: { requiresAuth: true },
        component: RegisterQuote
    },
    {
        path: "/registerquote/:id",
        name:"registerQuoteLead",
        component: RegisterQuote
    },
    {
        path: "/quotes",
        name: "quotes",
        meta: { requiresAuth: true },
        component: ListQuotes
    },
    {
        path: "/users",
        name: "users",
        meta: { requiresAdmin: true },
        component: ListUsers
    },
    {
        path: "/register",
        name: "register",
        meta: { requiresAdmin: true },
        component: Register
    },
    {
        path: "/registerbrand",
        name: "registerBrand",
        meta: { requiresAdmin: true },
        component: RegisterBrand
    },
    {
        path: "/brands",
        name: "brands",
        meta: { requiresAdmin: true },
        component: ListBrand
    },
    {
        path: "/registerproposal",
        name: "registerproposal",
        meta: { requiresAuth: true },
        component: RegisterProposal
    },
    //Ruta de creación de formularios
    {
        path: "/create-form",
        name: "createform",
        component: CreateForm
    },
    {
        path:"/forms/:message?",
        name:"forms",
        component:ListForm
    },
    {
        path:"/edit-form/:id",
        name:"editform",
        component: EditForm
    },
    {
        path: "/reset-password",
        name: "reset",
        component: ResetPassword
    },
    {
        path: "/leads",
        name: "leads",
        component: ListLead
    },
    {
        path: "/info-lead/:id",
        name: "infolead",
        component: InfoLead
    },
    // para probar PDF
    // {
    //     path: "/proposal",
    //     name: "pdfTest",
    //     meta: { requiresAdmin: true },
    //     component: TestPdf
    // },






    { path: '/404', name: '404', component: NotFound },
    { path: '*', redirect: '/404' },
]


const router = new VueRouter({
    mode: "history",
    routes: routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAdmin)) {
        if (store.getters["auth/isAdmin"]) {
            next();
            return;
        }
        next("/404");
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["auth/loggedIn"]) {
            next();
            return;
        }
        next("/login");
    } else if (to.matched.some((record) => record.meta.requiresNotLogged)) {
        if (!store.getters["auth/loggedIn"]) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
});

export default router;