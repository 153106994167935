import * as API from "@/services/af-quote/API";

export default class UserService {

    async getLength() {

        return await API.apiClient.post('/api/users/length').then(function(data) {
            return data.data.data;
        }).catch(function(error) {
            throw (error);
        });
    }

    async getUsers(query = '') {
        return await API.apiClient.post('/api/users' + (query != '' ? '?' + query : '')).then(
            data => { return data.data.data; }
        ).catch(
            error => { throw (error); }
        );
    }

    async deleteUser(id) {
        return await API.apiClient.delete('/api/users/' + id).then(
            data => { return data.data.data; }
        ).catch(
            error => { throw (error); }
        );
    }

    async restoreUser(id) {
        return await API.apiClient.patch('/api/users/restore/' + id).then(
            data => { return data.data.data; }
        ).catch(
            error => { throw (error); }
        )
    }


};