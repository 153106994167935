<template>
  <div id="app">
    <TopBar style="height:6vh;"/>
    <main style="min-height:94vh;" class="bg-white">
      <router-view class="h-full"/>
    </main> 
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";

export default {
  name: "app",
  components: {
    TopBar,
  },
  created(){
    document.documentElement.style.setProperty('--bgCompany', '#fff');
  }
};
</script>

<style lang="scss">
@import "./assets/sass/app.scss";
</style>
