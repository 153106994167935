<template>
  <div>
    <div class="bg-white w-full h-full">
      <div
        class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
      >
        <h1 class="text-white text-xl lg:text-2xl font-bold">
          <i class="fas fa-file"></i>
          LEAD INFO
        </h1>
      </div>

      <div class="p-20 m-auto text-center">
        <p class="font-semibold text-xl leading-10">
          <b>Name: </b>{{ this.lead.name }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Email: </b>{{ this.lead.email }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Telephone: </b>{{ this.lead.phone }}
        </p>

        <p class="font-semibold text-xl leading-10">
          <b>Loan: </b>{{ this.lead.loan }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Business name: </b>{{ this.lead.bussines_name }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Notifications: </b>{{ this.lead.marketing }}
        </p>
         <p class="font-semibold text-xl leading-10">
          <b>Amount: </b>{{ this.lead.amount }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Deposit: </b>{{ this.lead.deposit }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Term: </b>{{ this.lead.term }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Purpose: </b>{{ this.lead.purpouse }}
        </p>
        <p class="font-semibold text-xl leading-10">
          <b>Message: </b>{{ this.lead.message }}
        </p>
        
      </div>

      <div class="flex justify-center mt-12 pb-5">
        <button
          class="w-32 h-14 btn-primary font-regular text-lg lg:text-xl rounded-full"
          @click="redirectList()"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import LeadService from "../../services/af-quote/LeadService";

export default {
  data() {
    return {
      lead: null,
    };
  },
  async created() {
    this.getLead(this.$route.params.id);
  },
  computed: {},
  methods: {
    async getLead(id) {
      const lead_service = new LeadService();
      const lead_data = await lead_service.getLead(id);
      //console.log(lead_data);
      this.lead = lead_data.lead;

    },
    redirectList() {
      this.$router.replace({ name: "leads" });
    },
  },
};
</script>