<template>
 <div id="login"  style="min-height: 94vh;" class="bg-img flex justify-center py-4 items-center">
      <title>Zenfiny| Forgot Password</title>
        <div class="w-full lg:w-3/6 p-4 mt-5">
            <h1 class="mb-12 text-center text-5xl lg:text-6xl">
               FORGOT PASSWORD
            </h1>
            <div  v-if="alert" class="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                <strong class="font-bold">E-mail sent! </strong>
                <span class="block sm:inline">Please check your mailbox for reset your password.</span>
                <span class="absolute top-0 bottom-3 right-0 px-4 py-3">
                    <svg class="fill-current h-6 w-6 text-blue-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
            </div>
            <form class="w-11/12 mx-auto">
                <div class="form-group mb-6 mt-6">
                    <label for="exampleInputEmail1" class="form-label inline-block mb-2 px-3 text-lg lg:text-xl">Please insert your email</label>
                    <input v-model="email" type="email" class="form-control
                        block
                        w-full
                        px-3
                        py-2 lg:py-4 
                        font-normal
                        bg-white
                        border-2 border-solid border-white
                        rounded-full
                        transition
                        ease-in-out
                        m-0
                        focus:outline-none" id="exampleInputEmail1"  placeholder="Email User"
                        v-bind:class="{
                            'form-control': true,
                            'is-invalid': !validEmail(email) && already_submitted
                        }" 
                    >
                    <div class="invalid-feedback">Enter a valid email</div>
                        
                </div>

                <div class="flex justify-center mt-10">
                    <button type="submit" v-on:click.stop.prevent="handleSubmit" class="w-1/2 lg:w-1/2 btn-primary py-2 lg:py-2 text-xl lg:text-xl rounded-full">
                        <i class="fas fa-sign-in-alt mr-2"></i>
                        SEND EMAIL
                    </button>
                </div>
            </form>


        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import AuthService from "../services/af-quote/AuthService";
export default {
    name: 'Forgot',
    data(){
        return{
            email:null,
             already_submitted:false,
             alert:false
        }
    },
    methods:{

        async  handleSubmit(){
            this.already_submitted = true;
            if(this.validEmail(this.email)){
                try {
                    let form = {
                        email:this.email
                    }
                    const response = await AuthService.forgotPassword(form);
                    
                    this.$emit("email", [form]);
                    this.alert = true;
                } catch(error) {
                    console.error(error);
                }   
            }
            
        },  

        validEmail(str) {
            var re = /(.+)@(.+){2,}\.(.+){2,}/;
            return str != '' && re.test(str); 
        }

    }
}
</script>
