import * as API from "@/services/af-quote/API";

export default class LeadService {

    async getLeads(query=null){
        try{
            if(query == null || query == "null"){
                const data = await API.apiClient.get("api/index-leads");
                return data.data;
            }else{
                const data = await API.apiClient.get("api/index-leads"+query);
                return data.data;
            }
        }catch(error){
            return error;
        }
    }
    async getLead(id){
        try{
            const data = await API.apiClient.get("api/show-lead/"+id);
            return data.data;
        }catch(error){
            return error;
        }
    }

    async deleteLead(id){
        try{
            const data = await API.apiClient.delete("api/delete-lead/"+id);
            //console.log(data);
            return data.data;
        }catch(error){
            return error;
        }
    }

}