<template>
  <div v-show="isActive" class="mt-0 pt-0">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "tab",
  props: {
    name: { required: true },
    selected: { default: false },
  },

  data() {
    return {
      code: "",
      isActive: false,
    };
  },

  created() {
    this.code = this.name;
    this.isActive = this.selected;
  },
};
</script>

