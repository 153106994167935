<template>
  <div class="max-w-lg p-5 m-auto">
    <title>Zenfiny | Error</title>
    <article class="p-5">
      <h1
        class="
          mb-2
          text-6xl
          font-medium
          text-transparent
          bg-gradient-to-r bg-clip-text
          text-red-500
        "
      >
        4O4
      </h1>
      <p class="font-medium text-white">
        The page you're looking for is not here.
        <router-link to="/" class="base-link"
          ><p class="text-xl underline strong">Back home.</p></router-link
        >
      </p>
    </article>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
