<template>
  <div>
    
  <title>Zenfiny | List Leads</title>
  <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-lg lg:text-2xl font-bold">
        <i class="fas fa-file mr-2"></i>
        LIST LEADS
      </h1>
    <div class="flex justify-end mt-3 lg:mt-0 w-full lg:w-auto">
      <button
        @click="toggleCollapse"
        class="text-sm lg:text-md font-medium btn-search inline-block lg:px-2 py-2 font-regular leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full 
lg:px-4 px-6"
        aria-expanded="false"
        aria-controls="collapseExample"
      >
        <i class="fas fa-search color-text mx-3"></i> SEARCH FILTER
      </button>
    </div>
  </div>

     <!-- contenido del Collapse -->
    <transition name="slide-fade">
      <div v-if="isCollapsed" id="collapseExample" class="p-6 m-4 rounded-lg shadow-lg bg-color">
        <!-- Primera fila: Name y Email -->
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="form-group">
            <label for="nameLisForm" class="form-label inline-block mb-2 color-grey font-regular px-3">Name</label>
            <input v-model="search_provisional_name" type="text" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="nameLisForm" placeholder=""/>
          </div>
          <div class="form-group">
            <label for="emailLisForm" class="form-label inline-block mb-2 color-grey font-regular px-3">Email</label>
            <input v-model="search_provisional_email" type="text" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="emailLisForm" placeholder=""/>
          </div>
        </div>
        <!-- Segunda fila: Type y Source -->
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="form-group">
            <label for="typeLisForm" class="form-label inline-block mb-2 color-grey font-regular px-3">Type</label>
            <select v-model="search_provisional_type" type="dropdown" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="typeLisForm" placeholder="">
              <option value="2"></option>
              <option value="0">Calculator</option>
              <option value="1">Form Quote</option>
            </select>
          </div>
          <div class="form-group">
            <label for="sourceLisForm" class="form-label inline-block mb-2 color-grey font-regular px-3">Source</label>
            <input v-model="search_provisional_source" type="text" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="sourceLisForm" placeholder=""/>
          </div>
        </div>
        <!-- Tercera fila: Start Date y End Date -->
        <div class="grid grid-cols-2 gap-4 mb-6">
          <div class="form-group">
            <label id="startDate" for="startDate" class="form-label inline-block mb-2 color-grey font-regular px-3">Start Date</label>
            <input v-model="search_provisional_startDate" type="date" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="startDate" placeholder="Select Start Date"/>
          </div>
          <div class="form-group">
            <label for="endDate" class="form-label inline-block mb-2 color-grey font-regular px-3">End Date</label>
            <input v-model="search_provisional_endDate" type="date" class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none" id="endDate" placeholder="Select End Date"/>
          </div>
        </div>
        <!-- Botones de búsqueda y limpieza -->
        <div class="flex justify-end gap-4">
          <button v-on:click.stop.prevent="clearSearch()" class="btn-clear inline-block px-2 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-purple-600 focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-2/12" type="button">
            <i class="fas fa-times color-black mx-1"></i> Clear
          </button>
          <button v-on:click.stop.prevent="setSearch()" class="btn-primary inline-block px-2 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-purple-600 focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-2/12" type="button">
            <i class="fas fa-search color-black mx-1"></i> SEARCH
          </button>
        </div>
      </div>
    </transition>
    <div style="width:95%;" class="my-4 py-2 overflow-x-auto mx-auto">
      <table class="w-full">
        <thead class="bg-primary border-b-2">
          <tr>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('created_at')"
            >
              Creation date
              <span v-if="sortBy === 'created_at'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('name')"
            >
              Name
              <span v-if="sortBy === 'name'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('type')"
            >
              Type
              <span v-if="sortBy === 'type'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('form_id')"
            >
              Source
              <span v-if="sortBy === 'form_id'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              
            >
              Email
            </th>
            
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              
            >
              Phone
              
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('loan')"
            >
              Loan/Amount
              <span v-if="sortBy === 'loan'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('quote')"
            >
              Quote
              <span v-if="sortBy === 'quote'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left cursor-pointer"
              @click="orderBy('bussines_name')"
            >
              Business name
              <span v-if="sortBy === 'bussines_name'" :class="sortDesc ===  true ? 'arrow-up' : 'arrow-down'"></span>
            </th>
            <th
              scope="col"
              class="text-sm font-regular text-white px-4 py-2 text-left w-28"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
          v-for="lead in leadsSorted" :key="lead.id"
          class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
            <td class="text-sm px-4 py-2 whitespace-nowrap">
              {{ formatDate(lead.created_at) }}
            </td>
            <td class="text-sm font-semibold px-4 py-2 whitespace-nowrap">
              {{ lead.name }}
            </td>

            <td
              v-if="lead.type == 0"
              class="text-sm font-semibold px-4 py-2 whitespace-nowrap"
            >
              Calculator
            </td>

            <td
              v-else
              class="text-sm font-semibold px-4 py-2 whitespace-nowrap"
            >
              Form Quote
            </td>
            <td
              v-if="lead.form_id"
              class="text-sm px-4 py-2 whitespace-nowrap"
            >
              {{ lead.form.name }}
            </td>

            <td v-else class="text-sm px-4 py-2 whitespace-nowrap"></td>

            <td class="text-sm px-4 py-2 whitespace-nowrap">
              {{ lead.email }}
            </td>
            
            <td class="text-sm px-4 py-2 whitespace-nowrap">
              {{ lead.phone }}
            </td>
            <td 
              v-if="lead.type == 0"
              class="text-sm px-4 py-2 whitespace-nowrap">
              {{ formattedAmount(lead.amount) }}
            </td>
            <td
              v-else
              class="text-sm font-semibold px-4 py-2 whitespace-nowrap"
            >
              {{ lead.loan }}
            </td>
            <td class="text-sm px-4 py-2 whitespace-nowrap">
              {{ formattedAmount(lead.quote) }}
            </td>
            <td class="text-sm px-4 py-2 whitespace-nowrap">
              {{ lead.bussines_name }}
            </td>
            <td
              class="text-md flex justify-between text-left font-medium px-4 py-2"
            >
              <button
                @click="openModal(lead.id)"
                type="button"
                class="inline-block py-2 font-medium text-md leading-tight color-black transition 
                duration-150 ease-in-out hover:opacity-75 pr-4" title="Details"
              >
                <i class="fas fa-eye color-grey "></i>
              </button>
            
              <button
                @click="quoteCreate(lead.id)"
                type="button"
                class="inline-block py-2 font-medium text-md leading-tight color-black transition 
                duration-150 ease-in-out hover:opacity-75 pr-4" title="Create"
              >
                <i class="fas fa-plus color-grey "></i>
              </button>
          
              <button
                @click="leadRemove(lead.id)"
                type="button"
                class="inline-block py-2 font-medium text-md leading-tight color-delete transition duration-150 ease-in-out hover:opacity-70 hover:opacity-75" title = "Remove"
                data-bs-toggle="modal"
                data-bs-target="#modalDelete" 
            >
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    

    <!--PAGINATION-->
    <div aria-label="Page navigation" class="bg-transparent pb-4">
      <div class="flex flex-col mt-5">
        <ul class="inline-flex flex justify-center">
          <li>
            <button
              @click="getLeads(current_page - 1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black rounded-l-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
          <li v-if="current_page > 1">
            <button
              @click="getLeads(1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              1
            </button>
          </li>
          <li v-if="current_page > 2">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>

          <li>
            <button
              disabled
              class="h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-black focus:shadow-outline"
            >
              {{ current_page }}
            </button>
          </li>

          <li v-if="current_page < last_page - 1">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>
          <li v-if="current_page < last_page">
            <button
              @click="getLeads(last_page)"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              {{ last_page }}
            </button>
          </li>

          <li>
            <button
              @click="getLeads(current_page + 1)"
              :disabled="current_page == last_page"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-black rounded-r-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!--END PAGINATION-->

    <!--MODAL-->
    <!-- The Modal -->
    <div id= "modalDelete" class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        tabindex="-1"
        aria-labelledby="modalDelete"
        aria-modal="true"
        role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
        <div
            
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
        <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
            <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Delete lead
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
              <i class="fas fa-times"></i>
              </button>
        </div>
        <div class="modal-body relative py-6 px-2">
              <p>Are you sure you want to remove this lead from the list?</p>
        </div>
        <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                type="button"
                @click="deleteLead()"
                data-bs-dismiss="modal"
                class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                <i class="fas fa-trash-alt"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
    </div>
    <!--END MODAL-->
    <div id="myModal" class="modale">
      <!-- Modal content -->
      <div class="modal-contente rounded-xl">
        <div
          class="w-full bg-secondary py-2 lg:flex justify-between items-center px-6 rounded-t-xl relative"
        >
          <h2 class="text-white text-xl font-bold">
            <i class="fas fa-times absolute top-2 right-2 text-lg lg:text-2xl" @click="closeModal"></i>
            <i class="fas fa-times absolute top-2 right-2 text-lg lg:text-2xl" @click="closeModal"></i>
            <i class="fas fa-file"></i>
            LEAD INFO
          </h2>
      
        </div>
        <div v-if="current_lead != null" class="lg:flex justify-between">
          <div class="lg:w-1/2 bg-white rounded-bl-xl rounded-tr-xl p-4"
          v-if="
          current_lead.name !== null || 
          current_lead.last_name !== null || 
          current_lead.email !== null || 
          current_lead.phone !== null || 
          current_lead.loan !== null || 
          current_lead.bussines_name !== null || 
          current_lead.trader !== null ||
          current_lead.marketing !== null ||
          current_lead.custom_comunications !== null"> 
              <p v-if="current_lead.name !== null" class="font-medium text-md leading-10">
                <b>Name: </b>{{ this.current_lead.name }}
              </p>
              <p v-if="current_lead.last_name !== null" class="font-medium text-md leading-10">
                <b>Lastname: </b>{{ this.current_lead.last_name }}
              </p>
              <p v-if="current_lead.email !== null" class="font-medium text-md leading-10">
                <b>Email: </b>{{ this.current_lead.email }}
              </p>
              <p v-if="current_lead.phone !== null" class="font-medium text-md leading-10">
                <b>Telephone: </b>{{ this.current_lead.phone }}
              </p>
              <p v-if="current_lead.loan !== null" class="font-medium text-md leading-10">
                <b>Loan: </b>{{ this.current_lead.loan }}
              </p>
              <p v-if="current_lead.bussines_name !== null" class="font-medium text-md leading-10">
                <b>Business name: </b>{{ this.current_lead.bussines_name }}
              </p>
              <p v-if="current_lead.trader !==null" class="font-medium text-md leading-10">
                <b>Business type: </b>{{ companyType(this.current_lead.trader) }}
              </p>
              <p v-if="current_lead.marketing !== null" class="font-medium text-md leading-10">
                <b>Marketing notifications: </b
                >{{ this.current_lead.marketing == 0 ? "No" : "Yes" }}
              </p>
              <p v-if="current_lead.custom_comunications !== null" class="font-medium text-md leading-10">
                <b>Receive custom communications: </b
                >{{ this.current_lead.custom_comunications == 0 ? "No" : "Yes" }}
              </p>
              </div>
              <div 
              v-if="
              current_lead.amount !== null ||
              current_lead.deposit !== null ||
              current_lead.term !== null ||
              current_lead.credit !== null ||
              current_lead.purpouse !== null ||
              current_lead.message !== null ||
              current_lead.form_id !== null ||
              current_lead.quote !== null" 
                class="lg:w-1/2 bg-white rounded-bl-xl rounded-tr-xl p-4">
              <p v-if="current_lead.amount !== 0" class="font-medium text-md leading-10">
                <b>Amount: </b>{{ formattedAmount(this.current_lead.amount) }}
              </p>
              <p v-if="current_lead.deposit !== 0" class="font-medium text-md leading-10">
                <b>Deposit:</b> {{ formattedAmount(this.current_lead.deposit) }}
              </p>
              <p v-if="current_lead.term !== 0" class="font-medium text-md leading-10">
                <b>Term: </b>{{ this.current_lead.term }}
              </p>
              <p v-if="current_lead.credit !== null" class="font-medium text-md leading-10">
                <b>Credit: </b>{{ this.current_lead.credit }}
              </p>
              <p v-if="current_lead.purpouse !== null" class="font-medium text-md leading-10">
                <b>Purpose: </b>{{ this.current_lead.purpouse }}
              </p>
              <p v-if="current_lead.message !== null" class="font-medium text-md leading-10">
                <b>Message: </b>{{ this.current_lead.message }}
              </p>
              <p v-if="current_lead.form_id !==null" class="font-medium text-md leading-10">
                <b>Source: </b>{{ this.current_lead.form['name'] }}
              </p>
              <p v-if="current_lead.quote !==null" class="font-medium text-md leading-10">
                <b>Quote: </b>{{ formattedAmount(this.current_lead.quote) }}
              </p>
              
          </div>
          <div 
          v-if="
          current_lead.company_number !== null ||
          current_lead.directors_name !== null ||
          current_lead.directors_nationality !== null ||
          current_lead.directors_born_date !== null" 
          class="w-1/2 bg-white rounded-bl-xl rounded-tr-xl p-4">
            <p v-if="current_lead.company_number !==null" class="font-medium text-md leading-10">
              <b>Company number: </b>{{ this.current_lead.company_number }}
            </p>
            <p v-if="current_lead.directors_name !==null" class="font-medium text-md leading-10">
              <b>Director Name: </b>{{ this.current_lead.directors_name }}
            </p>
            <p v-if="current_lead.directors_nationality !==null" class="font-medium text-md leading-10">
              <b>Director Nationality: </b>{{ this.current_lead.directors_nationality}}
            </p>
            <p v-if="current_lead.directors_born_date !==null" class="font-medium text-md leading-10">
              <b>Director Born Date: </b>{{ this.current_lead.directors_born_date }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--END MODAL-->
  </div>
</template>

<style scoped>
/* The Modal (background) */
.modale {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-contente {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  border: 1px solid #888;
  width: 50%;
  /* Could be more or less, depending on screen size */
}
@media (max-width: 800px) {
  .modal-contente {
    width: 95%;
  }
}

/* The Close Button */
.close {
  color: #ffffff;
  float: right;
  font-size: 43px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>

<script>
import LeadService from "../../services/af-quote/LeadService";

/*/////////////////////////*/


export default {
  data() {
    
    return {
      isCollapsed: false,
      search_query: null,
      leads: [],
      search_name: null,
      search_type: null,
      search_email: null,
      search_provisional_name: null,
      search_provisional_type: null,
      search_provisional_email: null,
      search_provisional_startDate: null, // New field for start date
      search_provisional_endDate: null,   // New field for end date
      search_provisional_source: null,
      current_page: null,
      last_page: null,
      current_lead: null,
      sortBy: 'created_at', // Variable para controlar el ordenamiento por columna
      sortDesc: true // Variable para controlar el orden descendente
    };
  },
  watch: {
    search_provisional_source(newVal) {
      //console.log(newVal);
      if (newVal === null || newVal === undefined || newVal.trim() === '') {
      //  console.log(newVal + " aaaaaaaaaaaaaaa");
        this.search_provisional_source = '';
      }
     // console.log(newVal);
      //console.log(this.search_provisional_source);
    }
  },

  computed: {
 // Computed property para ordenar leads basado en el estado actual
 leadsSorted() {
      const leadsCopy = [...this.leads];

      if (this.sortColumn) {
        leadsCopy.sort((a, b) => {
          const aValue = a[this.sortColumn];
          const bValue = b[this.sortColumn];

          if (aValue < bValue) {
            return this.sortOrder === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return this.sortOrder === 'asc' ? 1 : -1;
          }
          return 0;
        });
      }

      return leadsCopy;
    },

    
  },

  async created() {
    await this.getLeads();
  },

  methods: {
    
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;  // Alterna el estado del colapso
    },
    formattedAmount(amount) {
      //console.log(amount);
      if (amount !== null && !isNaN(amount)) {
        // Formatear el número al estilo del Reino Unido con símbolo de libra (£)
        return `${amount.toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP'
        })}`;
      } else {
        return 'Amount not available'; // Opcional: Manejo para cuando el valor es null o no es un número
      }
    },
    companyType(companyType) {
      if (companyType=='T' || companyType=='ST') {
        return 'Sole Trader';
      } else if (companyType=='P') {
        return 'Partnership';
      } else if (companyType=='L') {
        return 'Limited Business';
      } else if (companyType=='LC') {
        return 'Limited Company';
      }
    },

    formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}-${month}-${year}`;
    },
    async leadRemove(lead_id) {
      this.leadToDelete = lead_id;
      var modal = document.getElementById("myModal2");
      modal.style.display = "block";
    },
    async deleteLead() {
      var lead_service = new LeadService();
      var lead_id = this.leadToDelete;

      const lead_response = await lead_service.deleteLead(lead_id);

      if (lead_response) {
        // Encuentra el índice del lead que se va a eliminar
        const index = this.leads.findIndex(lead => lead.id === lead_id);
        if (index !== -1) {
          // Elimina el lead del array leads
          // Utiliza slice para crear un nuevo array
          this.getLeads(this.leads.length > 1 ? this.current_page : --this.current_page);
        }
      }
    },
    async openModal(lead_id) {
      var lead_service = new LeadService();
      // console.log(lead_id);
      const lead_response = await lead_service.getLead(lead_id);
      this.current_lead = lead_response.lead;
      var modal = document.getElementById("myModal");
      modal.style.display = "block";
    },
    closeModal() {
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    },
    closeModal2(){
      var modal2 = document.getElementById("myModal2");
      modal2.style.display = "none";
    },
    closeModalNondelete(){
      this.closeModal2();
    },
    async closeModalDelete(){
      var lead_service = new LeadService();
      var lead_id = this.leadToDelete;

      const index = this.leads.findIndex((lead) => lead.id === lead_id);
      if (index !== -1)
      {
        this.leads.splice(index, 1);
      }

      const lead_response = await lead_service.deleteLead(lead_id);

      this.closeModal2();
    },
    infoLead(id) {
      //console.log("info lead modal: ", id);
      this.$router.replace({
        name: "infolead",
        params: {
          id: id,
        },
      });
    },
    quoteCreate(id) {
      //console.log("quote create modal: ", id);
      this.$router.replace({
        name: "registerQuoteLead",
        params: {
          id: id,
        },
      });
    },

    async orderBy(column)
    {
      if (column === this.sortBy) {
        this.sortDesc =!this.sortDesc;
      } else {
        this.sortBy = column;
        this.sortDesc = false;
      }
      const lead_service = new LeadService();
      if(this.search_query == null){
        this.search_query = `?orderBy=${column}&order=${this.sortDesc? 'desc' : 'asc'}`;
      } else {
        this.search_query += `&orderBy=${column}&order=${this.sortDesc? 'desc' : 'asc'}`;
      }
      // console.log(this.search_query);
      const leads = await lead_service.getLeads(this.search_query);
      // console.log(leads);
      this.leads = leads.lead.data;
      
    },
// Hacer la searchquery con la columna, el jombre y algo reconocil¡ble tipo orderBy => desc
    async getLeads(num_page = null) {
      
      if (num_page != null) {
        this.setSearch(num_page);
      }
      const lead_service = new LeadService();
      if (this.search_query == null) {
        const lead_data = await lead_service.getLeads();
        // console.log('Y');
        // console.log(lead_data);
        this.leads = lead_data.lead.data;
        this.current_page = lead_data.lead.current_page;
        this.last_page = lead_data.lead.last_page;
      } else {
        // console.log('X ->' + this.search_query);
        const lead_data = await lead_service.getLeads(this.search_query);
        
        // console.log(lead_data);
        this.leads = lead_data.lead.data;
        this.current_page = lead_data.lead.current_page;
        this.last_page = lead_data.lead.last_page;
      }
      // console.log(this.leads);
    },
    async setSearch(num_page = null) {
    // Inicializar el objeto de parámetros de búsqueda
    let searchParams = new URLSearchParams();
    this.search_query = "";

    // Añadir los parámetros si existen y no están vacíos
    if (this.search_provisional_type != 2) {
        if (this.search_provisional_type && this.search_provisional_type != "" && this.search_provisional_type != "null") {
            searchParams.append("type", this.search_provisional_type);
        }
    }

    if (this.search_provisional_name && this.search_provisional_name != "" && this.search_provisional_name != "null") {
        searchParams.append("name", this.search_provisional_name);
    }

    if (this.search_provisional_email && this.search_provisional_email != "" && this.search_provisional_email != "null") {
        searchParams.append("email", this.search_provisional_email);
    }

    if (this.search_provisional_source && this.search_provisional_source != "" && this.search_provisional_source != "null") {
        searchParams.append("source", this.search_provisional_source);
    }

    if (this.search_provisional_startDate && this.search_provisional_startDate != "" && this.search_provisional_startDate != "null") {
        searchParams.append("startDate", this.search_provisional_startDate);
    }

    if (this.search_provisional_endDate && this.search_provisional_endDate != "" && this.search_provisional_endDate != "null") {
        searchParams.append("endDate", this.search_provisional_endDate);
    }

    if (num_page != null) {
        searchParams.append("page", num_page);
    }

    // Construir la query string
    //console.log(this.search_query);
    // if(this.search_query == null || this.search_query == 'null'){
      this.search_query = searchParams.toString() ? `?${searchParams.toString()}` : null;
    // } else {
      //  this.search_query += searchParams.toString() ? `&${searchParams.toString()}` : null;
    // }
   
    //console.log(this.search_provisional_source + "source en busqueda");
    
    

    // Llamar a getLeads si no hay paginación
    if (num_page == null) {
        await this.getLeads();
    }
  },

  async clearSearch() {
      this.search_query = null;
      this.search_provisional_name = '';
      this.search_provisional_email = '';
      this.search_provisional_type = '';
      this.search_provisional_source = '';
      this.search_provisional_startDate = '';
      this.search_provisional_endDate = '';
      this.getLeads();
  },

  },
};
</script>

<style scoped>
/* Estilo para la flecha de ordenamiento */
.arrow-up::after {
  content: '\25B2'; /* Unicode para triángulo hacia arriba */
  margin-left: 5px;
}

.arrow-down::after {
  content: '\25BC'; /* Unicode para triángulo hacia abajo */
  margin-left: 5px;
}



</style>