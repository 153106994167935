<template>
  <div v-if="proposal != null">
    <section class="pdf-item">
      <div style="height: 1080px" class="relative">
        <div class="p-10">
          <div class="flex justify-center">
            <div style="max-width: 20vh" href="">
              <h2 class="text-xl mb-5 font-light">PROPOSED BY</h2>
              <div style="max-width: 300px; max-height: 18vh">
                <img
                  v-if="imagenBrand != null"
                  :src="imagenBrand"
                  alt=""
                  style=""
                  class="img-fluid"
                />
                <img
                  v-else
                  class="img-fluid mx-auto text-center"
                  style="height: 18vh; width: auto"
                  src="imagenBrand"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="flex mt-10">
            <div class="w-1/2 mr-3 justify-center flex">
              <div class="w-11/12">
                <h2 class="text-2xl mb-2 font-medium">
                  NEW BUSINESS PROPOSAL FORM
                </h2>
                <p v-if="type == 'lease'" class="text-2xl mb-5 font-light">
                  FINANCE LEASE
                </p>
                <p v-else class="text-2xl mb-5 font-light">HIRE PURCHASE</p>
              </div>
            </div>
            <div v-if="brand" class="w-1/2">
              <p class="mt-1">
                <i
                  style="padding: 2px 4px"
                  class="fas fa-phone-alt mr-3 bg-black text-white rounded-md text-sm"
                ></i>
                {{ brand.telephone != null ? brand.telephone : "--" }}
              </p>
              <p class="mt-1">
                <i
                  style="padding: 2px 4px"
                  class="fas fa-globe mr-3 bg-black text-white rounded-md text-sm"
                ></i>
                {{ brand.url }}
              </p>
              <p class="mt-1">
                <i
                  style="padding: 2px 4px"
                  class="fas fa-envelope mr-3 bg-black text-white rounded-md text-sm"
                ></i>
                {{ brand.email }}
              </p>
              <p class="mt-1">
                <i
                  style="padding: 2px 4px"
                  class="fas fa-house-user mr-3 bg-black text-white rounded-md text-sm"
                ></i>

                {{ brand.address_line_1 }}, {{ brand.address_line_2 }}
              </p>
            </div>
          </div>
          <div class="flex form-check my-8">
            <input
              class="form-check-input appearance-none h-5 w-5 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-12 cursor-pointer"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label
              style="font-size: 15px"
              class="font-regular form-check-label inline-block text-gray-800"
              for="flexCheckDefault"
            >
              We confirm that the Director(s)/Proprietor(s) Individual(s) named
              in this proposal have given their permission for third parties to
              use their personal information for the purposes of searches at
              Credit Reference and/or Fraud Prevention Agencies for the specific
              purpose of ascertaining their credit worthiness.
            </label>
          </div>
          <div class="">
            <div class="flex my-2">
              <p class="font-bold mr-3 text-sm">Customer</p>
              <p class="w-full bg-gray-200 rounded-full text-sm px-1">
                {{ proposal.company_name }}
              </p>
            </div>
            <div class="flex">
              <p class="font-bold mr-3 text-sm">Address</p>
              <p class="w-full bg-gray-200 rounded-full px-1">
                {{ proposal.address }}
              </p>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-9/12 font-bold mr-3 text-sm">Company Number</p>
                <p class="w-full bg-gray-200 rounded-full text-sm px-1">
                  {{ proposal.company_number }}
                </p>
              </div>
              <div class="flex">
                <p class="w-11/12 font-bold mr-3 text-sm">
                  Business Established
                </p>
                <p class="w-full bg-gray-200 rounded-full text-sm px-1">
                  {{ proposal.business_established }}
                </p>
              </div>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-9/12 font-bold mr-3 text-sm">Guarantee Available</p>
                <p class="w-full bg-color rounded-full text-sm px-1"></p>
              </div>
              <div class="flex">
                <p class="w-9/12 font-bold mr-3 text-sm">Contact Number</p>
                <p class="w-full bg-color rounded-full text-sm px-1"></p>
              </div>
            </div>
          </div>
          <div class="flex items-center mt-8">
            <h3
              :style="{ 'background-color': brand.color }"
              class="line-height flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Partners/<br />Directors
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              D.O.B
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-3/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Address
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Owner
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Value
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Mortgage
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="line-height flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full"
            >
              Years at Address
            </h3>
          </div>

          <div
            class="flex my-3"
            v-for="director in proposal.directors"
            v-bind:key="director.name"
          >
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              {{ director.name }}
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- D.O.B -->
            </div>
            <div class="w-3/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              {{ director.address.address_line_1 }}
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Owner -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Value -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Mortgage -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 text-xs p-1">
              <!-- Years atAddress -->
            </div>
          </div>

          <div class="">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center bg-black text-white font-medium rounded-full mb-3"
            >
              Goods
            </h3>
            <div class="w-full bg-color rounded-lg h-16 mr-1">
              <!-- Goods -->
            </div>
          </div>
          <div class="flex items-center mt-3">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Reg
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              New/Used
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Mileage
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-4/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Supplier
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Price
            </h3>
          </div>
          <!-- 1º row -->
          <div class="flex my-3">
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Reg -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- New/Used -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Mileage -->
            </div>
            <div class="w-4/12 bg-color rounded-lg h-16 mr-1 text-xs p-1">
              <!-- Supplier -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-16 text-xs p-1">
              <!-- Price -->
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 right-5">
          <p class="text-sm text-gray-300 font-medium">
            Page 1. Scroll down for more information
          </p>
        </div>
      </div>
    </section>

    <!--
        After this element below, the page will break and any elements after
        <div class="html2pdf__page-break"/> will go to the next page.
    -->
    <div class="html2pdf__page-break" />
    <section class="pdf-item">
      <div style="height: 1080px" class="relative">
        <div class="p-10">
          <div class="">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              Goods
            </h3>
            <div class="w-full bg-color rounded-lg h-20 mr-1">
              <!-- Goods -->
            </div>
          </div>
          <div class="flex items-center my-3">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Reg
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              New/Used
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Mileage
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-4/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Supplier
            </h3>
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 w-2/12 text-sm text-center text-white font-medium rounded-full mr-1"
            >
              Price
            </h3>
          </div>
          <!-- 1º row -->
          <div class="flex my-3">
            <div class="w-2/12 bg-color rounded-lg h-12 mr-1 text-xs p-1">
              <!-- Reg -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-12 mr-1 text-xs p-1">
              <!-- New/Used -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-12 mr-1 text-xs p-1">
              <!-- Mileage -->
            </div>
            <div class="w-4/12 bg-color rounded-lg h-12 mr-1 text-xs p-1">
              <!-- Supplier -->
            </div>
            <div class="w-2/12 bg-color rounded-lg h-12 mr-1 text-xs p-1">
              <!-- Price -->
            </div>
          </div>
          <h3
            :style="{ 'background-color': brand.color }"
            class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3 mt-8"
          >
            Figures
          </h3>
          <div class="flex justify-end">
            <p class="mr-2 font-medium">VAT %</p>
            <p class="w-24 rounded-full bg-color"></p>
          </div>
          <div class="my-4">
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-9/12 font-medium mr-3 text-sm">
                  Cash Price (Ex VAT)
                </p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
              <div class="flex">
                <p class="w-8/12 font-medium mr-3 text-sm">Deposit</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-2/12 font-medium mr-3 text-sm">VAT</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
              <div class="flex">
                <p class="w-2/12 font-medium mr-3 text-sm">Advance</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-5/12 font-medium mr-3 text-sm">Non-VAT Items</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
              <div class="flex">
                <p class="w-5/12 font-medium mr-3 text-sm">Term (months)</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="grid grid-cols-2 mr-2">
                <p class="font-medium mr-3 text-sm">
                  Deferred VAT? - Month (?)
                </p>
                <p class="bg-color rounded-full text-sm"></p>
              </div>
              <div class="flex">
                <p class="w-2/12 font-medium mr-3 text-sm">Overdraft</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
            </div>
            <div class="grid grid-cols-2 my-2">
              <div class="flex mr-2">
                <p class="w-2/12 font-medium mr-3 text-sm">Balloon</p>
                <p class="w-full bg-color rounded-full text-sm"></p>
              </div>
            </div>
          </div>
          <div class="mt-8 mb-3">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              Business Background
            </h3>
            <div class="w-full bg-color rounded-lg p-3 mr-1 text-sm">
              <p>
                The client has approached us to look to arrange finance on their
                behalf.
              </p>
              <p class="mt-5">
                The business is run by {{ getDirectors() }} &nbsp;who established the
                business in {{ proposal.town }} &nbsp;in
                {{ proposal.business_established }}.
              </p>
              <p class="mt-5">
                The client can demonstrate experience - please provide 3 or 4
                lines about experience (do the have a website that you can get
                some knowledge from).
              </p>
              <p class="mt-5">
                The client is a (homeowner or tenant) and is willing to offer
                their personal guarantee to support the deal.
              </p>
            </div>
          </div>
          <div class="mt-8 mb-3">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              Reason for purchase?
            </h3>
            <div
              style="min-height: 10rem"
              class="w-full bg-color rounded-lg p-3 mr-1 text-sm"
            >
              <p>
                The client is looking to purchase asset to (replace an existing
                asset that is currently costing £X per month/or add to their
                existing fleet and except income from the asset of £X per month
              </p>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 right-5">
          <p class="text-sm text-gray-300 font-medium">
            Page 2. Scroll down for more information
          </p>
        </div>
      </div>
    </section>
    <div class="html2pdf__page-break" />
    <section class="pdf-item">
      <div class="relative">
        <div class="p-10">
          <div class="">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              Trading Information
            </h3>
            <div
              style="min-height: 10rem"
              class="w-full bg-color rounded-lg mr-1 p-3"
            >
              <p>
                The client has provided bank statements that (show good
                affordability for the deal proposed/or are stable and will only
                be enhaced by the income the proposed asset will bring)
              </p>
              <p class="mt-3">
                ** Please check if there are bounced payments - f not proceed if
                so we need to know why)
              </p>
            </div>
          </div>
          <div class="my-4">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              COVID-19
            </h3>
            <div
              style="min-height: 10rem"
              class="w-full bg-color rounded-lg mr-1 text-sm p-3"
            >
              <p>
                The client is not negatively impacted by Covid 19 given its new
                start nature.
              </p>
            </div>
          </div>
          <div class="my-4">
            <h3
              :style="{ 'background-color': brand.color }"
              class="flex justify-center items-center h-7 text-sm text-center text-white font-medium rounded-full mb-3"
            >
              Recommendations for support
            </h3>
            <div
              style="min-height: 10rem"
              class="w-full bg-color rounded-lg mr-1 text-sm p-3"
            >
              <p>Based on the above we recommend this client for finance</p>
              <p>Business critial</p>
              <p>Income generating asset</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  props: ["proposal", "brand", "imagenBrand", "type"],

  data() {
    return {};
  },

  methods: {
    getDirectors() {
      let directorsStr = "";
      for (let i = 0; i < this.proposal.directors.length; i++) {
        if (directorsStr != "") {
          if (i == this.proposal.directors.length - 1) {
            directorsStr += " & ";
          } else {
            directorsStr += ", ";
          }
        }
        directorsStr += this.proposal.directors[i].name;
      }

      return directorsStr;
    },
  },
};
</script>

<style></style>
