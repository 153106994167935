import * as API from "@/services/af-quote/API";

export default class FormService {

    async createForm(form) {
        try {
            let data = await API.apiClient.post("/api/create-form", form);
            return data.data;
        } catch (error) {
            return error;
        }
    }
    async getForms(query=null) {
        try {
             if (query == null) {
                 const data = await API.apiClient.get("api/index-form");
                 return data.data;

             } else {
                const data = await API.apiClient.get("api/index-form"+query);
                return data.data;

            }
        } catch (error) {
            return error;
        }
    }
    async getForm(id) {
        try {
            const data = await API.apiClient.get("api/show-form/" + id);
            return data.data;
        } catch (error) {
            return error;
        }
    }
    async updateForm(id, form) {
        try {
            const data = await API.apiClient.post("api/update-form/" + id, form);
            return data.data;
        } catch (error) {
            return error;
        }
    }
    async deleteForm(id) {
        try {
            const data = await API.apiClient.delete("api/delete-form/" + id);
            return data.data;
        } catch (error) {
            return error;
        }
    }
}