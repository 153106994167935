<template>
  <div style="min-height: 94vh" class="bg-white w-full">
    <title>Zenfiny | Create Proposal</title>
    <div
      class="w-full bg-secondary py-2 flex justify-between items-center px-2 lg:px-24"
    >
      <h1 class="text-white text-xl lg:text-2xl font-bold">
        <i class="fas fa-handshake mr-1"></i>
        NEW PROPOSAL
      </h1>
    </div>
    <form class="w-10/12 mx-auto my-10">
      <div class="flex justify-end">
        <div class="flex justify-center">
          <div class="form-check mr-4">
            <input
              class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              v-model="quote_type"
              value="hire"
              name="type"
              id="radioHire"
            />
            <label
              class="form-check-label inline-block text-gray-800"
              for="radioHire"
            >
              Hire purchase
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              v-model="quote_type"
              value="lease"
              id="radioLease"
              name="type"
            />
            <label
              class="form-check-label inline-block text-gray-800"
              for="radioLease"
            >
              Lease
            </label>
          </div>
        </div>
      </div>
      <label
        for="brandStep"
        class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-lg mt-3 lg:mt-8"
        >Brand:</label
      >
      <select
        v-model="selected_brand"
        type="select"
        @change="onChangeBrand($event)"
        class="form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
        v-bind:class="{
          'form-control': true,
        }"
      >
        <option
          v-for="brand in all_brands"
          v-bind:key="brand.id"
          :value="brand.id"
        >
          {{ brand.name }}
        </option>
      </select>
      <label
        class="mt-5 form-label inline-block mb-1 color-grey px-3 text-md lg:text-lg"
        >Company Name:</label
      >
      <input
        v-model="form.company_name"
        @input="onChangeCompany"
        type="text"
        class="form-control block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
        v-bind:class="{
          'form-control': true,
          'is-invalid': form.company_name == null,
        }"
      />

      <ul
        v-show="isCompanyOpen"
        class="autocomplete-results border-2 border-gray-200 rounded-lg p-2"
      >
        <li
          v-for="(result, i) in companies"
          :key="i"
          @click="setResult(result)"
          class="autocomplete-result hover:bg-blue-200 cursor-pointer"
        >
          {{ result.title }}
        </li>
      </ul>
      <div
        v-if="form.company_name"
        class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-8"
      >
        <div class="form-group">
          <label
            for="exampleInputPassword1"
            class="form-label inline-block mb-1 color-grey px-3 text-md lg:text-lg"
            >Address:</label
          >
          <input
            v-model="form.address"
            class="form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid': form.address == null,
            }"
          />
        </div>
        <div class="form-group">
          <label
            for="exampleInputPassword1"
            class="form-label inline-block mb-1 color-grey px-3 text-md lg:text-lg"
            >Business Established:</label
          >
          <input
            v-model="form.business_established"
            @input="checkFields()"
            class="form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid': form.business_established == null,
            }"
          />
        </div>
      </div>
      <div v-if="form.address" class="form-group">
        <label for="exampleInputPassword1" class="form-label inline-block mb-1 color-grey px-3 text-md lg:text-lg">Directors</label>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4" v-if="form.directors.length > 0">
              <input
                v-for="(director, index) in form.directors"
                :key="index"
                v-model="director.name"
                placeholder="Enter director's name"
                class="form-control block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-bind:class="{'form-control': true, 'is-invalid': !director.name}"
              />
        </div>
</div>
    </form>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :quality="0.2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="800px"
      margin:0
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="m-0 p-0">
        <proposal-pdf
          class="m-0 p-0"
          :type="quote_type"
          :proposal="proposal"
          :brand="brand"
          :imagenBrand="imagenBrand"
        ></proposal-pdf>
      </section>
    </vue-html2pdf>

    <div class="w-11/12 mx-auto pb-5">
      <a
        @click="fillForm()"
        href="#"
        class="flex justify-center mt-10 lg:mt-15"
      >
        <div
          class="w-4/6 lg:w-2/6 btn-primary font-regular py-2 text-lg lg:text-xl rounded-full text-center"
        >
          <i class="fas fa-download mr-1"></i> PDF Proposal
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import BrandService from "../../services/af-quote/BrandService";
import CompanyHouseService from "../../services/company-house/CompanyHouseService";
import VueHtml2pdf from "vue-html2pdf";
import ProposalPdf from "./ProposalPdf.vue";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";

export default {
  name: "register-proposal",
  components: {
    VueHtml2pdf,
    ProposalPdf,
  },

  data() {
    return {
      form: {
        company_name: "",
        company_num: null,
        address: null,
        directors: [
        { name: "", address: "", date_of_birth: "" },
        { name: "", address: "", date_of_birth: "" },
        { name: "", address: "", date_of_birth: "" },
        { name: "", address: "", date_of_birth: "" },
      ],
        business_established: null,
        town: null,
      },
      companies: [],
      search: null,
      submitted: false,
      isCompanyOpen: false,
      proposal: null,
      all_brands: null,
      selected_brand: null,
      brand: null,
      imagenBrand: null,
      quote_type: "hire",
    };
  },

  computed: {
    // directorsList() {
    // // Asegurarse de que siempre hay al menos 4 elementos en la lista de directores
    // return this.form.directors.length >= 4 
    //   ? this.form.directors 
    //   : [...this.form.directors, ...Array(4 - this.form.directors.length).fill({ name: "", address: "", date_of_birth: "" })];
    // },
  },

  async created() {
    await this.getAllBrands();
  },

  methods: {
    pdfFileName: function () {
      var date = this.date(Date.now());
      var type = "HP";
      if (this.quote_type == "hire") {
        type = "HP";
      } else {
        type = "Lease";
      }

      return (
        "Proposal" +
        "-" +
        type +
        "-" +
        (this.form.company_name != null ? this.form.company_name : "pdf") +
        "-" +
        date +
        ".pdf"
      );
    },

    async fillForm() {
  let formUrl = "";

  if (this.quote_type == "hire" && this.form.directors.length <= 2) {
    formUrl = "/pdf/HPDirectors_New_Business_Proposal_Form.pdf";
  } else if (this.quote_type != "hire" && this.form.directors.length <= 2) {
    formUrl = "/pdf/Lease_New_Business_Proposal_Form.pdf";
  }

  if (this.quote_type == "hire" && this.form.directors.length > 2) {
    formUrl = "/pdf/HPDirectors_New_Business_Proposal_Form.pdf";
  } else if (this.quote_type != "hire" && this.form.directors.length > 2) {
    formUrl = "/pdf/LeaseDirectors_New_Business_Proposal_Form.pdf";
  }
  

  const formPdfBytes = await fetch(formUrl).then((res) =>
    res.arrayBuffer()
  );

  const pdfDoc = await PDFDocument.load(formPdfBytes);
  //console.log("PDF Document: ", pdfDoc);
  const form = pdfDoc.getForm();

  const customerNameField = form.getTextField("Customer Name");
  const addressField = form.getTextField("Customer Address");
  const companyNumberField = form.getTextField("Customer Company Number");
  const businessEstablishedField = form.getTextField("Customer Business Established");
  const businessBackground = form.getTextField("Business background");

  customerNameField.setText(this.form.company_name);
  addressField.setText(this.form.address);
  companyNumberField.setText(this.form.company_number);
  businessEstablishedField.setText(this.form.business_established);

  const directorFields = [
    { name: "Partners/Directors_1", address: "Address_1", dob: "Date_of_Birth_1" },
    { name: "Partners/Directors_2", address: "Address_2", dob: "Date_of_Birth_2" },
    { name: "Partners/Directors_3", address: "Address_3", dob: "Date_of_Birth_3" },
    { name: "Partners/Directors_4", address: "Address_4", dob: "Date_of_Birth_4" },
  ];

  //console.log("Directors: ", this.form.directors);

  for (let i = 0; i < 4; i++) {
    const director = this.form.directors[i];
    //console.log("Director " + (i + 1) + ": ", director);
    if (director) {
      form.getTextField(directorFields[i].name).setText(director.name || "");
      form.getTextField(directorFields[i].address).setText(director.address ? director.address.address_line_1 || "" : "");
      try {
        form.getTextField(directorFields[i].dob).setText(
          director.date_of_birth
            ? `${this.PadLeft(director.date_of_birth.month, 2)}/${director.date_of_birth.year}`
            : ""
        );
      } catch (e) {
        console.error('Director date of birth is wrong: ' + director);
        form.getTextField(directorFields[i].dob).setText("");
      }
    } else {
      form.getTextField(directorFields[i].name).setText("");
      form.getTextField(directorFields[i].address).setText("");
      form.getTextField(directorFields[i].dob).setText("");
    }
  }

  businessBackground.setText(
    [
      "The client has approached us to look to arrange finance on their behalf.",
      `The business is run by ${this.getDirectors()} who established the business in ${this.form.town} in ${this.form.business_established}.`,
      "The client can demonstrate experience - please provide 3 or 4 lines about experience (do they have a website that you can get some knowledge from).",
      "The client is a (homeowner or tenant) and is willing to offer their personal guarantee to support the deal. ",
    ].join("\n")
  );

  const pdfBytes = await pdfDoc.save();
  let url = this.pdfFileName();

  download(pdfBytes, url, "public/pdf");
},

    checkFields() {
      let ok = true;

      if (this.form.company_name == null || this.form.company_name == "")
        ok = false;

      if (this.form.address == null || this.form.address == "") ok = false;

      if (
        this.form.business_established == null ||
        this.form.business_established == ""
      )
        ok = false;

      if (this.form.directors && this.form.directors.length == 0) ok = false;

      return ok;
    },

    getDirectors() {
      let directorsStr = "";
      //console.log('hola',this.form.directors);
      for (let i = 0; i < this.form.directors.length; i++) {
        if (directorsStr != "") {
          if (i == this.form.directors.length - 1) {
            directorsStr += " & ";
          } else {
            directorsStr += ", ";
          }
        }
        directorsStr += this.form.directors[i].name;
      }
      return directorsStr;
    },

    async getAllBrands() {
      const brand_service = new BrandService();
      this.all_brands = await brand_service.getAllBrands();
    },

    async getDataByBrand() {
      const brand_service = new BrandService();
      this.brand = await brand_service.getBrand(this.selected_brand);
      this.imagenBrand = await brand_service.getImg(this.selected_brand);
    },

    generateReport() {
      if (this.companies.length > 0) {
        this.proposal = this.form;
        this.$refs.html2Pdf.generatePdf();
      }
    },
    async filterCompanies() {
      //Filter by company name
      const company_service = new CompanyHouseService();
      let external = await company_service.getAllNamesCompanies(
        this.form.company_name
      );
      external.forEach((element) => {
        this.companies.push(element);
      });
    },

    async onChangeCompany() {
      // Call to company house service
      if (this.form.company_name != "") {
        this.isCompanyOpen = true;
        this.filterCompanies();
        this.companies = [];
      }
    },
    setResult(result) {
      //Setting the result with company_number
      this.search = result;
      this.form.company_number = result.company_number;
      this.form.company_name = result.title;
      this.form.town = result.address.locality;
      this.isCompanyOpen = false;
      this.fillFields();
      this.searchDirectors(this.search.company_number);
    },

    async fillFields() {
      //this.form.address = this.search.address.address_line_1;
      this.form.address = this.search.address_snippet;
      this.form.business_established = this.search.date_of_creation;
    },

    async searchDirectors(company_number) {
      const company_service = new CompanyHouseService();
      const aux = await company_service.getPersonsWSC(company_number);
      if(aux.length > 0) {
        this.form.directors = aux;
      }
      this.checkFields();
    },

    onChangeBrand() {
      this.getDataByBrand();
    },

    date(value) {
      return moment(value).format("DD/MM/yyyy");
    },
    PadLeft(value, length) {
      return value.toString().length < length
        ? this.PadLeft("0" + value, length)
        : value;
    },
  },
};
</script>
<style lang="scss">
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
  vertical-align: middle;
}
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result:hover {
  background-color: #00a8d3;
  color: white;
}
</style>
