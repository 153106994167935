<template>
  <div class="bg-white w-100 h-full">
    <title>Zenfiny | Register</title>
    <div style="min-height: 94vh" class="w-12/12">
      <div
        class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10 mb-4"
      >
        <h1 class="text-white text-xl lg:text-2xl font-bold">
          <i class="fas fa-users mr-2"></i>
          NEW USER
        </h1>
      </div>
      <form class="w-10/12 mx-auto my-5">
        <div class="form-group mb-5">
          <div
            v-if="correct_register"
            class="bg-auto text-center rounded text-base font-normal mb-2 color-black bg-green-400 text-lg lg:text-xl"
          >
            <p class="mx-2 justify-center">
              User {{ this.username }} created, click
              <router-link class="underline font-medium" to="/users"
                >here</router-link
              >
              to see all the users
            </p>
          </div>
          <div
            v-else-if="error_register"
            class="bg-auto text-center rounded text-base font-normal mb-2 color-black bg-red-400 text-lg lg:text-xl"
          >
            <p class="mx-2 justify-center">
              Error creating the user, please try again
            </p>
          </div>
          <label
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"
            >Name:</label
          >
          <input
            v-model.lazy="username"
            @change="correct_register = false"
            type="text"
            class="form-control block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid': username == '' && password_blured,
            }"
          />
          <div class="invalid-feedback">Name cannot be empty</div>
        </div>
        <div class="form-group my-5">
          <label
            for="exampleInputPassword1"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"
            >Email:</label
          >
          <input
            v-model="email"
            @change="correct_register = false"
            type="email"
            class="form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid':
                ((email == '' || !validEmail(email)) && password_blured) ||
                error_mail,
            }"
          />
          <div class="invalid-feedback">Not valid email. {{this.error_mail_msg[0]}}</div>
        </div>
        <div class="form-group my-5">
          <label
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"
            >Password:</label
          >
          <input
            v-model="password"
            @change="correct_register = false"
            type="password"
            class="form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid': !validPassword(password) && password_blured,
            }"
          />
          <div class="invalid-feedback">
            The password must have at least eight characters
          </div>
        </div>
        <div class="form-group my-5">
          <label
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"
            >Confirm password:</label
          >
          <input
            v-model="confirm_password"
            @change="correct_register = false"
            type="password"
            class="form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            v-bind:class="{
              'form-control': true,
              'is-invalid': password != confirm_password && password_blured,
            }"
          />
          <div class="invalid-feedback">The passwords are not the same</div>
        </div>
        <div class="flex justify-center mt-12">
          <button
            v-on:click.stop.prevent="submit"
            class="w-4/6 lg:w-2/6 btn-primary font-regular py-2 text-lg lg:text-xl rounded-full"
          >
            <i class="fas fa-plus-circle pr-3"></i>
            Add User
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/af-quote/AuthService";

export default {
  data() {
    return {
      username: "",
      email: "",
      password: null,
      confirm_password: null,
      password_blured: false,
      error_mail: false,
      error_mail_msg: "",

      correct_register: false,
      error_register: false,
    };
  },

  methods: {
    async submit() {
      try {
        this.error_register = false;
        this.correct_register = false;
        this.password_blured = true;
        this.error_mail_msg="";
        this.error_mail = false;
        if (
          !this.validPassword(this.password) ||
          this.password != this.confirm_password ||
          !this.validEmail(this.email) ||
          this.username == null
        )
          return false;
        let form = {
          name: this.username,
          email: this.email,
          password: this.password,
        };
        const response = await AuthService.registerUser(form);
        // console.log("response: ", response);
        this.correct_register = true;
        return true;
      } catch (error) {
        // console.log("eeeeeeeee", error.response.data.message.email);
        if (error.response.data.message.email) {
            this.error_mail=true;
            this.error_mail_msg = error.response.data.message.email;

        }
        console.error(error);
        this.error_register = true;
        return false;
      }
    },

    validPassword(str) {
      return str != null && str.length > 7;
    },

    validEmail(str) {
      let re = /[a-zA-Z0-9]+@[a-zA-Z]+.[a-zA-Z0-9]+/;
      return re.test(str);
    },
  },
};
</script>
