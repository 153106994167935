<template>
  <div style="min-height: 92vh" class="bg-white">
    <div class="bg-white w-full list">
      <title>Zenfiny | List Users</title>
      <div
        class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
      >
        <h1 class="text-white text-xl lg:text-2xl font-bold">
          <i class="fas fa-users mr-2"></i>
          LIST USERS
        </h1>
        <div class="grid grid-cols-2 gap-4 mt-3 lg:mt-0">
          <a
            href="/register"
            class="text-sm lg:text-md font-medium btn-secondary text-center inline-block p-1 lg:p-2 leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
          >
            <i class="fas fa-plus-circle mx-1"></i> ADD USER
          </a>
          <button
            class="text-sm lg:text-md font-medium btn-search text-center inline-block p-1 lg:p-2 leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
            @click="toggleCollapse"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <i class="fas fa-search color-text mx-1"></i> SEARCH FILTER
          </button>
        </div>
      </div>
      <div class="w-11/12 mx-auto">
      <transition name="slide-fade">
        <!-- contenido del Collapse -->
        <div v-if=isCollapsed class="my-4" id="collapseExample">
          <div class="block p-6 rounded-lg shadow-lg bg-color">
            <div class="form-group mb-6">
              <label
                for="nameLisUser"
                class="form-label inline-block mb-2 color-grey font-regular px-3"
                >Name</label
              >
              <input
                v-model="search_provisional_name"
                type="text"
                class="form-control block w-full px-3 py-2 text-sm font-normal t ext-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                id="nameLisUser"
                placeholder=""
              />
            </div>
            <div class="form-group mb-6">
              <label
                for="emailLisUser"
                class="form-label inline-block mb-2 color-grey font-regular px-3"
                >Email</label
              >
              <input
                v-model="search_provisional_email"
                type="text"
                class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                id="emailLisUser"
                placeholder=""
              />
            </div>
            <div class="flex justify-end items-center">
              <div class="form-check pr-5">
                <input
                  v-model="search_deleted"
                  class="form-check-input h-4 w-4 border border-gray-300 focus:accent-indigo-100 rounded-sm bg-white accent-indigo-100 transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  style="background-color"
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="flexCheckDefault"
                >
                  Deleted Users
                </label>
              </div>
              <button
                v-on:click.stop.prevent="
                  setSearch();
                  getUsers();
                "
                class="btn-primary inline-block px-2 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-purple-600 focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-2/12"
                type="button"
              >
                <i class="fas fa-search color-black mx-1"></i> SEARCH
              </button>
            </div>
          </div>
        </div>
      </transition>
      <!-- fin del collapse-->
        <div class="flex flex-col mt-5">
          <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-5">
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="bg-primary border-b-2">
                    <tr>
                      <th
                        scope="col"
                        class="text-sm font-regular text-white px-4 py-2 text-left"
                      >
                        User
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-regular text-white px-4 py-2 text-left"
                      >
                        Email
                      </th>
                      <th
                        v-if="!showing_deleted"
                        scope="col"
                        class="text-sm font-regular text-white px-2 py-2 text-left w-20"
                      >
                        Actions
                      </th>
                      <th
                        v-else
                        scope="col"
                        class="text-sm font-regular text-white px-2 py-2 text-left"
                      >
                        Restore
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="user in users"
                      v-bind:key="user.id"
                      class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                    >
                      <td
                        class="text-sm text-gray-900 font-semibold px-4 py-2 whitespace-nowrap"
                      >
                        <a
                          @click="
                            setUserDetail(user);
                            modalDeleteBrand = false;
                            modalDeleteUser = false;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCenter"
                          class="cursor-pointer"
                          >{{ user.name }}</a
                        >
                      </td>
                      <td
                        class="text-sm text-gray-900 font-medium px-2 py-2 whitespace-nowrap"
                      >
                        {{ user.email }}
                      </td>
                      <td
                        v-if="!showing_deleted"
                        class="text-md text-left font-medium px-4 py-2 whitespace-nowrap"
                      >
                        <button
                          @click="
                            user_delete_id = user.id;
                            modalDeleteBrand = false;
                            modalDeleteUser = true;
                            user_detail = null;
                          "
                          title="Delete"
                          type="button"
                          class="inline-block py-2 font-medium text-md leading-tight color-delete transition duration-150 ease-in-out hover:opacity-70"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalCenter"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                      <td
                        v-else
                        class="text-md text-left font-medium px-4 py-2 whitespace-nowrap"
                      >
                        <button
                          @click="restoreUser(user.id)"
                          type="button"
                          title="Restore"
                          class="inline-block py-2 font-medium text-md leading-tight color-restore transition duration-150 ease-in-out hover:opacity-70"
                        >
                         <i class="fas fa-undo"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Assigned brands -->
        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="exampleModalCenter"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
        >
          <div
            v-if="!modalDeleteBrand && !modalDeleteUser && details"
            class="modal-xl modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                v-if="user_detail != null"
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
              >
                <h5
                  class="text-xl font-regular leading-normal text-white"
                  id="exampleModalScrollableLabel"
                >
                  Assigned brands to {{ user_detail.name }}
                </h5>
                <button
                  type="button"
                  class="box-content w-4 h-4 p-1 text-white border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="modal-body relative rounded-lg px-2 py-2">
                <div class="bg-color py-4 px-2 rounded-lg">
                  <h3 class="text-lg font-regular px-2">Select brand</h3>
                  <label
                    v-if="
                      selectable_brands == null || selectable_brands.length == 0
                    "
                    class="text-green-400"
                    >All brands assigned</label
                  >
                  <div class="flex mt-3">
                    <div class="w-full flex relative items-center">
                      <select
                        :disabled="
                          selectable_brands == null ||
                          selectable_brands.length == 0
                        "
                        v-model="selected_brand"
                        class="block appearance-none border-none w-full mr-3 py-2 px-2 leading-tight focus:outline-none text-gray-400 rounded-full px-2 py-2"
                        id="grid-state"
                      >
                        <option
                          v-for="brand in this.selectable_brands"
                          :value="brand.id"
                          v-bind:key="brand.id"
                        >
                          {{ brand.name }}
                        </option>
                      </select>
                      <button
                        type="button"
                        v-on:click.stop.prevent="addBrandToUser()"
                      >
                        <i
                          class="fas fa-plus-circle text-2xl color-primary"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col mt-3 overflow-x-auto sm:-mx-6 lg:-mx-8"
                >
                  <div class="py-2 inline-block min-w-full sm:px-6 lg:px-5">
                    <table class="min-w-full overflow-hidden">
                      <thead class="border-b">
                        <tr>
                          <th
                            scope="col"
                            class="text-sm font-regular text-gray-900 p-2 text-left"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-regular text-gray-900 p-2 text-center"
                          >
                            Brand
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-regular text-gray-900 p-2 text-center"
                          >
                            Delete
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="brand in user_brands"
                          v-bind:key="brand.id"
                          class="border-b"
                        >
                          <td
                            class="text-sm text-gray-900 font-normal p-2 whitespace-nowrap"
                          >
                            {{ brand.name }}
                          </td>
                          <td
                            class="text-sm text-gray-900 font-normal p-2 whitespace-nowrap text-center"
                          >
                            <img
                              v-if="brand.img != ''"
                              class="h-16 mx-auto"
                              :src="brand.img"
                              alt=""
                            />
                            <img
                              v-else
                              class="h-16 mx-auto"
                              src="img/ejemplo.brand.png"
                              alt=""
                            />
                          </td>
                          <td
                            class="text-sm text-gray-900 font-normal p-2 whitespace-nowrap text-center"
                          >
                            <button
                              v-on:click.stop.prevent="
                                brand_selected_delete = brand.id;
                                modalDeleteBrand = true;
                                modalDeleteUser = false;
                              "
                            >
                              <i
                                class="fas fa-trash-alt text-2xl color-delete mr-1"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal Delete -->
          <div
            v-else
            class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
              >
                <h5
                  v-if="modalDeleteBrand"
                  class="text-2xl font-regular leading-normal text-white"
                  id="exampleModalScrollableLabel"
                >
                  Delete brand from user
                </h5>
                <h5
                  v-else
                  class="text-2xl font-regular leading-normal text-white"
                  id="exampleModalScrollableLabel"
                >
                  Delete user
                </h5>
                <!-- No sale la X no entiendo por qué ya lo probaré -->
                <button
                  type="button"
                  @click="
                    modalDeleteBrand = false;
                    modalDeleteUser = false;
                    details = true;
                  "
                  class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray-200 hover:opacity-75 hover:no-underline"
                  aria-label="Cancel"
                  data-bs-dismiss="modal"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="modal-body relative py-6 px-4">
                <p v-if="modalDeleteBrand">
                  Are you sure you want to remove this brand from the user and
                  all the quotes realated?
                </p>
                <p v-else>
                  Are you sure you want to remove this user and all the quotes
                  realated?
                </p>
              </div>
              <div
                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
              >
                <button
                  v-if="modalDeleteBrand"
                  type="button"
                  v-on:click.stop.prevent="
                    deleteUserBrand(user_detail.id, brand_selected_delete);
                    modalDeleteBrand = false;
                    modalDeleteUser = false;
                    details = false;
                  "
                  class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  <i class="fas fa-trash-alt"></i>
                  Delete
                </button>
                <button
                  v-else
                  type="button"
                  v-on:click.stop.prevent="
                    deleteUser(user_delete_id);
                    modalDeleteUser = false;
                    modalDeleteBrand = false;
                  "
                  data-bs-dismiss="modal"
                  class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  <i class="fas fa-trash-alt"></i>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div aria-label="Page navigation" class="bg-transparent pb-4">
        <div class="flex flex-col mt-5">
          <ul class="inline-flex flex justify-center">
            <li>
              <button
                @click="getUsers(current_page - 1)"
                :disabled="current_page == 1"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black rounded-l-lg focus:shadow-outline hover:bg-indigo-100"
              >
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
            <li v-if="current_page > 1">
              <button
                @click="getUsers(1)"
                :disabled="current_page == 1"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                1
              </button>
            </li>
            <li v-if="current_page > 2">
              <button
                disabled
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                ...
              </button>
            </li>

            <li>
              <button
                disabled
                class="h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-black focus:shadow-outline"
              >
                {{ current_page }}
              </button>
            </li>

            <li v-if="current_page < last_page - 1">
              <button
                disabled
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                ...
              </button>
            </li>
            <li v-if="current_page < last_page">
              <button
                @click="getUsers(last_page)"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                {{ last_page }}
              </button>
            </li>

            <li>
              <button
                @click="getUsers(current_page + 1)"
                :disabled="current_page == last_page"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-black rounded-r-lg focus:shadow-outline hover:bg-indigo-100"
              >
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandService from "../../services/af-quote/BrandService";
import UserService from "../../services/af-quote/UserService";

export default {
  data() {
    return {
      isCollapsed: false,
      users: null,
      user_detail: null,
      user_brands: null,
      user_delete_id: null,

      all_brands: null,
      selected_brand: null,
      brand_selected_delete: null,
      selectable_brands: null,

      search_provisional_name: null,
      search_provisional_email: null,

      search_name: null,
      search_email: null,
      search_deleted: false,

      //pagination
      showing_deleted: false,
      current_page: null,
      last_page: null,
      show_deleted: false,

      modalDeleteBrand: false,
      modalDeleteUser: false,
      details: true,
    };
  },

  async created() {
    await this.getUsers();
    await this.getAllBrands();
    await this.getSelectableBrands();
  },

  methods: {
    toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  },
  async getUsers(num_page = null) {
    let query = this.buildSearchQuery();
    if (num_page != null)
      query += query != "" ? "&page=" + num_page : "page=" + num_page;
    try {
      const user_service = new UserService();
      const user_data = await user_service.getUsers(query);
      this.users = user_data.data;
      this.current_page = user_data.current_page;
      this.last_page = user_data.last_page;
      this.showing_deleted = this.search_deleted;
    } catch (error) {
      console.error(error);
    }
  },

  async getAllBrands() {
    const brand_service = new BrandService();
    this.all_brands = await brand_service.getAllBrands();
  },

  async setUserDetail(user) {
    this.user_detail = user;
    const brand_service = new BrandService();
    this.user_brands = await brand_service.getUserBrands(this.user_detail.id);
    this.getSelectableBrands();
  },

  async addBrandToUser(brand_id = null, user_id = null) {
    user_id = user_id != null ? user_id : this.user_detail.id;
    brand_id = brand_id != null ? brand_id : this.selected_brand;

    if (brand_id == null || user_id == null) return;

    const brand_service = new BrandService();

    try {
      await brand_service.addUserBrand({
        user_id: user_id,
        brand_id: brand_id,
      });
      this.user_brands = await brand_service.getUserBrands(user_id);
      this.getSelectableBrands();
    } catch (error) {
      console.error(error);
    }
  },

  async deleteUserBrand(user_id, brand_id) {
    const brand_service = new BrandService();
    try {
      await brand_service.deleteUserBrand(user_id, brand_id);
      this.user_brands = await brand_service.getUserBrands(
        this.user_detail.id
      );
      this.getSelectableBrands();
    } catch (error) {
      console.error(error);
    }
  },

  async restoreUser(id) {
    const user_service = new UserService();
    try {
      const data = await user_service.restoreUser(id);
      this.getUsers(
        this.users.length > 1 ? this.current_page : --this.current_page
      );
    } catch (error) {
      console.error(error);
    }
  },

  async deleteUser(id) {
    const user_service = new UserService();
    try {
      const data = await user_service.deleteUser(id);
      this.getUsers(
        this.users.length > 1 ? this.current_page : --this.current_page
      );
    } catch (error) {
      console.error(error);
    }
  },

  getSelectableBrands() {
    if (this.user_brands != null) {
      const user_brands = this.user_brands.map((x) => {
        return x.id;
      });

      this.selectable_brands = this.all_brands.filter((x) => {
        return !user_brands.includes(x.id);
      });
    } else this.selectable_brands = this.all_brands;
  },

  buildSearchQuery() {
    let query = "";
    if (this.search_deleted) query += "&deleted";
    if (this.search_name != null && this.search_name != "")
      query +=
        query == null
          ? "name=" + this.search_name
          : "&name=" + this.search_name;
    if (this.search_email != null && this.search_email != "")
      query +=
        query == null
          ? "email=" + this.search_email
          : "&email=" + this.search_email;
    return query;
  },

  setSearch() {
    this.search_email = this.search_provisional_email;
    this.search_name = this.search_provisional_name;
  },
},
};
</script>

<style>

.slide-fade-enter-active {
  transition: all 0.6s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active en <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

</style>