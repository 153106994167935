<template>
    <div style="min-height: 94vh;" class="bg-img flex justify-center items-center">
        <!-- WELCOME ADMIN -->
        <div class="w-full lg:w-3/6 p-4">
            <h1 class="cursor-grab pt-5 text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl">Welcome {{username}}!</h1>
            <router-link to="/quotes" class="flex justify-center mt-10 lg:mt-20">
                <div class="h-16 lg:h-24 xl:h-26 w-11/12 flex justify-center items-center rounded-full border-color py-2 lg:py-4">
                    <div class="w-9 md:w-10 lg:w-16">
                        <img class="img-fluid" src="img/icon-pound.png" alt="">
                    </div>
                    <div class="w-8/12">
                        <h3 class="text-center text-lg lg:text-2xl">TOTAL QUOTES</h3>
                        <p class="text-center text-md lg:text-2xl mt-2"><span class="font-medium">{{n_quotes}}</span> quotes</p>
                    </div>
                </div>
            </router-link>
            <router-link to="/users" class="flex justify-center mt-10">
                <div class="h-16 lg:h-24 xl:h-26 w-11/12 flex justify-center items-center rounded-full border-color py-2 lg:py-4">
                    <div class="w-1/12 w-16 text-center">
                        <i class="fas fa-users text-2xl lg:text-4xl xl:text-5xl pr-3 text-grey"></i>
                    </div>
                    <div class="w-8/12">
                        <h3 class="text-center text-lg lg:text-2xl">TOTAL USERS</h3>
                        <p class="text-center text-md lg:text-2xl mt-2"><span class="font-medium">{{n_users}}</span> users</p>
                    </div>
                </div>
            </router-link>
             <router-link to="/registerquote" class="flex justify-center mt-10 relative bottom-10 mx-auto">
                <div class="h-16 lg:h-20 w-11/12 mx-auto flex justify-center items-center btn-primary font mt-10 py-2 text-lg lg:text-2xl rounded-full flex justify-center items-center mx-auto hover:text-gray-400">
                    <i class="fas fa-plus-circle text-white pr-3"></i>
                    New quote
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

import UserService from '../../services/af-quote/UserService';
import QuoteService from '../../services/af-quote/QuoteService';


export default ({
    props:['username'],
    data() {
        return {
            n_users:null,
            n_quotes:null,
        };
    },
    async mounted() {
        try {
            const user_service = new UserService();
            this.n_users = await user_service.getLength();

            const quote_service = new QuoteService();
            this.n_quotes = await quote_service.getLength();
        } catch(error) {
            console.error(error);
        }
    }
})
</script>
