<template>
    <div style="min-height: 94vh;" class="bg-img flex justify-center items-center">
        <!-- WELCOME USER -->
        <div class="w-full lg:w-3/6 p-4 mt-5">
            <h1 class="cursor-grab pt-5 text-center text-2xl md:text-3xl lg:text-4xl xl:text-5xl">Welcome {{username}}!</h1>
            <router-link to="/registerquote" class="flex justify-center mt-10 lg:mt-20">
                <div class="h-16 lg:h-24 xl:h-26 w-11/12 flex justify-center items-center rounded-full border-color py-2 lg:py-4">
                    <div class="w-1/12 w-16 text-center">
                        <i class="fas fa-plus-circle text-2xl lg:text-4xl text-grey"></i> 
                    </div>
                    <div class="w-8/12">
                        <h3 class="text-center text-lg lg:text-2xl">NEW QUOTE</h3>
                    </div>
                </div>
            </router-link>
            <router-link to="/quotes" class="flex justify-center mt-10">
                <div class="h-16 lg:h-24 xl:h-26 w-11/12 flex justify-center items-center rounded-full border-color py-2 lg:py-4">
                    <div class="w-9 md:w-10 lg:w-12">
                        <img class="img-fluid" src="img/search-pound.png" alt="">
                    </div>
                    <div class="w-8/12">
                        <h3 class="text-center text-lg lg:text-2xl">VIEW QUOTES</h3>
                     </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

export default ({
    props:['username'],
})
</script>