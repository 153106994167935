<template>
    <div>
        <title>Zenfiny | Edit Form</title>
        <FormEdit/>
    </div>
</template>
<script>

import FormEdit from '../../components/FormEdit.vue';
export default {


    components: {
        FormEdit
    },

    data(){
        return{

        };
    },

    async created(){

    },

    methods: {

    }
}
</script>