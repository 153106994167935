var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white w-100 h-full"},[_c('title',[_vm._v("Zenfiny | Register")]),_c('div',{staticClass:"w-12/12",staticStyle:{"min-height":"94vh"}},[_vm._m(0),_c('form',{staticClass:"w-10/12 mx-auto my-5"},[_c('div',{staticClass:"form-group mb-5"},[(_vm.correct_register)?_c('div',{staticClass:"bg-auto text-center rounded text-base font-normal mb-2 color-black bg-green-400 text-lg lg:text-xl"},[_c('p',{staticClass:"mx-2 justify-center"},[_vm._v(" User "+_vm._s(this.username)+" created, click "),_c('router-link',{staticClass:"underline font-medium",attrs:{"to":"/users"}},[_vm._v("here")]),_vm._v(" to see all the users ")],1)]):(_vm.error_register)?_c('div',{staticClass:"bg-auto text-center rounded text-base font-normal mb-2 color-black bg-red-400 text-lg lg:text-xl"},[_c('p',{staticClass:"mx-2 justify-center"},[_vm._v(" Error creating the user, please try again ")])]):_vm._e(),_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"},[_vm._v("Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model.lazy",value:(_vm.username),expression:"username",modifiers:{"lazy":true}}],staticClass:"form-control block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
            'form-control': true,
            'is-invalid': _vm.username == '' && _vm.password_blured,
          },attrs:{"type":"text"},domProps:{"value":(_vm.username)},on:{"change":[function($event){_vm.username=$event.target.value},function($event){_vm.correct_register = false}]}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Name cannot be empty")])]),_c('div',{staticClass:"form-group my-5"},[_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl",attrs:{"for":"exampleInputPassword1"}},[_vm._v("Email:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
            'form-control': true,
            'is-invalid':
              ((_vm.email == '' || !_vm.validEmail(_vm.email)) && _vm.password_blured) ||
              _vm.error_mail,
          },attrs:{"type":"email"},domProps:{"value":(_vm.email)},on:{"change":function($event){_vm.correct_register = false},"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Not valid email. "+_vm._s(this.error_mail_msg[0]))])]),_c('div',{staticClass:"form-group my-5"},[_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"},[_vm._v("Password:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
            'form-control': true,
            'is-invalid': !_vm.validPassword(_vm.password) && _vm.password_blured,
          },attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"change":function($event){_vm.correct_register = false},"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" The password must have at least eight characters ")])]),_c('div',{staticClass:"form-group my-5"},[_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-xl"},[_vm._v("Confirm password:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirm_password),expression:"confirm_password"}],staticClass:"form-control block block w-full px-3 py-2 text-base font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
            'form-control': true,
            'is-invalid': _vm.password != _vm.confirm_password && _vm.password_blured,
          },attrs:{"type":"password"},domProps:{"value":(_vm.confirm_password)},on:{"change":function($event){_vm.correct_register = false},"input":function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("The passwords are not the same")])]),_c('div',{staticClass:"flex justify-center mt-12"},[_c('button',{staticClass:"w-4/6 lg:w-2/6 btn-primary font-regular py-2 text-lg lg:text-xl rounded-full",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-plus-circle pr-3"}),_vm._v(" Add User ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10 mb-4"},[_c('h1',{staticClass:"text-white text-xl lg:text-2xl font-bold"},[_c('i',{staticClass:"fas fa-users mr-2"}),_vm._v(" NEW USER ")])])}]

export { render, staticRenderFns }