var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-img flex justify-center py-4 items-center",staticStyle:{"min-height":"94vh"},attrs:{"id":"login"}},[_c('title',[_vm._v("Zenfiny | Login ")]),_c('div',{staticClass:"w-full lg:w-3/6 p-4 mt-5"},[_c('h1',{staticClass:"mb-12 text-center text-5xl lg:text-6xl"},[_vm._v(" LOG IN ")]),_c('form',{staticClass:"w-11/12 mx-auto"},[(_vm.invalid_login)?_c('div',{staticClass:"bg-red-100 border border-red-400 font-medium text-red-700 px-4 py-2 rounded relative",attrs:{"role":"alert"}},[_vm._v(" Incorrect email or password ")]):_vm._e(),_c('div',{staticClass:"form-group mb-6"},[_c('label',{staticClass:"form-label inline-block mb-2 px-3 text-lg lg:text-xl",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control\n                      block\n                      w-full\n                      px-3\n                      py-2 lg:py-4 \n                      font-normal\n                      bg-white\n                      border-2 border-solid border-white\n                      rounded-full\n                      transition\n                      ease-in-out\n                      m-0\n                      focus:outline-none",class:{
                          'form-control': true,
                          'is-invalid': !_vm.validEmail(_vm.email) && _vm.already_submitted
                      },attrs:{"type":"email","id":"exampleInputEmail1","placeholder":"Email User"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Enter a valid email")])]),_c('div',{staticClass:"form-group my-10 "},[_c('label',{staticClass:"form-label inline-block mb-2 px-3 text-lg lg:text-xl",attrs:{"for":"exampleInputPassword1"}},[_vm._v("Password")]),_c('div',{staticClass:"relative"},[(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password) && _vm.already_submitted
                          },attrs:{"type":"text","id":"exampleInputPassword1","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password) && _vm.already_submitted
                          },attrs:{"type":"password","id":"exampleInputPassword1","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(!_vm.errorPass)?_c('span',{staticClass:"button color-grey",on:{"click":_vm.toggleShow}},[_c('span',{staticClass:"icon is-small is-right absolute top-3 xl:top-5 right-4 cursor-pointer"},[_c('i',{staticClass:"fas ",class:{ 'fa-eye-slash': _vm.showPassword, 'fa-eye': !_vm.showPassword }})])]):_vm._e(),(_vm.errorPass)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Password is too short")]):_vm._e()])]),_c('div',[_c('p',{staticClass:"forgot-password text-right"},[_c('router-link',{attrs:{"to":"forgot-password"}},[_vm._v("Forgot password?")])],1)]),_c('div',{staticClass:"flex justify-center mt-10"},[_c('button',{staticClass:"w-2/4 lg:w-2/6 btn-primary py-2 lg:py-2 text-2xl lg:text-3xl rounded-full",attrs:{"type":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-sign-in-alt mr-2"}),_vm._v(" SIGN IN ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }