<template>
  <div class="bg-white pb-44 lg:pb-auto">
    <div
      v-if="error_message"
      class="bg-red-100 border font-medium border-red-400 text-red-700 px-4 py-2 rounded relative"
      role="alert"
    >
      An error has ocurred calculating the quote, check the input fields are
      correct.
    </div>
    <!-- circle steps -->
    <div class="w-11/12 mx-auto">
      <div class="progress-wrap mt-7 mx-auto">
        <div class="progress" id="progress" style="width: 50%"></div>
        <div class="step font-regular">1</div>
        <div class="step active font-regular">2</div>
        <div class="step font-regular">3</div>
      </div>
    </div>
    <!-- Step 2 -->
    <div id="secondStep" class="w-11/12 mx-auto bg-height">
      <h3 class="lg:w-3/12 w-10/12 mx-auto text-center text-xl font-regular">
        Please, now fill all the financial information
      </h3>
      <!--Quote Form-->
      <div class="lg:flex mt-5">
        <div class="form-group w-full lg:w-1/2 lg:mr-3 mb-3 lg:mb-auto">
          <!--Net Cost Input-->
          <label
            for="netCost"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Net Cost:<label class="text-red-600"> *</label>
          </label>
          <div class="relative">
            <input
              v-model="cost"
              @input="
                cost = correctNumber(cost);
                correctVat();
              "
              autocomplete="off"
              class="form-control block w-full px-10 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="netCost"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid':
                  (cost == null && already_submitted) || parseInt(cost) < 0,
              }"
            />
            <p class="invalid-feedback">Please enter a value greater than 0</p>
            <p
              class="text-sm lg:text-md font-medium absolute top-4 left-5 text-gray-500"
            >
              £
            </p>
          </div>
          <div class="invalid-feedback">This field is required</div>
        </div>
        <!--NOT VAT ITEMS-->
        <div class="form-group w-full lg:w-1/2 lg:mr-3 mb-3 lg:mb-auto">
          <!--Net Cost Input-->
          <label
            for="netCost"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Not vat items:
          </label>
          <div class="relative">
            <input
              v-model="not_vat_items"
              @input="not_vat_items = correctNumber(not_vat_items)"
              autocomplete="off"
              class="form-control block w-full px-10 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            />

            <p
              class="text-sm lg:text-md font-medium absolute top-4 left-5 text-gray-500"
            >
              £
            </p>
          </div>
          <div class="invalid-feedback">This field is required</div>
        </div>
        <!--Commission Amount Input-->
        <div class="form-group w-full lg:w-1/2 mb-3 lg:mb-auto">
          <div class="flex justify-between">
            <label
              for="commissionAmount"
              class="lg:pr-auto form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
            >
              Commission Amount:
            </label>
            <div class="form-check form-switch mr-3">
              <input
                v-model="net_commission"
                @change="changeSwitchCommission()"
                autocomplete="off"
                class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-red bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                type="checkbox"
                role="switch"
                id="switchCommission"
              />
              <label
                class="form-check-label inline-block text-gray-800 text-sm"
                for="switchCommission"
                >Manually input</label
              >
            </div>
          </div>
          <div class="relative">
            <input
              autocomplete="off"
              type="text"
              @input="commission = correctNumber(commission)"
              v-model="commission"
              list="commList"
              class="form-select appearance-none block w-full py-2text-md font-normal text-gray-800 bg-white bg-clip-padding bg-no-repeat bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              v-bind:class="{
                'px-10': net_commission,
                'px-3': !net_commission,
                'form-control': true,
                'is-invalid':
                  (!net_commission && (commission < 0 || commission > 100)) ||
                  (net_commission &&
                    parseInt(this.correctNumber(commission)) < 0),
              }"
              aria-label="Default select example"
              style="background: white"
            />
            <p class="invalid-feedback" v-if="!net_commission">
              Please enter a value greater than 0 a less than 100
            </p>
            <p class="invalid-feedback" v-if="net_commission">
              Please enter a value greater than 0
            </p>
            <p
              v-if="net_commission"
              class="text-sm lg:text-md font-medium absolute top-4 left-5 text-gray-500"
            >
              £
            </p>
            <p
              v-else
              class="text-sm lg:text-md absolute top-4 right-5 right-8 text-gray-500 font-medium"
            >
              %
            </p>
            <datalist
              v-if="!net_commission"
              class="w-full text-red-500"
              id="commList"
            >
              <option v-for="i in 10" :key="i">{{ i }}</option>
            </datalist>
          </div>
        </div>
      </div>

      <!--Vat Amount-->
      <div class="lg:flex mt-4">
        <div
          v-if="quote_type != 'lease'"
          class="form-group w-full lg:w-1/2 lg:mr-3 mb-3 lg:mb-auto"
        >
          <div class="flex justify-between">
            <label
              for="vatAmount"
              class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
            >
              Vat Amount:
            </label>

            <!-- prueba flex -->
            <!-- <div class="flex"> -->
            <div class="form-check form-switch mr-3">
              <input
                v-model="vat_deferal"
                @input="balloon = correctNumber(vat_deferal) ? null : balloon"
                autocomplete="off"
                class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-red bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
              />
              <label
                class="form-check-label inline-block text-gray-800 text-sm"
                for="flexSwitchCheckDefault"
                >Vat Deferal</label
              >
            </div>

            <!-- Prueba boton vat free no funcional solo esteticas-->
            <!-- <div class="form-check form-switch mr-3">
                <input
                  v-model="vat_free"
                  @change="switchVAToZero()"
                  autocomplete="off"
                  class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-red bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                  type="checkbox"
                  role="switch"
                  id="switchCommission2"
                />
                <label
                  class="form-check-label inline-block text-gray-800 text-sm"
                  for="switchCommission2"
                  >VAT free</label
                >
              </div>
            </div> -->

            <!-- End prueba -->
          </div>
          <div class="relative">
            <input
              v-model="vat"
              @input="vat = correctNumber(vat)"
              autocomplete="off"
              class="form-control block w-full px-10 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="numberCost"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid': parseInt(vat) < 0,
              }"
            />
            <p
              class="text-sm lg:text-md absolute top-4 left-5 text-gray-500 font-medium"
            >
              £
            </p>
          </div>
        </div>

        <!--Deposit-->
        <div class="form-group w-full lg:w-1/2 mb-3 lg:mb-auto">
          <div class="flex justify-between">
            <label
              for="deposit"
              class="lg:pr-auto form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
            >
              Deposit:
            </label>
            <div class="form-check form-switch mr-3">
              <input
                v-model="net_deposit"
                @change="changeSwitchDeposit()"
                autocomplete="off"
                class="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-red bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                type="checkbox"
                role="switch"
                id="switchCommission2"
              />
              <label
                class="form-check-label inline-block text-gray-800 text-sm"
                for="switchCommission2"
                >Manually input</label
              >
            </div>
          </div>
          <div class="relative">
            <input
              autocomplete="off"
              type="text"
              @input="deposit = correctNumber(deposit)"
              v-model="deposit"
              list="commList2"
              class="form-select appearance-none block w-full py-2text-md font-normal text-gray-800 bg-white bg-clip-padding bg-no-repeat bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              v-bind:class="{
                'px-10': net_deposit,
                'px-3': !net_deposit,
                'form-control': true,
                'is-invalid':
                  (!net_deposit && (deposit < 0 || parseInt(deposit) > 100)) ||
                  (net_deposit && parseInt(this.correctNumber(deposit)) < 0),
              }"
              aria-label="Default select example"
              style="background: white"
            />
            <p class="invalid-feedback" v-if="!net_deposit">
              Please enter a value greater than 0 and less than 100
            </p>
            <p class="invalid-feedback" v-if="net_deposit">
              Please enter a value greater than 0
            </p>
            <p
              v-if="net_deposit"
              class="text-sm lg:text-md absolute top-4 left-5 text-gray-500 font-medium"
            >
              £
            </p>
            <p
              v-else
              class="text-sm lg:text-md absolute top-4 right-5 right-8 text-gray-500 font-medium"
            >
              %
            </p>
            <datalist
              v-if="!net_deposit"
              class="w-full text-red-500"
              id="commList2"
            >
              <option v-for="i in 5" :key="i * 5">{{ i * 5 }}</option>
            </datalist>
          </div>
        </div>
      </div>

      <div class="lg:flex mt-4">
        <div
          v-if="!vat_deferal"
          class="form-group w-full lg:w-1/2 mb-3 lg:mr-3 lg:mb-auto"
        >
          <label
            for="paymentBalloon"
            id="balloonInput"
            class="pr-16 lg:pr-auto form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Balloon payment:
          </label>
          <div class="relative">
            <input
              v-model="balloon"
              @input="balloon = correctNumber(balloon)"
              autocomplete="off"
              class="form-control block w-full px-10 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="paymentBalloon"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid':
                  (!balloon || balloon == '0') && per && already_submitted,
              }"
            />
            <p class="invalid-feedback">
              Balloon can't be empty if baloon fall input is not
            </p>
            <p
              class="text-sm lg:text-md absolute top-4 left-5 text-gray-500 font-medium"
            >
              £
            </p>
          </div>
        </div>
        <div
          v-if="!vat_deferal"
          class="form-group w-full lg:w-1/2 mb-3 lg:mb-auto"
        >
          <label
            for="monthBalloon"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Month balloon falls due:
          </label>
          <div class="relative">
            <input
              v-model="per"
              type="number"
              autocomplete="off"
              class="form-control block w-full px-3 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="monthBalloon"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid': !per && balloon && already_submitted,
              }"
            />
            <p
              class="text-sm lg:text-md absolute top-4 right-4 text-gray-500 font-medium"
            >
              months
            </p>
            <div class="invalid-feedback">
              Balloon fall can't be empty if the balloon payment is not
            </div>
          </div>
        </div>
        <div v-else class="form-group w-full lg:w-1/2 mb-3 lg:mb-auto">
          <label
            for="monthBalloon"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Month vat deferal falls due:
          </label>
          <div class="relative">
            <input
              v-model="per"
              type="number"
              autocomplete="off"
              class="form-control block w-full px-3 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="monthBalloon"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid': !per && already_submitted,
              }"
            />
            <p
              class="text-sm lg:text-md absolute top-4 right-4 text-gray-500 font-medium"
            >
              months
            </p>
            <div class="invalid-feedback">This field is required</div>
          </div>
        </div>
      </div>
      <div class="lg:flex mt-4">
        <div class="form-group w-full lg:w-1/2 lg:mr-3 mb-3 lg:mb-auto">
          <label
            for="term"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Term:<label class="text-red-600"> *</label>
          </label>
          <div class="relative">
            <input
              v-model="term"
              type="number"
              class="form-control block w-full px-3 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="term"
              autocomplete="off"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid': term == null && already_submitted,
              }"
            />
            <p
              class="text-sm lg:text-md font-medium absolute top-4 right-4 text-gray-500"
            >
              months
            </p>
          </div>
          <div class="invalid-feedback">This field is required</div>
        </div>

        <div class="form-group w-full lg:w-1/2 mb-3 lg:mb-auto">
          <label
            for="interestLender"
            class="lg:pr-auto form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Interest to lender:<label class="text-red-600"> *</label>
          </label>
          <div class="relative">
            <input
              v-model="interest"
              required
              type="number"
              class="form-control block w-full px-3 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="interestLender"
              autocomplete="off"
              placeholder=""
              v-bind:class="{
                'form-control': true,
                'is-invalid': interest == null && already_submitted,
              }"
            />
            <p
              class="text-sm lg:text-md absolute top-4 right-4 right-8 text-gray-500 font-medium"
            >
              %
            </p>
          </div>
          <div class="invalid-feedback">This field is required</div>
        </div>
      </div>
      <div class="lg:flex mt-4">
        <div class="form-group w-full lg:w-1/2 lg:mr-3 mb-3 lg:mb-auto">
          <label
            for="advancedPayment"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-sm lg:text-xl"
          >
            Advanced payment:
          </label>
          <div class="relative">
            <input
              v-model="advances"
              type="number"
              class="form-control block w-full px-3 py-2 text-md font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
              id="advancedPayment"
              autocomplete="off"
              placeholder=""
            />
            <p
              class="text-sm lg:text-md absolute top-4 right-4 text-gray-500 font-medium"
            >
              months
            </p>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed bottom-0 mt-10 w-full bg-secondary">
      <div class="w-11/12 mx-auto flex justify-between items-center">
        <button
          @click="back"
          class="mr-2 btn-back rounded-full font-regular py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
          id="next"
        >
          <i class="fas fa-chevron-circle-left pr-3"></i>
          Back
        </button>
        <button
          @click="submit"
          class="btn-secondary text-xl rounded-full py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent"
          id="next"
        >
          Next
          <i class="fas fa-chevron-circle-right pl-3 color-text"></i>
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import LeadService from "../../../services/af-quote/LeadService";
import QuoteService from "../../../services/af-quote/QuoteService";

export default {
  name: "quote-step",

  data() {
    return {
      lead: null,
      cost: null,
      not_vat_items: null,
      not_vat_items_str: "",
      vat: null,
      deposit: null,
      interest: null,
      commission: null,
      commission_full: null,
      advances: null,
      term: null,
      balloon: null,
      per: null,
      vat_deferal: false,
      quote_type: null,

      already_submitted: false,

      net_commission: false,
      net_deposit: false,

      error_message: false,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getLead(this.$route.params.id);
    }
  },
  methods: {
    async getLead(id) {
      this.deposit = 0;
      this.net_deposit = !this.net_deposit;

      let lead_service = new LeadService();
      const lead_response = await lead_service.getLead(id);
      this.lead = lead_response.lead;
      //console.log("LEAD: ", this.lead);
      this.term = this.lead.term;
      this.cost = this.lead.amount;
      this.deposit = this.lead.deposit;
    },
    setQuote(
      cost,
      commission,
      vat,
      deposit,
      term,
      balloon,
      balloon_fall,
      interest,
      advanced_pay,
      vat_deferal,
      quote_type,
      net_commission,
      net_deposit,
      not_vat_items
    ) {
      // console.log(
      //   '*************************',
      //   '\n cost: ' + cost,
      //   '*************************',
      //   '\n commission: ' + commission,
      //   '*************************',
      //   '\n vat: ' + vat,
      //   '*************************',
      //   '\n deposit: ' + deposit,
      //   '*************************',
      //   '\n term: ' + term,
      //   '*************************',
      //   '\n ballon: ' + balloon,
      //   '*************************',
      //   '\n ballon_fall: ' + balloon_fall,
      //   '*************************',
      //   '\n advanced_pay: ' + advanced_pay,
      //   '*************************',
      //   '\n vat_deferal: ' + vat_deferal,
      //   '*************************',
      //   '\n net_commission: ' + net_commission,
      //   '*************************',
        // '\n net_deposit: ' + net_deposit,
      //   '*************************',
      //   '\n interest: ' + interest,
      // )

      this.not_vat_items = not_vat_items == null || not_vat_items == '' ? 0 : parseFloat(not_vat_items);

      /*--Set cost of the quote--*/
      this.cost = this.correctNumber(cost);

      /*--Set vat of the quote --*/
      isNaN(vat) || vat == null
        ? (this.vat = 0)
        : (this.vat = this.correctNumber(vat));

      /*--Set vat deferral--*/
      this.vat_deferal = vat_deferal;

      /*--Set the commission for this quote--*/
      isNaN(commission) || commission == null
        ? (this.commission = 0)
        : (this.commission = this.correctNumber(commission));
      this.commission_full = commission;
      this.net_commission = net_commission;
      // this.net_commission ? this.commission : this.commission = (parseFloat(commission) / (parseFloat(cost) + parseFloat(vat)- parseFloat(deposit)) * 100);
      this.net_commission ? this.commission : this.commission = (parseFloat(commission) / (parseFloat(cost) +parseFloat(not_vat_items || 0) - parseFloat(deposit)) * 100).toFixed(2);

      /*--Set deposite for the deposit --*/
      isNaN(deposit) || deposit == null
        ? (this.deposit = 0)
        : (this.deposit = this.correctNumber(deposit));
      this.net_deposit = net_deposit;
      if (!this.net_deposit) {
        if (vat_deferal) {
          this.deposit = this.correctNumber((parseFloat(deposit) / (parseFloat(cost) + parseFloat(this.not_vat_items || 0))) * 100);
        } else {
          this.deposit = this.correctNumber((parseFloat(deposit) / (parseFloat(cost) + parseFloat(this.not_vat_items || 0))) * 100);
        }
      }

      /*--Set other terms--*/
      this.term = term;
      this.balloon = balloon;
      this.per = balloon_fall;
      this.interest = interest;
      this.advances = advanced_pay;

      console.log(
        "*************************",
        "\n cost: " + cost,
        "*************************",
        "\n commission: " + commission,
        "*************************",
        "\n vat: " + vat,
        "*************************",
        "\n deposit: " + deposit,
        "*************************",
        "\n term: " + term,
        "*************************",
        "\n ballon: " + balloon,
        "*************************",
        "\n ballon_fall: " + balloon_fall,
        "*************************",
        "\n advanced_pay: " + advanced_pay,
        "*************************",
        "\n vat_deferal: " + vat_deferal,
        "*************************",
        "\n net_commission: " + net_commission,
        "*************************",
        "\n net_deposit: " + net_deposit,
        "*************************",
        "\n interest: " + interest
      );
    },

    setType(quote_type) {
      this.quote_type = quote_type;
    },

    back() {
      this.$emit("backwards");
    },
    async submit() {
      console.log("entra en el submit", {
        commision: this.commission,
        net_comission: this.commission_full,
        cost: this.cost,
        deposit: this.deposit,
        net_deposit: this.net_deposit,
        deposit_net_no_vat: this.deposit_net_without_vat,
        terms: this.term,
        vat: this.vat,
        vat_deferal: this.vat_deferal,
        net_commision: this.net_commission,
      });

      this.error_message = false;
      this.already_submitted = true;
      try {
        if (!this.validForm()) throw "error input fields";
        this.formatNumbers();
        let deposit_net;
        let deposit_net_without_vat;

        if (isNaN(this.cost) || this.cost == null) this.cost = 0;

        if (isNaN(this.vat) || this.vat == null || this.vat == 0) this.vat = 0;
        else this.vat = process.env.VUE_APP_UK_VAT * this.cost;

        // console.log("this net_deposit",this.net_deposit);
        // if (isNaN(this.cost) || this.cost == null) this.cost = 0;

        if (this.vat_deferal) {
          //console.log('entra en el vat deferal')
          deposit_net_without_vat = this.net_deposit
            ? this.deposit
            : (parseFloat(this.cost) + parseFloat(this.not_vat_items || 0)) * (parseFloat(this.deposit) / 100);
          deposit_net = 0;
        } else {
          if (this.net_deposit) {
            //manual
            //console.log('entra en el net deposit')
            deposit_net_without_vat = this.deposit;
            deposit_net = process.env.VUE_APP_UK_VAT * deposit_net_without_vat;
          } else {
            //porcentaje a manual
            //console.log('entra en el manual')
            deposit_net_without_vat =
              (parseFloat(this.cost) + parseFloat(this.not_vat_items || 0)) * (parseFloat(this.deposit) / 100);
            deposit_net = process.env.VUE_APP_UK_VAT * deposit_net_without_vat;
          }
        }

        var coste_ = parseFloat(this.cost);
        var vat_ = parseFloat(this.vat);
        var deposit_ = 0;

        //Deposit calcultations - Depends on multiple factors --quote_type, vat_deferal--
        if (this.quote_type != "lease" && this.vat_deferal) {
          deposit_ = deposit_net_without_vat;
        } else if (
          (this.quote_type != "lease" &&
            !this.net_deposit &&
            !this.vat_deferal) ||
          (this.quote_type != "lease" && !this.vat_deferal)
        ) {
          // deposit_ = deposit_net_without_vat + vat_;
          // console.log("deposito no lease no net deposit no vat deferral")
          if (deposit_net_without_vat > 0 || deposit_net_without_vat < 0) {
            // console.log("deposito mayor menor")
            deposit_ = deposit_net_without_vat;
          }

          if (this.vat == 0) {
            // console.log("vat igual a 0")
            deposit_ = deposit_net_without_vat;
          }
        } else {
          deposit_ = parseFloat(deposit_net_without_vat);
        }
        //console.log("deposit_ = " + deposit_);

        let comm_net = 0;
        // console.log(this.net_commission, 'net commision antes del comm_net')
        if (this.net_commission) {
          comm_net = parseFloat(this.commission);
          // console.log('entra en el primer if')
        } else {
          // console.log('entra en el else')
          if (isNaN(deposit_)) {
            deposit_ = 0;
          }

          //(this.not_vat_items || 0, 'not vat')
          //console.log(this.cost, 'cost') 
          //console.log(this.deposit, 'deposit')
          // comm_net = ((parseFloat(this.cost) - parseFloat(deposit_) + parseFloat(vat_)) / 100) * parseFloat(this.commission);
          comm_net = (((parseFloat(this.cost) + parseFloat(this.not_vat_items || 0)) - parseFloat(deposit_)) / 100) * parseFloat(this.commission);

          // comm_net = ((parseFloat(this.cost) - parseFloat(deposit_)) / 100) * parseFloat(this.commission);
          //console.log(comm_net, 'comm net')
        }

        // console.log(comm_net)

        var commission_ = parseFloat(this.commission);
        // console.log("--------------- coste : " + coste_);
        // console.log("--------------- vat_ : " + vat_);
        // console.log("--------------- desposit_ : " + deposit_);
        // console.log("--------------- commission_ : " + commission_);

        let form = {
          cost: this.cost,
          vat: this.vat,
          deposit: !this.net_deposit ? this.deposit / 100 * this.cost : this.deposit,
          vat_deposit: deposit_net,
          interest: this.interest,
          commission: comm_net,
          advances: this.advances,
          term: this.term,
          balloon: this.balloon,
          per: this.per,
          vat_deferal: this.vat_deferal,
          net_commission: this.net_commission,
          net_deposit: this.net_deposit,
          not_vat_items: this.not_vat_items,
        };
        // console.log(form);
        let quote_service = new QuoteService();
        let data = await quote_service.calculateQuote(form);
        // console.log(data, 'calculos realizados para obtener la cuota')

        this.$emit("quote_data", [form, data]);
        this.$emit("next");
      } catch (error) {
        this.error_message = true;
        console.error(error);
      }
    },

    formatNumbers() {
      this.cost =
        this.cost != null && typeof this.cost === "string"
          ? parseFloat(this.cost.split(",").join(""))
          : parseFloat(this.cost);
      this.vat =
        this.vat != null && typeof this.vat === "string"
          ? parseFloat(this.vat.split(",").join(""))
          : parseFloat(this.vat);
      this.deposit =
        this.deposit != null && typeof this.deposit === "string"
          ? parseFloat(this.deposit.split(",").join(""))
          : parseFloat(this.deposit);
      this.balloon =
        this.balloon != null && typeof this.balloon === "string"
          ? this.balloon.split(",").join("")
          : this.balloon;

      this.commission =
        this.commission != null && typeof this.commission === "string"
          ? this.commission.split(",").join("")
          : this.commission;
      // console.log(this.commission);
    },

    validForm() {
      // console.log('entra a validar el form')
      return (
        this.cost != null &&
        this.term != null &&
        this.interest != null &&
        this.validateCommission() &&
        this.validateDeposit() &&
        this.validVatBalloon()
      );
    },

    validVatBalloon() {
      // console.log('entra a validar el ballon')
      if (this.vat_deferal) {
        return this.validVatDeferal();
      } else {
        return this.validStr(this.balloon) == this.validStr(this.per);
      }
    },

    validVatDeferal() {
      return this.validStr(this.vat) && this.validStr(this.per);
    },

    validStr(str) {
      return str != null && str != "";
    },

    validateCommision() {
      return this.correctNumber(this.commission);
    },

    correctNumber(e) {
      //console.log(e);
      e = e.toString().replace(/[^\d.-]/g, "");

      let to =
        e.indexOf(".") == -1
          ? e.length
          : Math.min(e.indexOf(".") + 3, e.length);
      to = e.indexOf(".") == e.length - 1 ? to - 1 : to;

      let [entero, decimal] = e.split(".");

      if (decimal && decimal.length > 2) decimal = decimal.substring(0, 2);
      entero = entero.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return decimal != null ? entero + "." + decimal : entero;
    },

    correctVat() {
      if (this.quote_type != "lease") {
        var test = this.cost.replace(/[^\d.-]/g, "");
        // console.log(test);
        test = parseFloat(test);
        // console.log(test);
        test = process.env.VUE_APP_UK_VAT * test;
        // console.log(test);
        this.vat = this.correctNumber(
          (
            process.env.VUE_APP_UK_VAT *
            parseFloat(this.cost.replace(/[^\d.-]/g, ""))
          ).toString()
        );
      }
    },
    changeSwitchCommission() {
      this.commission = 0;
    },
    changeSwitchDeposit() {
      this.deposit = 0;
    },

    // function prueba
    // switchVAToZero(){
    //   if(this.vat_free){
    //     this.aux_vat = this.vat;
    //     this.vat = 0;
    //   } else {
    //     this.vat = this.aux_vat;
    //   }
    // },
    // end function prueba

    validateCommission() {
      // console.log('entra a validar la comision', this.net_commission, this.commission)
      if (!this.net_commission) {
        // console.log('entra en el primer if')
        return (this.commission >= 0 && this.commission <= 100)
      } else {
        // console.log('entra en el else')
        return this.commission == null || parseInt(this.commission) > 0
      }
    },
    validateDeposit() {
      if (this.deposit == null) { this.deposit = 0 }
      // console.log('entra al deposito', this.net_deposit, this.deposit)
      if (!this.net_deposit) {
        return (parseInt(this.deposit) >= 0 && parseInt(this.deposit) <= 100)
      } else {
        // console.log('entra en el else')
        return parseInt(this.deposit) >= 0
      }
      //console.log("entra al deposito", this.net_deposit, this.deposit);
      if (!this.net_deposit) {
        return parseInt(this.deposit) >= 0 && parseInt(this.deposit) <= 100;
      } else {
        //console.log("entra en el else");
        return parseInt(this.deposit) >= 0;
      }
    },
  },
};
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

form input:required {
  content: "*";
}

.progress-wrap::before {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 0;
  transition: 0.3s ease-in-out;
  background-color: #e6e6e6;
}
</style>