<template>
  <div id="steps">
    <div class="tab-details">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-wizard",
  data() {
    return {
      tabs: [],
      currentActive: 0,
      totalTabs: 0,
    };
  },

  created() {
    this.tabs = this.$children;
  },

  mounted() {
    this.totalTabs = this.tabs.length;
  },

  methods: {
    next() {
      this.tabs.forEach((tab) => {
        tab.isActive = false;
      });
      this.tabs[++this.currentActive].isActive = true;
    },
    previous() {
      this.tabs.forEach((tab) => {
        tab.isActive = false;
      });
      this.tabs[--this.currentActive].isActive = true;
    },
  },
};
</script>

