<template>
  <div id="navTop">
    <nav
      class="navbar navbar-expand-lg shadow-lg relative flex items-center w-full justify-between xl:px-6 lg:px-4"
    >
      <div style="height: 6vh" class="flex items-center">
        <router-link to="/" class="flex">
          <div style="height: 5vh">
            <img class="img-fluid" src="/img/logo-white.png" alt="" />
          </div>
        </router-link>
      </div>
      <div class="">
        <!-- nav responsive -->
        <button
          class="lg:hidden inline-block px-6 py-2.5 color-primary font-medium text-xs leading-tight rounded transition duration-150 ease-in-out"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            class="w-5"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </button>
        <div
          class="lg:hidden offcanvas offcanvas-end fixed bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 right-0 border-none w-72"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header flex items-center justify-start pt-6 p-4">
            <button
              class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body flex-grow px-4 overflow-y-auto">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item my-4 flex justify-end">
                <a
                  class="mb-1 nav-link block pr-2 lg:px-2 py-2 transition duration-150 ease-in-out flex items-center"
                  href="/"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  ><i class="fas fa-home mr-1 text-xl"></i>HOME</a
                >
              </li>
              <li v-if="!user" class="nav-item my-4 flex justify-end">
                <a
                  class="nav-link block pr-2 lg:px-2 py-2 transition duration-150 ease-in-out flex items-center"
                  href="/login"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  ><i class="fas fa-sign-in-alt mr-1 text-xl"></i>LOG IN</a
                >
              </li>
              <!-- USERS -->
              <li v-if="user" class="nav-item dropdown static my-4">
                <div class="flex justify-end">
                  <button
                    class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseUsers"
                    aria-expanded="false"
                    aria-controls="collapseUsers"
                  >
                    <i class="fas fa-user mr-2 text-xl"></i>USERS
                   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>

                  </button>
                </div>
                <div class="collapse" id="collapseUsers">
                  <ul
                    class="block p-2 rounded-lg shadow-lg bg-color text-right"
                  >
                    <li class="lg:my-auto py-2 font-medium text-lg border-b">
                      <a href="/register">ADD USER</a>
                    </li>
                    <li class="lg:my-auto my-2 font-medium text-lg">
                      <a href="/users">LIST USERS</a>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- QUOTES -->
              <li v-if="user" class="nav-item dropdown static my-4">
                <div class="flex justify-end">
                  <button
                    class="nav-link pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseQuotes"
                    aria-expanded="false"
                    aria-controls="collapseQuotes"
                  >
                    <i class="fas fa-calculator mr-2 text-xl"></i>QUOTES
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </button>
                </div>
                <div class="collapse" id="collapseQuotes">
                  <ul
                    class="block p-2 rounded-lg shadow-lg bg-color text-right"
                  >
                    <li class="lg:my-auto py-2 font-medium text-lg border-b">
                      <a href="/registerquote">ADD QUOTES</a>
                    </li>
                    <li class="lg:my-auto my-2 font-medium text-lg">
                      <a href="/quotes">LIST QUOTES</a>
                    </li>
                  </ul>
                </div>
              </li>
    
              <!-- PROPORSAL -->
              <li v-if="user" class="nav-item my-4 flex justify-end px-2">
                <a
                  class="nav-link px-2 py-2 transition duration-150 ease-in-out flex items-center"
                  href="/registerproposal"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  ><i class="fas fa-handshake mr-1 text-xl"></i>PROPOSAL</a
                >
              </li>
              <!-- FORMS -->
              <li v-if="isAdmin" class="nav-item dropdown static my-4">
                <div class="flex justify-end">
                  <button
                    class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseForms"
                    aria-expanded="false"
                    aria-controls="collapseForms"
                  >
                    <i class="fas fa-list text-xl mr-2"></i>FORMS
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </button>
                </div>
                <div class="collapse" id="collapseForms">
                  <ul
                    class="block p-2 rounded-lg shadow-lg bg-color text-right"
                  >
                    <li class="lg:my-auto py-2 font-medium text-lg border-b">
                      <a href="/create-form">ADD FORM</a>
                    </li>
                    <li class="lg:my-auto my-2 font-medium text-lg">
                      <a href="/forms">LIST FORMS</a>
                    </li>
                  </ul>
                </div>
              </li>
              <!-- LEADS -->
              <li v-if="isAdmin" class="nav-item dropdown static my-4">
                <div class="flex justify-end">
                  <a
                    class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out flex items-center whitespace-nowrap"
                    href="/leads"
                  >
                    <i class="fas fa-users text-xl mr-2"></i>LEADS
                    
                  </a>
                </div>
              </li>
              <!-- BRANDS -->
              <li v-if="isAdmin" class="nav-item dropdown static my-4">
                <div class="flex justify-end">
                  <button
                    class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseBrands"
                    aria-expanded="false"
                    aria-controls="collapseBrands"
                  >
                    <i class="fas fa-tags text-xl mr-2"></i>BRANDS
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                    </svg>
                  </button>
                </div>
                <div class="collapse" id="collapseBrands">
                  <ul
                    class="block p-2 rounded-lg shadow-lg bg-color text-right"
                  >
                    <li class="lg:my-auto py-2 font-medium text-lg border-b">
                      <a href="/registerbrand">ADD BRANDS</a>
                    </li>
                    <li class="lg:my-auto my-2 font-medium text-lg">
                      <a href="/brands">LIST BRANDS</a>
                    </li>
                  </ul>
                </div>
              </li>
              
              <!-- PROFILE -->
              <li v-if="user" class="nav-item dropdown static py-4 border-t ">
                <div class="flex justify-end">
                  <button
                    class="nav-link text-lg pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseProfile"
                    aria-expanded="false"
                    aria-controls="collapseProfile"
                  >
                  <i class="fas fa-user-circle mr-2 text-xl"></i>{{ user.name }}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>

                  </button>
                </div>
                <div class="collapse" id="collapseProfile">
                  <ul
                    class="block p-2 rounded-lg shadow-lg bg-color text-right"
                  >
                    <li
                      v-if="user"
                      @click="logout()"
                      class="lg:my-auto my-2 font-medium text-lg"
                    >
                      <a>LOG OUT</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- nav desktop -->
        <div
          class="navbar-collapse collapse grow items-center lg:px-3 md:px-2"
          id="navbarSupportedContentX"
        >
          <ul class="navbar-nav mr-auto flex flex-row">
            <li class="nav-item flex items-center lg:px-3 md:px-2">
              <router-link :to="{ name: 'home' }" class="nav-link flex items-center">
                <i class="fas fa-home mr-2 text-xl"></i>
                HOME
              </router-link>
            </li>
            <li v-if="!user" class="nav-item">
              <a
                class="nav-link pr-2 lg:px-2 py-2 flex items-center text-md cursor-pointer"
                href="/login"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                ><i class="fas fa-sign-in-alt mr-1 text-xl"></i>LOG IN</a
              >
            </li>

            <li
              v-if="isAdmin"
              class="nav-item dropdown static flex items-center lg:px-3 md:px-2"
            >
              <router-link
                :to="{ name: 'users' }"
                class="nav-link flex items-center"
                id="collapseUser"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-users text-xl mr-2"></i>
                USERS
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>

              </router-link>
              <div
                class="border-top dropdown-menu mt-0 hidden shadow-lg bg-white absolute top-full"
                style="right:46rem;"
                aria-labelledby="collapseUser"
              >
                <ul class="px-6 lg:px-8 py-2">
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/register">ADD USER</a>
                  </li>
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/users">LIST USERS</a>
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="user" class="nav-item dropdown static flex items-center lg:px-3 md:px-2">
              <router-link
                :to="{ name: 'quotes' }"
                class="nav-link flex items-center"
                id="collapseQuotes"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-calculator mr-2 text-xl"></i>QUOTES
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </router-link>
              <div
                class="border-top w-full md:w-auto dropdown-menu mt-0 hidden shadow-lg bg-white absolute right-26 top-full"
                aria-labelledby="collapseQuotes"
              >
                <ul class="px-6 lg:px-8 py-2">
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/registerquote">ADD QUOTE</a>
                  </li>

                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/quotes">LIST QUOTES</a>
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="user" class="nav-item flex items-center lg:px-3 md:px-2">
              <router-link :to="{ name: 'registerproposal' }" class="nav-link flex items-center">
                <i class="fas fa-handshake mr-2 text-xl"></i>PROPOSAL
              </router-link>
            </li>
            <!--FORMS-->

            <li
              v-if="isAdmin"
              class="nav-item dropdown static flex items-center lg:px-3 md:px-2"
            >
              <router-link
                :to="{ name: 'forms' }"
                class="nav-link flex items-center"
                id="collapseUser"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-list mr-2 text-xl"></i>
                FORMS
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </router-link>
              <div
                class="border-top dropdown-menu mt-0 hidden shadow-lg bg-white absolute top-full"
                aria-labelledby="collapseUser"
                style="right:20rem;"
              >
                <ul class="px-6 lg:px-8 py-2">
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/create-form">ADD FORM</a>
                  </li>
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/forms">LIST FORMS</a>
                  </li>
                </ul>
              </div>
            </li>
            <!--END FORMS-->
            <!--LEADS-->
            <li   v-if="isAdmin"
              class="nav-item static flex items-center px-2">
                <a
                  class="nav-link pr-2 lg:px-2 py-2 transition duration-150 ease-in-out flex items-center"
                  href="/leads"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                  ><i class="fas fa-users text-xl mr-2"></i>LEADS</a
                >
            </li>
           
            <!--END LEADS-->
            <li
              v-if="isAdmin"
              class="nav-item dropdown static flex items-center lg:px-3 md:px-2"
            >
              <router-link
                :to="{ name: 'brands' }"
                class="nav-link flex items-center"
                id="collapseBrands"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-tags text-lg mr-2"></i>BRANDS
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </router-link>
              <div
                class="border-top w-full md:w-auto dropdown-menu mt-0 hidden shadow-lg bg-white absolute right-26 top-full"
                aria-labelledby="collapseBrands"
              >
                <ul class="px-6 lg:px-8 py-2">
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/registerbrand">ADD BRAND</a>
                  </li>
                  <li class="lg:my-auto py-2 font-medium text-md">
                    <a href="/brands">LIST BRANDS</a>
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="user" class="nav-item dropdown static flex items-center">
              <router-link
                :to="{ name: 'login' }"
                class="nav-link flex items-center"
                id="collapseProfile"
                data-bs-toggle="dropdown"
              >
                <i class="fas fa-user-circle text-lg mr-2"></i>{{ this.user.name }}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 ml-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>
              </router-link>
              <div
                class="border-top w-full md:w-auto dropdown-menu mt-0 hidden shadow-lg bg-white absolute right-5 top-full text-md"
                aria-labelledby="collapseProfile"
              >
                <ul class="px-6 lg:px-8 py-2">
                  <li
                    @click="logout()"
                    class="lg:my-auto my-2 font-medium cursor-pointer"
                  >
                    <a>LOG OUT</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";


Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});
export default {
  computed: {
    ...mapGetters({
      user: "auth/authUser",
      isAdmin: "auth/isAdmin",
    }),
  },

  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
  },
};
</script>
<style scoped>
/* TODO review by JOSE */
.img-fluid {
  width: auto;
  height: 100%;
}
</style>
