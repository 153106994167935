<template>
<div>
    <title>Zenfiny | List Forms</title>
    <FormList/>
</div>
</template>
<script>
import FormList from '../../components/FormList.vue';

export default {
    
    components: {
        FormList
    },

    data(){
        return{

        };
    },

    async created(){

    },

    methods: {

    }
    
}
</script>