<template>
  <div class="bg-white w-full h-full">
    <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-xl lg:text-2xl font-bold">
        <i class="fas fa-file"></i>
        NEW FORM
      </h1>
    </div>
    <div class="w-11/12 lg:w-10/12 mx-auto">
      <!-- información general -->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">1. General information</h2>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
          <!-- domain -->
          <div class="form-group">
            <p class="mb-1">Domain *</p>
            <input
              placeholder="https://www.example.com"
              v-model="domain"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': domain_invalid == true && form_sended == true,
              }"
            />
            <div v-if="domain_invalid == true && form_sended == true">
              <p style="color: red">{{ domain_error }}</p>
            </div>
          </div>
          <!-- code -->
          <div class="form-group">
            <p class="mb-1">Code *</p>
            <input
              v-model="code"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': code_invalid == true && form_sended == true,
              }"
            />
            <div v-if="code_invalid == true && form_sended == true">
              <p style="color: red">{{ code_error }}</p>
            </div>
          </div>
          <!-- email -->
          <div class="form-group">
            <p class="mb-1">Email *</p>
            <input
              v-model="email"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': email_invalid == true && form_sended == true,
              }"
            />
            <div v-if="email_invalid == true && form_sended == true">
              <p style="color: red">{{ email_error }}</p>
            </div>
          </div>
          <!-- telephone -->
          <div class="form-group">
            <p class="mb-1">Telephone *</p>
            <input
              v-model="telephone"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': telephone_invalid == true && form_sended == true,
              }"
            />
            <div v-if="telephone_invalid == true && form_sended == true">
              <p style="color: red">{{ telephone_error }}</p>
            </div>
          </div>
          <!--Terms and conditions link-->
          <div class="form-group">
            <p class="mb-1">Terms and conditions link</p>
            <input
              placeholder="https://www.example.com"
              v-model="terms_link"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': terms_link_invalid == true && form_sended == true,
              }"
            />
            <div v-if="terms_link_invalid == true && form_sended == true">
              <p style="color: red">{{ terms_link_error }}</p>
            </div>
          </div>
          <div class="form-group py-8">
            <div class="flex flex-row items-center mb-4 lg:mb-0">
              <label class="switch">
                <input type="checkbox" id="myCheck" v-model="show_checkbox" />
                <span class="slider round"></span>
              </label>
              <p class="pl-4 text-md lg:text-lg">show option to receive information</p>
            </div>
            <div v-if="show_checkbox">
              <p class="mb-1">Receive information text</p>
              <input
                v-model="information_text"
                class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    show_checkbox == true &&
                    form_sended == true &&
                    information_text == '',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">2. Style form</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
          <!-- bg color box -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Background color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="background_color"
              name="background_color"
              value="#ffffff"
            />
          </div>
          <!-- bg color input -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Background input</label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="background_input"
              name="background_input"
              value="#eeeeee"
            />
          </div>

          <!-- text-color -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Text color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="text_color"
              name="text_color"
              value="#434343"
            />
          </div>
          <!-- choose a font -->
          <div class="form-group">
            <label for="fonts" class="mb-1">Choose a font</label>
            <select
              name="fonts"
              id="fonts"
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
            >
              <option value="1" style="font-family: Inter">Inter</option>
              <option value="2" style="font-family: Merriweather">
                Merriweather
              </option>
              <option value="3" style="font-family: Montserrat">
                Montserrat
              </option>
              <option value="4" style="font-family: Poppins">Poppins</option>
              <option value="5" style="font-family: Lexend">Lexend</option>
              <option value="6" style="font-family: Lato">Lato</option>
            </select>
          </div>
          <!-- primary color -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Primary color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="primary_color"
              name="primary_color"
              value="#025177"
            />
          </div>
          <!-- primary color -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Secondary color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="secondary_color"
              name="secondary_color"
              value="#55FFC9"
            />
          </div>
          <!-- text button culor -->
          <div class="form-group">
            <label for="favcolor" class="mb-1">Text button color</label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="text_btn_color"
              name="text_btn_color"
              value="#ffffff"
            />
          </div>
        </div>
        <!-- border radius -->
        <div class="mt-4">
          <p>Choose a border radius size for the buttons</p>
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex items-center"
          >
            <div class="form-group">
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-xl focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="value"
              />
              px
            </div>
          </div>
        </div>
      </div>

      <!--CLAIM TEXT-->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">3. Apply step 1: introduction</h2>
        <div class="form-group mt-4">
          <p class="mb-2">
            Enter claim text for step 1 of the application form
          </p>
          <textarea
            v-model="claim"
            class="form-control w-full font-normal text-gray-600 bg-transparent border-input rounded-3xl focus:text-gray-700 focus:border-gray-400 h-32 p-3 italic"
          />
        </div>
      </div>

      <!--CONGRATULATIONS TEXT-->

      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">
          4. Apply and calculator congratulations text
        </h2>
        <div class="form-group mt-4">
          <p class="mb-2">
            Enter congratulations text for the application form and calculator
          </p>
          <textarea
            v-model="congratulations_text"
            class="form-control w-full font-normal text-gray-600 bg-transparent border-input rounded-3xl focus:text-gray-700 focus:border-gray-400 h-32 p-3 italic"
          />
        </div>
      </div>

      <!--RATIO PERCENTAGE-->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">5. Calculator credit rate</h2>
        <p>Select what configuration you want to give to the credits.</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8 mt-4">
          
          <div class="flex flex-col lg:flex-row items-start lg:items-center col-span-1 lg:col-span-2">
            <div class="w-full lg:w-3/4">
              <p>Percentage excellent</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="excellent_value"
              />
            </div>
            <div class="w-full lg:w-1/4 flex items-center mt-2 lg:mt-0 lg:ml-4">
              <input
                type="number"
                class="form-control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-xl focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="excellent_value"
              />
              <span class="ml-2">%</span>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row items-start lg:items-center col-span-1 lg:col-span-2">
            <div class="w-full lg:w-3/4">
              <p>Percentage good</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="good_value"
              />
            </div>
            <div class="w-full lg:w-1/4 flex items-center mt-2 lg:mt-0 lg:ml-4">
              <input
                type="number"
                class="form-control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-xl focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="good_value"
              />
              <span class="ml-2">%</span>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row items-start lg:items-center col-span-1 lg:col-span-2">
            <div class="w-full lg:w-3/4">
              <p>Percentage fair</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="fair_value"
              />
            </div>
            <div class="w-full lg:w-1/4 flex items-center mt-2 lg:mt-0 lg:ml-4">
              <input
                type="number"
                class="form-control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-xl focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="fair_value"
              />
              <span class="ml-2">%</span>
            </div>
          </div>

          <div class="flex flex-col lg:flex-row items-start lg:items-center col-span-1 lg:col-span-2">
            <div class="w-full lg:w-3/4">
              <p>Percentage poor</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="poor_value"
              />
            </div>
            <div class="w-full lg:w-1/4 flex items-center mt-2 lg:mt-0 lg:ml-4">
              <input
                type="number"
                class="form-control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-xl focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="poor_value"
              />
              <span class="ml-2">%</span>
            </div>
          </div>

        </div>
      </div>
      <div
        v-show="elementVisible"
        class="bg-auto text-center rounded-xl font-normal mb-2 color-black bg-red-400 text-lg lg:text-xl p-2 w-full"
      >
        <p class="font-bold">
          The form could not be created, please ensure that all required fields
          are filled out.
        </p>
      </div>
      <div class="flex justify-center mt-12 pb-5">
        <button
          class="w-4/6 lg:w-2/6 btn-primary font-regular text-lg lg:text-xl rounded-xl h-12"
          @click="createForm()"
        >
          <i class="fas fa-plus-circle pr-3"></i>
          Create form
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import FormService from "../services/af-quote/FormService";
export default {
  data() {
    return {
      value: 50,
      excellent_value: 8,
      good_value: 10,
      fair_value: 14,
      poor_value: 20,
      domain: "",
      code: "",
      email: "",
      claim:
        "Please complete the following form with the necessary information. We are here to assist you at every step of the way. \n\n <b> Remember, there is no impact on your credit score. </b>",
      congratulations_text:
        "A member of our sales team will contact you shortly. If you would like to speak to us in the meantime, don't hesitate to get in touch!",
      telephone: "",
      terms_link: "",
      form_sended: false,
      correct_register: false,
      domain_invalid: false,
      code_invalid: false,
      email_invalid: false,
      telephone_invalid: false,
      terms_link_invalid: false,
      domain_error: "",
      code_error: "",
      email_error: "",
      telephone_error: "",
      terms_link_error: "",

      elementVisible: false,
      show_checkbox: false,
      information_text: "",
    };
  },
  async created() {},
  computed: {
    total: function () {
      return this.value * 10;
    },
  },
  methods: {
    async createForm() {
      this.domain_invalid = false;
      this.code_invalid = false;
      this.email_invalid = false;
      this.telephone_invalid = false;
      this.terms_link_invalid = false;
      this.code_error = "";
      this.domain_error = "";
      this.email_error = "";
      this.telephone_error = "";
      this.terms_link_error = "";

      this.form_sended = true;

      if (this.code.length > 10) {
        this.code_invalid = true;
        this.code_error =
          "The length of the code cannot be longer than 10 characters";
      }

      if (this.domain.length == 0) {
        this.domain_invalid = true;
        this.domain_error = "Domain cannot be empty";
      }
      if (this.code.length == 0) {
        this.code_invalid = true;
        this.code_error = "Code cannot be empty";
      }
      if (this.email.length == 0) {
        this.email_invalid = true;
        this.email_error = "Email cannot be empty";
      }
      if (!this.validateEmail(this.email)) {
        this.email_invalid = true;
        this.email_error = "Invalid email format";
      }
      if (!this.validateTelephone(this.telephone)) {
        this.telephone_invalid = true;
        this.telephone_error = "Invalid telephone format";
      }
      if (this.telephone.length == 0) {
        this.telephone_invalid = true;
        this.telephone_error = "The telephone  cannot be empty";
      }
      if (this.terms_link.length == 0) {
        /*this.terms_link_invalid = true;
        this.terms_link_error = "The terms and conditions link cannot be empty";*/
        this.terms_link = null;
      }

      if (this.claim.length == 0) {
        this.claim = null;
      } else {
        this.claim = this.claim.split("\n").join("<br>");
      }
      if (this.congratulations_text.length == 0) {
        this.congratulations_text = null;
      } else {
        this.congratulations_text = this.congratulations_text
          .split("\n")
          .join("<br>");
      }

      let form = {
        name: this.domain,
        letter_type: document.getElementById("fonts").value,
        code: this.code,
        email: this.email,
        claim: this.claim,
        congratulations_text: this.congratulations_text,
        telephone: this.telephone,
        text_btn_color: document.getElementById("text_btn_color").value,
        border_radius: this.value + "px",
        color_primary: document.getElementById("primary_color").value,
        color_secondary: document.getElementById("secondary_color").value,
        background_input: document.getElementById("background_input").value,
        background_color: document.getElementById("background_color").value,
        text_color: document.getElementById("text_color").value,
        excellent_rate: this.excellent_value,
        good_rate: this.good_value,
        fair_rate: this.fair_value,
        poor_rate: this.poor_value,
        terms_link: this.terms_link,
        show_custom: this.show_checkbox,
        custom_text: this.information_text
      };
      if (this.information_text == "" && this.show_checkbox == true) {
        this.terms_link = "";
        this.claim = "";
        this.congratulations_text = "";
        this.elementVisible = true;
        setTimeout(() => (this.elementVisible = false), 3000);
      } else {
        if (
          !this.code_invalid &&
          !this.email_invalid &&
          !this.domain_invalid &&
          !this.telephone_invalid
        ) {
          let form_service = new FormService();
          let data = await form_service.createForm(form);
          if (this.claim != null) {
            this.claim = this.claim.split("<br>").join("\n");
          }
          if (this.congratulations_text != null) {
            this.congratulations_text = this.congratulations_text
              .split("<br>")
              .join("\n");
          }

          if (data.isError == false) {
            this.correct_register = true;

            this.$router.push({ path: "/forms/" + "created" });
          } else {
          }
        } else {
          this.terms_link = "";
          this.claim = "";
          this.congratulations_text = "";
          this.elementVisible = true;
          setTimeout(() => (this.elementVisible = false), 3000);
        }
      }
    },
    validateEmail(email) {
      var regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
    validateTelephone(telephone) {
      var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return regex.test(telephone);
    },
  },
};
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>