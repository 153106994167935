var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-img flex justify-center py-4 items-center",staticStyle:{"min-height":"94vh"},attrs:{"id":"login"}},[_c('title',[_vm._v("Zenfiny| Reset Password ")]),_c('div',{staticClass:"w-full lg:w-3/6 p-4 mt-5"},[_c('h1',{staticClass:"mb-12 text-center text-5xl lg:text-6xl"},[_vm._v(" RESET PASSWORD ")]),(_vm.alert)?_c('div',{staticClass:"bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow-md",attrs:{"role":"alert"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"py-1"},[_c('svg',{staticClass:"fill-current h-6 w-6 text-green-500 mr-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"}})])]),_vm._m(0)])]):_vm._e(),_c('form',{staticClass:"w-11/12 mx-auto"},[_c('div',{staticClass:"form-group my-10 mt-10"},[_c('label',{staticClass:"form-label inline-block mb-2 px-3 text-lg lg:text-xl",attrs:{"for":"exampleInputEmail1"}},[_vm._v("Please insert your email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control\n                      block\n                      w-full\n                      px-3\n                      py-2 lg:py-4 \n                      font-normal\n                      bg-white\n                      border-2 border-solid border-white\n                      rounded-full\n                      transition\n                      ease-in-out\n                      m-0\n                      focus:outline-none",class:{
                          'form-control': true,
                          'is-invalid': !_vm.validEmail(_vm.email) && _vm.already_submitted
                      },attrs:{"type":"email","id":"exampleInputEmail1","placeholder":"Email User"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Enter a valid email")])]),_c('div',{staticClass:"form-group my-10 "},[_c('label',{staticClass:"form-label inline-block mb-2 px-3 text-md lg:text-lg",attrs:{"for":"exampleInputPassword1"}},[_vm._v("Insert a new password")]),_c('div',{staticClass:"relative"},[(_vm.showPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password)  && _vm.already_submitted
                          },attrs:{"type":"text","id":"exampleInputPassword1","placeholder":"Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{ 
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password) && _vm.already_submitted
                          },attrs:{"type":"password","id":"exampleInputPassword1","placeholder":"New Password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(!_vm.errorPass)?_c('span',{staticClass:"button color-grey",on:{"click":_vm.toggleShow}},[_c('span',{staticClass:"icon is-small is-right absolute top-5 xl:top-5 right-4 cursor-pointer"},[_c('i',{staticClass:"fas ",class:{ 'fa-eye-slash': _vm.showPassword, 'fa-eye': !_vm.showPassword }})])]):_vm._e(),(_vm.errorPass)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Password is too short")]):_vm._e()])]),_c('div',{staticClass:"form-group my-10 "},[_c('label',{staticClass:"form-label inline-block mb-2 px-3 text-md lg:text-lg",attrs:{"for":"exampleInputPassword2"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"relative"},[(_vm.showPasswordConfirmPass)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password)  && _vm.already_submitted
                          },attrs:{"type":"text","id":"exampleInputPassword2","placeholder":"Password"},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"form-control block\n                          block\n                          w-full\n                          px-3\n                          py-2 lg:py-4 \n                          text-base\n                          font-normal\n                          bg-white\n                          border-2 border-solid border-white\n                          rounded-full\n                          transition\n                          ease-in-out\n                          m-0\n                          focus:outline-none",class:{
                              'form-control': true,
                              'is-invalid': !_vm.validPassword(_vm.password) && _vm.already_submitted
                          },attrs:{"type":"password","id":"exampleInputPassword2","placeholder":"Confirm Password"},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}}),(!_vm.errorPass)?_c('span',{staticClass:"button color-grey",on:{"click":_vm.toggleShowConfirmPass}},[_c('span',{staticClass:"icon is-small is-right absolute top-5 xl:top-5 right-4 cursor-pointer"},[_c('i',{staticClass:"fas ",class:{ 'fa-eye-slash': _vm.showPasswordConfirmPass, 'fa-eye': !_vm.showPasswordConfirmPass }})])]):_vm._e(),(_vm.errorPass)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("Password is too short")]):_vm._e()])]),_c('div',{staticClass:"flex justify-center mt-10"},[_c('button',{staticClass:"w-1/2 lg:w-1/2 btn-primary py-2 lg:py-2 text-lg lg:text-xl rounded-full",attrs:{"type":"submit"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-sign-in-alt mr-2"}),_vm._v(" RESET PASSWORD ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"font-bold"},[_vm._v("Your password has been successfully changed. ")]),_c('p',{staticClass:"text-sm"},[_vm._v("Now try to access "),_c('a',{attrs:{"href":"/login"}},[_vm._v("here")])])])}]

export { render, staticRenderFns }