<template>
   <div>
        <title>Zenfiny | Create Form</title>
        <FormCreate/>

   </div>
    
</template>
<script>
import FormCreate from '../../components/FormCreate.vue';
export default {
    
    components: {
        FormCreate
    },
    data(){
        return{

        };
    },
   async created() {
        
    },
    methods: {

    },

}
</script>