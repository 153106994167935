import * as API from "@/services/af-quote/API";

export default class QuoteService {
  async calculateQuote(form) {
    let data = await API.apiClient.post("/api/calculate", form);
    return data.data.data;
  }

  async createQuote(form) {
    let data = await API.apiClient.post("/api/quote", form);
    return data.data.data;
  }

  async editQuote(id, form) {
    let data = await API.apiClient.patch("/api/quote/" + id, form);
    return data.data.data;
  }

  async getQuotes(query = "") {
    try {
      let data = await API.apiClient.get(
        "/api/quote" + (query != "" ? "?" + query : "")
      );
      //(data.data.data, 'datos de la llamada getQuotes en el service')
      return data.data.data;
    } catch (error) {
      return error;
    }
  }

  async getLength() {
    try {
      let data = await API.apiClient.post("/api/quote/length");
      return data.data.data;
    } catch (error) {
      return error;
    }
  }

  async delete(id) {
    try {
      const response = await API.apiClient.delete("/api/quote/" + id);
      return response;
    } catch (error) {
      return error;
    }
  }

  async restore(id) {
    try {
      const response = await API.apiClient.patch("/api/quote/restore/" + id);
    } catch (error) {
      return error;
    }
  }

  async getOffer(id) {
    //console.log("CSA getOffer");
    try {
      const response = await API.apiClient.get("api/quoteoffer/" + id);
      //console.log("quoteoffer ");
      //console.log(response, 'llamada desde el servicio');
      return response.data.data;
    } catch (error) {
      let response ={
        'success':false,
      }
      return response;
    }
  }

  async createOffer(form) {
    try {
     
      const response = await API.apiClient.post("api/quoteoffer/", form);
      return response.data.data;
    } catch (error) {
      return error;
    }
  }

  async updateOffer(form, id) {
    const response = await API.apiClient.patch("api/quoteoffer/" + id, form);
    return response.data;
  }
}
