<template>
  <div>
    <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-lg lg:text-2xl font-bold">
        <i class="fas fa-file mr-2"></i>
        LIST FORMS
      </h1>
      <div class="grid grid-cols-2 gap-4 mt-3 lg:mt-0">
        <a
          href="/create-form"
          class="text-center text-sm lg:text-md btn-secondary inline-block lg:px-4 py-2 font-medium leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
        >
          <i class="fas fa-plus-circle mx-3"></i> ADD FORM
        </a>
        <button
          class="text-center text-sm lg:text-md font-medium btn-search inline-block lg:px-4 py-2 font-regular leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
          @click="toggleCollapse"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fas fa-search color-text mx-3"></i> SEARCH FILTER
        </button>
      </div>
    </div>
    <transition name="slide-fade">
    <!-- contenido del Collapse -->
    <div v-if="isCollapsed" id="collapseExample" class="my-4 mx-6">
      <div class="block p-6 rounded-lg shadow-lg bg-color">
        <div class="form-group mb-6">
          <label
            for="nameLisForm"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-md"
            >Domain</label
          >
          <input
            v-model="search_provisional_name"
            type="text"
            class="form-control block w-full px-3 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            id="nameLisForm"
            placeholder=""
          />
        </div>
        <div class="form-group mb-6">
          <label
            for="codeLisForm"
            class="form-label inline-block mb-2 color-grey font-regular px-3 text-md"
            >Code</label
          >
          <input
            v-model="search_provisional_code"
            type="text"
            class="form-control block w-full px-3 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
            id="codeLisForm"
            placeholder=""
          />
        </div>

        <div class="flex justify-end">
          <button
            v-on:click.stop.prevent="setSearch()"
            class="btn-primary inline-block px-4 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-purple-600 focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-2/12"
            type="button"
          >
            <i class="fas fa-search color-black mx-1"></i> SEARCH
          </button>
        </div>
      </div>
    </div>
    <!--end collapse-->
    </transition>
    <div style="width: 95%;" class="py-2 mt-5 overflow-x-scroll mx-auto">
        <div
          v-if="message == 'created'"
          v-show="elementVisible"
          class="bg-auto text-center rounded-xl font-normal mb-2 color-black bg-green-200 text-lg lg:text-xl p-2 w-full"
        >
          <p>Form created successfully</p>
        </div>
        <div
          v-if="message == 'updated'"
          v-show="elementVisible"
          class="bg-auto text-center rounded-xl font-normal mb-2 color-black bg-green-200 text-lg lg:text-xl p-2 w-full"
        >
          <p>Form updated successfully</p>
        </div>
        <table class="w-full">
          <thead class="bg-primary border-b-2">
            <tr>
              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Code
              </th>

              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Domain
              </th>

              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Email
              </th>
              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Telephone
              </th>
              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Font
              </th>

              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
                title="Primary color | Background color | Text color | Background input | Text button color"
              >
                Color
              </th>
              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left"
              >
                Border radius
              </th>
              <th
                scope="col"
                class="text-sm font-regular text-white px-4 py-2 text-left w-28"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="form in forms"
              v-bind:key="form.id"
              class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
            >
              <td class="text-sm font-semibold px-4 py-2 whitespace-nowrap">
                {{ form.code }}
              </td>
              <td class="text-sm px-4 py-2 whitespace-nowrap">
                {{ form.name }}
              </td>
              <td class="text-sm px-4 py-2 whitespace-nowrap">
                {{ form.email }}
              </td>
              <td class="text-sm px-4 py-2 whitespace-nowrap">
                {{ form.phone_number }}
              </td>
              <!--Leter tipes-->
              <td
                v-if="form.letter_type == 1"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Inter
              </td>
              <td
                v-if="form.letter_type == 2"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Merriweather
              </td>
              <td
                v-if="form.letter_type == 3"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Montserrat
              </td>
              <td
                v-if="form.letter_type == 4"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Poppins
              </td>
              <td
                v-if="form.letter_type == 5"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Lexend
              </td>
              <td
                v-if="form.letter_type == 6"
                class="text-sm px-4 py-2 whitespace-nowrap"
              >
                Lato
              </td>
              <!---->
              <td class="text-sm px-4 py-2 whitespace-nowrap">
                <input
                  type="color"
                  id="primarycolor"
                  name="primarycolor"
                  :value="form.color_primary"
                  class="lg:w-8 lg:h-8 sm:w-4 sm:h-4"
                  disabled
                  title="Primary color"
                />
                <input
                  type="color"
                  id="bgcolor"
                  name="bgcolor"
                  :value="form.background_color"
                  class="lg:w-8 lg:h-8 sm:w-4 sm:h-4"
                  disabled
                  title="Background color"
                />
                <input
                  type="color"
                  id="txtcolor"
                  name="txtcolor"
                  :value="form.text_color"
                  class="lg:w-8 lg:h-8 sm:w-4 sm:h-4"
                  disabled
                  title="Text color"
                />
                <input
                  type="color"
                  id="bginput"
                  name="bginput"
                  :value="form.background_input"
                  class="lg:w-8 lg:h-8 sm:w-4 sm:h-4"
                  disabled
                  title="Background input"
                />
                <input
                  type="color"
                  id="txtbtncolor"
                  name="txtbtncolor"
                  :value="form.text_btn_color"
                  class="lg:w-8 lg:h-8 sm:w-4 sm:h-4"
                  disabled
                  title="Text button color"
                />
              </td>

              <td class="text-sm px-4 py-2 whitespace-nowrap">
                {{ form.border_radius }}
              </td>

              <td
                class="text-md text-left font-medium px-4 py-2 whitespace-nowrap flex justify-between"
              >
                <button
                  @click="editForm(form.id)"
                  type="button"
                  class="inline-block py-2 font-medium text-md leading-tight color-black transition duration-150 ease-in-out pr-3 hover:opacity-70"
                  title="Edit"
                >
                  <i class="fas fa-edit color-grey"></i>
                </button>
                <button
                  @click="form_code = form.id"
                  type="button"
                  class="inline-block py-2 font-medium text-md leading-tight color-black transition duration-150 ease-in-out pr-3 hover:opacity-70"
                  data-bs-toggle="modal"
                  data-bs-target="#modalCode"
                  title="Link"
                >
                <i class="fas fa-code color-grey"></i>
                </button>
                <button
                  @click="form_delete = form.id"
                  type="button"
                  class="inline-block py-2 font-medium text-md leading-tight color-delete transition duration-150 ease-in-out hover:opacity-70"
                  data-bs-toggle="modal"
                  title="Delete"
                  data-bs-target="#modalDelete"
                >
                  <i class="fas fa-trash-alt"></i>
                </button>
              </td>
            
            </tr>
          </tbody>
        </table>
    
    </div>
    <!--Modal delete-->
    <div
      class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalDelete"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-xl modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
      >
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Delete form
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray-200 hover:opacity-75 hover:no-underline"
                aria-label="Cancel"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative py-6 px-4">
              <p>Are you sure you want to remove this form?</p>
            </div>
            <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                type="button"
                v-on:click.stop.prevent="deleteForm(form_delete)"
                data-bs-dismiss="modal"
                class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                <i class="fas fa-trash-alt"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End modal delete-->
    <!--Modal code-->

    <div
      class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
      id="modalCode"
      tabindex="-1"
      aria-labelledby="modalCode"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-lg modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
      >
        <div
          class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                CODE FORM
              </h5>
              <button
                type="button"
                data-bs-dismiss="modal"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray-200 hover:opacity-75 hover:no-underline"
                aria-label="Cancel"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative p-6">
              <p class="text-lg font-semibold">Add this code of your website.</p>
              <p class="text-md mt-4 text-gray-600">Embed the calculator code on your website.</p>
             
              <div class="relative">
                <input  :value="this.vue_api_url+'/calculator/finance-calculator/'+form_code" id="calculator" class="w-full rounded-lg border border-gray-400 p-3 mt-2  italic font-bold flex justify-between hover:bg-gray-100" />
                <i class="fas fa-copy hover:opacity-50 cursor-pointer absolute right-4 top-4" @click="copyText()"></i>
              </div>
               <p class="text-sm text-gray-600 mt-1 mb-8">
                 &lt;iframe title="form-calculadora" src="https://api.afcalc.com/calculator/finance-calculator/..." width="300" height="800"&lt;iframe&gt;
              </p>

              <p class="text-md mt-4 text-gray-600">Embed the apply form code on your website.</p>
              <div class="relative">
                <input :value="this.vue_api_url+'/form/finance-form/'+form_code" id="finance" class="w-full rounded-lg border border-gray-400 p-3 mt-2 italic font-bold flex justify-between hover:bg-gray-100"/>
                <i class="fas fa-copy hover:opacity-50 cursor-pointer absolute right-4 top-4" @click="copyTextFinance()"></i>
              </div>
                <p class="text-sm text-gray-600 mt-1 mb-8">
                 &lt;iframe title="form-calculadora" src="https://api.afcalc.com/form/finance-form/..." width="300" height="800"&lt;iframe&gt;
              </p>
         
               
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End modal code-->

    <!--PAGINATION-->
    <div aria-label="Page navigation" class="bg-transparent pb-4">
      <div class="flex flex-col mt-5">
        <ul class="inline-flex flex justify-center">
          <li>
            <button
              @click="getForms(current_page - 1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black rounded-l-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
          <li v-if="current_page > 1">
            <button
              @click="getForms(1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              1
            </button>
          </li>
          <li v-if="current_page > 2">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>

          <li>
            <button
              disabled
              class="h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-black focus:shadow-outline"
            >
              {{ current_page }}
            </button>
          </li>

          <li v-if="current_page < last_page - 1">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>
          <li v-if="current_page < last_page">
            <button
              @click="getForms(last_page)"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              {{ last_page }}
            </button>
          </li>

          <li>
            <button
              @click="getForms(current_page + 1)"
              :disabled="current_page == last_page"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-black rounded-r-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!--END-PAGINATION-->
  </div>
</template>
<script>
import FormService from "../services/af-quote/FormService";

export default {
  data() {
    return {
      isCollapsed: false,
      search_query: null,
      forms: null,
      form_delete: null,
      search_name: null,
      search_code: null,
      vue_api_url: "",
      form_code:null,
      search_provisional_name: null,
      search_provisional_code: null,
      current_page: null,
      last_page: null,
      message: false,
      elementVisible: true,
      calculator:"",
      finance:"",
    };
  },

  async created() {
    await this.getForms();
    this.message = this.$route.params.message;
    setTimeout(() => (this.elementVisible = false), 3000);
    this.vue_api_url = process.env.VUE_APP_API_URL;
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;  // Alterna el estado del colapso
    },
    async getForms(num_page = null) {
      if (num_page != null) {
        this.setSearch(num_page);
      }
      //console.log(this.search_query);
      const form_service = new FormService();
      if (this.search_query == null) {
        const form_data = await form_service.getForms();
        this.forms = form_data.forms.data;

        this.current_page = form_data.forms.current_page;
        this.last_page = form_data.forms.last_page;
      } else {
        const form_data = await form_service.getForms(this.search_query);
        this.forms = form_data.forms.data;
        this.current_page = form_data.forms.current_page;
        this.last_page = form_data.forms.last_page;
      }
    },
    copyText() {
      var copyText = document.getElementById("calculator");

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

   // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);

  // Alert the copied text
    },
    copyTextFinance() {
      var copyText = document.getElementById("finance");

  // Select the text field
  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices

   // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.value);

  // Alert the copied text
    },
    async deleteForm(form_id) {
      //console.log(form_id);
      const form_service = new FormService();
      const form_data = await form_service.deleteForm(form_id);
      //console.log(form_data);
      if (form_data.isError == false) {
        location.reload();
      }
    },

    editForm(id) {
      this.$router.replace({ name: "editform", params: { id: id } });
    },

    async setSearch(num_page = null) {
      this.search_code = this.search_provisional_code;
      this.search_name = this.search_provisional_name;
      this.search_query = null;

      if (num_page != null) {
        this.search_query = "?page=" + num_page;
      }

      if (this.search_name != null && this.search_name != "") {
        this.search_query = "?name=" + this.search_name;
      }

      if (
        this.search_name != null &&
        this.search_name != "" &&
        num_page != null
      ) {
        this.search_query = "?page=" + num_page + "&name=" + this.search_name;
      }

      if (this.search_code != null && this.search_code != "") {
        this.search_query = "?code=" + this.search_code;
      }

      if (
        this.search_code != null &&
        this.search_code != "" &&
        num_page != null
      ) {
        this.search_query = "?page=" + num_page + "&code=" + this.search_code;
      }

      if (this.search_name != null && this.search_code != null) {
        this.search_query =
          "?name=" + this.search_name + "&code=" + this.search_code;
      }
      if (
        this.search_name != null &&
        this.search_code != null &&
        num_page != null
      ) {
        this.search_query =
          "?page=" +
          num_page +
          "&name=" +
          this.search_name +
          "&code=" +
          this.search_code;
      }
      if (num_page == null) {
        await this.getForms();
      }
    },
  },
};
</script>

<style>

.slide-fade-enter-active {
  transition: all 0.6s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active en <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}


</style>