<template>
  <div id="login"  style="min-height: 94vh;" class="bg-img flex justify-center py-4 items-center">
      <title>Zenfiny| Reset Password </title>
        <div class="w-full lg:w-3/6 p-4 mt-5">
            <h1 class="mb-12 text-center text-5xl lg:text-6xl">
                RESET PASSWORD
            </h1>
                <div v-if="alert" class="bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow-md" role="alert">
                    <div class="flex">
                        <div class="py-1"><svg class="fill-current h-6 w-6 text-green-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                        <div>
                        <p class="font-bold">Your password has been successfully changed. </p>
                        <p class="text-sm">Now try to access <a href="/login">here</a> </p>
                        </div>
                    </div>
                </div>
            <form class="w-11/12 mx-auto">
                <div class="form-group my-10 mt-10">
                    <label for="exampleInputEmail1" class="form-label inline-block mb-2 px-3 text-lg lg:text-xl">Please insert your email</label>
                    <input v-model="email" type="email" class="form-control
                        block
                        w-full
                        px-3
                        py-2 lg:py-4 
                        font-normal
                        bg-white
                        border-2 border-solid border-white
                        rounded-full
                        transition
                        ease-in-out
                        m-0
                        focus:outline-none" id="exampleInputEmail1"  placeholder="Email User"
                        v-bind:class="{
                            'form-control': true,
                            'is-invalid': !validEmail(email) && already_submitted
                        }" 
                    > 
                    <div class="invalid-feedback">Enter a valid email</div>
                </div>
                <div class="form-group my-10 ">
                    <label for="exampleInputPassword1" class="form-label inline-block mb-2 px-3 text-md lg:text-lg">Insert a new password</label>
                    <div class="relative">
                        <input  v-if="showPassword" type="text" v-model="password"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword1" placeholder="Password"
                            v-bind:class="{
                                'form-control': true,
                                'is-invalid': !validPassword(password)  && already_submitted
                            }" 
                        >
                  
                        <input v-else type="password" v-model="password"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword1" placeholder="New Password"
                            v-bind:class="{ 
                                'form-control': true,
                                'is-invalid': !validPassword(password) && already_submitted
                            }"
                           
                        >

                        <span v-if="!errorPass" class="button color-grey" @click="toggleShow">
                            <span class="icon is-small is-right absolute top-5 xl:top-5 right-4 cursor-pointer">
                                <i class="fas " :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" ></i>
                            </span>
                        </span>
                        <div v-if="errorPass" class="invalid-feedback">Password is too short</div>
                    </div>
                </div>
                <div class="form-group my-10 ">
                    <label for="exampleInputPassword2" class="form-label inline-block mb-2 px-3 text-md lg:text-lg">Confirm Password</label>
                    <div class="relative">
                        <input  v-if="showPasswordConfirmPass" type="text" v-model="password_confirm"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword2" placeholder="Password"
                            v-bind:class="{
                                'form-control': true,
                                'is-invalid': !validPassword(password)  && already_submitted
                            }" 
                        >
                  
                        <input v-else type="password" v-model="password_confirm"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword2" placeholder="Confirm Password"
                            v-bind:class="{
                                'form-control': true,
                                'is-invalid': !validPassword(password) && already_submitted
                            }"
                           
                        >

                        <span   v-if="!errorPass" class="button color-grey" @click="toggleShowConfirmPass">
                            <span class="icon is-small is-right absolute top-5 xl:top-5 right-4 cursor-pointer">
                                <i class="fas " :class="{ 'fa-eye-slash': showPasswordConfirmPass, 'fa-eye': !showPasswordConfirmPass }" ></i>
                            </span>
                        </span>
                        <div v-if="errorPass" class="invalid-feedback">Password is too short</div>
                    </div>
                </div>


                <div class="flex justify-center mt-10">
                    <button type="submit" v-on:click.stop.prevent="handleSubmit" class="w-1/2 lg:w-1/2 btn-primary py-2 lg:py-2 text-lg lg:text-xl rounded-full">
                        <i class="fas fa-sign-in-alt mr-2"></i>
                        RESET PASSWORD
                    </button>
                </div>
            </form>


        </div>
    </div>
</template>
<script>
import axios from "axios";
import AuthService from "../services/af-quote/AuthService";
export default {
        name: 'Reset',
        data(){
            return{
                token:'',
                email:null,
                password:'',
                password_confirm:'',
                showPassword: false,
                showPasswordConfirmPass: false,
                errorPass:false,
                already_submitted:false,
                alert:false
            }
        },
        mounted() {
            this.token = this.$route.query.token;
        },
        methods:{

            buttonLabel() {
                return (this.showPassword) ? "Hide" : "Show";
            },

            toggleShow() {
            this.showPassword = !this.showPassword;
            },
              toggleShowConfirmPass() {
            this.showPasswordConfirmPass = !this.showPasswordConfirmPass;
            },

            async  handleSubmit(){
                 this.already_submitted = true;
                if((this.password == this.password_confirm)&&this.validPassword(this.password) && this.validPassword(this.password_confirm)){
                    try {
                        let form = {
                            token:this.token,
                            email:this.email,
                            password:this.password,
                            password_confirmation:this.password_confirm
                        };
                        
                        const response = await AuthService.resetPassword(form);      
                        this.alert = true;
                    } catch(error) {
                       //console.error(error);
                    }
                }else{
                     this.errorPass = true;
                }
            },
            
            validEmail(str) {
                var re = /(.+)@(.+){2,}\.(.+){2,}/;
                return str != '' && re.test(str); 
            },

            validPassword(str) {
                return str != null && str.length > 7;
            },
    
        }
}
</script>
