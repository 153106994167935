import axios from "axios";
import store from "@/store";
import * as API from "@/services/af-quote/API";

/*
export const authClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true, // required to handle the CSRF token
});
*/
/*
 * Add a response interceptor
 */
/*
authClient.interceptors.response.use(
    (response) => {
        return response;
    },
    function(error) {
        if (
            error.response && [401, 419].includes(error.response.status) &&
            store.getters["auth/authUser"] &&
            !store.getters["auth/guest"]
        ) {
            store.dispatch("auth/logout");
        }
        return Promise.reject(error);
    }
);
*/
export default {
    async login(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        let response = await API.apiClient.post("/login", payload);
        return response;
    },
    logout() {
        return API.apiClient.post("/logout");
    },
    async forgotPassword(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        return API.apiClient.post("/forgot-password", payload);
    },
    getAuthUser() {
        return API.apiClient.get("/api/users/auth");
    },
    async resetPassword(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        return API.apiClient.post("/reset-password", payload);
    },
    updatePassword(payload) {
        return API.apiClient.put("/user/password", payload);
    },
    async registerUser(payload) {
        await API.apiClient.get("/sanctum/csrf-cookie");
        return API.apiClient.post("/api/register", payload);
    },
    sendVerification(payload) {
        return API.apiClient.post("/email/verification-notification", payload);
    },
    updateUser(payload) {
        return API.apiClient.put("/user/profile-information", payload);
    },
};  