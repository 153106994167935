<template>
<div id="home" class="relative z-10">
    <head>
        <title>Home | Zenfiny</title>
    </head>
    <div v-if="!logged">
        <home-public/>
    </div>
    <div v-else-if="!isAdmin">
        <home-manager v-bind:username="user.name"/>
    </div>
    <div v-else>
        <home-admin v-bind:username="user.name"/>
    </div>
</div>

</template>

<script>
import { mapGetters } from "vuex";
import HomeAdmin from '../components/homes/HomeAdmin.vue';
import HomePublic from '../components/homes/HomePublic.vue';
import HomeManager from '../components/homes/HomeManager.vue';

export default {
  components: { HomePublic, HomeManager, HomeAdmin},
  name: "Home",
  computed: {
        ...mapGetters({
            user: "auth/authUser",
            isAdmin: "auth/isAdmin",
            logged: "auth/loggedIn",
        }),
    },
};

</script>
