import Vue from "vue";
import Vuex from "vuex";

import * as auth from "@/store/modules/Auth";
import * as user from "@/store/modules/User";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    mutations: {},
    actions: {},
    getters: {},
    plugins: [createPersistedState()],


    modules: {
        auth,
        user,
    },
});