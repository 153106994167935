import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "tw-elements";

import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Importar los iconos que necesitas
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

library.add(
  faDownload, 
  faSignInAlt, 
  faFile, 
  faPlusCircle, 
  faSearch, 
  faEdit, 
  faCode,
  faHome,
  faTimes,
  faEye,
  faEyeSlash,
  faUser,
  faUserCircle,
  faUsers,
  faCalculator,
  faHandshake,
  faList,
  faTags,
  faUndo,
  faPhone,
  faGlobe,
  faEnvelope,
  faChevronCircleRight,
  faChevronCircleLeft,
  faTrashAlt,
  faPlus,
  faInfo,
);

// Este código busca automáticamente los iconos en el DOM
dom.watch();

Vue.config.productionTip = false;

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
