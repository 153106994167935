<template>
  <div class="bg-white" id="RegisterCustomer">
    <title>Zenfiny | Register Quote</title>
    <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-xl lg:text-2xl font-bold">
        <i class="fas fa-calculator mr-2"></i>
        NEW QUOTE
      </h1>
    </div>
    <step-wizard ref="stepWizard">
      <tab name="Name Step" :selected="true">
        <name-step ref="name_step" @next="goNext" @qname="quoteName" />
      </tab>
      <tab name="Quote Step">
        <quote-step
          ref="quote_step"
          @next="goNext"
          @backwards="goBack"
          @quote_data="quoteData"
        />
      </tab>
      <tab name="Result Step">
        <result-step
          ref="result_step"
          @submit="submit"
          @create_offer="createOffer"
          @backwards="goBack"
          @submit_offer="submitOffer"
        />
      </tab>
    </step-wizard>
  </div>
</template>
<script>
import StepWizard from "../../../components/StepWizard/StepWizard.vue";
import Tab from "../../../components/StepWizard/Tab.vue";
import NameStep from "./NameStep.vue";
import ResultStep from "./ResultStep.vue";
import QuoteStep from "./QuoteStep.vue";
import QuoteService from "../../../services/af-quote/QuoteService";
import { mapGetters } from "vuex";

export default {
  name: "register-customer",
  components: {
    StepWizard,
    Tab,
    NameStep,
    ResultStep,
    QuoteStep,
  },
  data() {
    return {
      id: null,
      name: null,
      brand_id: null,
      quote_type: null,
      quote_data: null,
      quote_payment: null,
      quote_apr: null,
      quote_gross_yield: null,
      quote_flat_rate: null,
      quote_total_interest: null,
      quote_vat_deferal: null,
      monthly_history: null,
      code: null,

      quote_offer: false,
      assets: null,
      conditions: null,

      edit_mode: false,
      net_commission: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
  },

  mounted() {
    //console.log('monta el componente')
    if (this.$route.params.quote) {
      //console.log('entra en el if de si hay paramentros')
      const in_quote = this.$route.params.quote;
      //console.log("quote**",in_quote)
      //console.log(in_quote, 'cuando se monta el component si hay parametros')
      this.code = in_quote.code;
      this.id = in_quote.id;
      this.name = in_quote.name;
      this.brand_id = in_quote.brand_id;
      this.quote_type = in_quote.type;
      this.quote_payment = in_quote.payment;
      this.quote_vat_deferal = in_quote.vat_deferal;
      this.edit_mode = true;

      this.$refs.name_step.setQuote(this.name, this.brand_id, this.quote_type);

      // console.log(in_quote);
      this.$refs.quote_step.setQuote(
        in_quote.cost,
        in_quote.commission,
        in_quote.vat,
        in_quote.deposit,
        in_quote.term,
        in_quote.baloon,
        in_quote.balloon_fall,
        in_quote.interest,
        in_quote.advanced_pay,
        in_quote.vat_deferal,
        in_quote.quote_type,
        in_quote.net_commission,
        in_quote.net_deposit,
        in_quote.not_vat_items

      );
      this.$refs.result_step.setQuote(this.code);
    }
  },
  methods: {
    async createOffer(quote) {
      // this.assets = assets;
      // this.conditions = conditions;
      // console.log("estamos creando quote and offer");

      // console.log(quote);
      let form = this.formulario(quote.quote_code);
      const quote_service = new QuoteService();
      this.edit_mode = true;
      const data = await quote_service.createQuote(form);
      this.id = data.id;
      if (data.id != null) {
        await quote_service.createOffer({
          quote_id: this.id,
          assets: quote.offer.assets,
          conditions: quote.offer.conditions,
        });
        this.edit_mode = true;
        this.$refs.result_step.setIdCreate(this.id);
      }
    },
    async update() {
      // console.log("update quote and route to /");
    },
    formulario(quote_code) {
      this.code = quote_code;
      var commission_percentage = this.quote_data["commission"]
        ? (
            (this.quote_data["commission"] /
              (this.quote_data["cost"] +
                this.quote_data["vat"] -
                this.quote_data["deposit"])) *
            100
          ).toFixed(2)
        : 0;
      var net_commission = this.quote_data["net_commission"]
        ? this.quote_data["net_commission"]
        : false;
      let form = {
        user_id: this.user.id,
        brand_id: this.brand_id,
        code: this.code,
        name: this.name,
        type: this.quote_type,
        cost: this.quote_data["cost"],
        vat: this.quote_data["vat"],
        commission: this.quote_data["commission"],
        commission_percentage: commission_percentage,
        net_commission: net_commission,
        net_deposit :  this.quote_data["net_deposit"],
        deposit: this.quote_data["deposit"],
        term: this.quote_data["term"],
        balloon: this.quote_data["balloon"],
        balloon_fall: this.quote_data["per"],
        interest: this.quote_data["interest"],
        advanced_pay: this.quote_data["advances"],

        payment: this.quote_payment,
        gross_yield: this.quote_gross_yield,
        flat_rate: this.quote_flat_rate,
        apr: this.quote_apr,
        total_interest: this.total_interest,
        vat_deferal: this.quote_data["vat_deferal"],
        not_vat_items: this.quote_data["not_vat_items"]
      };
      return form;
    },
    async submit(quote_code) {
      try {
        let form = this.formulario(quote_code);
        // console.log(form);
        // alert("sdfs");
        const quote_service = new QuoteService();
        if (!this.edit_mode) {
          // console.log("creando una nueva cuota .....");
          const data = await quote_service.createQuote(form);
          this.id = data.id;
          // if (this.quote_offer)
          //   await quote_service.createOffer({
          //     quote_id: this.id,
          //     assets: this.assets,
          //     conditions: this.conditions,
          //   });
        } else {
          // console.log("Editando una nueva cuota .....");
          const response_edit = await quote_service.editQuote(this.id, form);
          // console.log(response_edit);
          // if (this.quote_offer) {
          //   try {
          //     await quote_service.getOffer(this.id);

          //     await quote_service.updateOffer(
          //       { assets: this.assets, conditions: this.conditions },
          //       this.id
          //     );
          //   } catch (error) {
          //     await quote_service.createOffer({
          //       quote_id: this.id,
          //       assets: this.assets,
          //       conditions: this.conditions,
          //     });
          //   }
          // }
        }


        this.$router.replace("/");
      } catch (error) {
        // console.log("error submit registerQuote vue..");
        this.$refs.result_step.hasFailed(error);
        console.error(error);
      }
    },
    quoteName([q_name, brand_id, quote_type]) {
      this.name = q_name;
      this.brand_id = brand_id;
      this.quote_type = quote_type;
      if (this.quote_type != "lease") this.vat = null;
      this.$refs.quote_step.setType(this.quote_type);
    },

    quoteData([form, data]) {
      // console.log(form);
      this.quote_data = form;
      this.quote_data.id = this.id;
      // console.log(this.quote_data, 'informacion de cuota del padre');
      this.quote_payment = data["monthly_payment"];
      this.quote_apr = data["apr"];
      this.quote_gross_yield = data["gross_yield"];
      this.quote_flat_rate = data["flat_rate"];
      this.monthly_history = data["dynamic_cost"];
      this.total_interest = data["total_interest"];
      this.$refs.result_step.setData(
        this.name,
        this.quote_type,
        this.quote_data,
        {
          payment: this.quote_payment,
          gross_yield: this.quote_gross_yield,
          flat_rate: this.quote_flat_rate,
          apr: this.quote_apr,
          total_interest: this.total_interest,
        },
        this.monthly_history
      );
    },

    submitOffer([assets, conditions]) {
      this.assets = assets;
      this.conditions = conditions;
      this.quote_offer = true;
    },

    goNext() {
      this.$refs.stepWizard.next();
    },

    goBack() {
      this.$refs.stepWizard.previous();
    },
  },
};
</script>
