/*
 * This is the initial API interface
 * we set the base URL for the API
 */

import axios from "axios";

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_COMPANY_HOUSE_BASE,
  headers: {
    Authorization: process.env.VUE_APP_COMPANY_HOUSE_KEY,
    "Content-Type": "application/json",
  },
});
