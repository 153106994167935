<template>
  <div ref="download" class="w-full mx-0 px-0 py-0 my-0">
    <!-- -------page1 -->
    <div class="" style="width: 793.5px; height: 1120.5px; position: relative">
      <div class="absolute top-16 left-20">
        <h2 style="letter-spacing: 5px" class="text-xl mb-2 font-light">
          PROPOSED BY
        </h2>
        <div>
          <img v-if="quote.brand != null && quote.brand.img != ''" :src="quote.brand.img" alt=""
          class="img-fluid mx-auto text-center" style="height: auto; max-width:300px; max-height: 250px; " />
          <img v-else class="img-fluid mx-auto text-center" style="height: auto; width: 200px" src="/img/ejemplo.brand.png"
            alt="" />
        </div>
      </div>
      <div class="absolute top-32 right-0">
        <div class="text-vertical">
          <a class="text-md font-light" :href="quote.brand.url">{{
            quote.brand.url
          }}</a><br />
          <a class="text-md font-light" :href="`mailto:${quote.brand.email}`">{{
            quote.brand.email
          }}</a>
        </div>
      </div>
      <div class="absolute bottom-7" style="left: -100px;">
        <div class="text-footer" >
          <p class="text-md font-light">{{ quote.brand.address_line_1 }}</p>
          <p class="text-md font-light">{{ quote.brand.address_line_2 }}</p>
        </div>
      </div>
      <div class="absolute bottom-20 right-10">
        <div :style="{ 'background-color': quote.brand.color }" style="border-radius: 40px" class="px-8 py-14 pr-14">
          <h3 style="letter-spacing: 1.5px; font-size: 34px" class="text-white font-semibold">
            OFFER LETTER
          </h3>
          <h4 style="letter-spacing: 1.5px; font-size: 34px; line-height: 35px" v-if="quote.type != 'lease'"
            class="text-white font-thin mt-1">
            UNREGULATED <br />HIRE PURCHASE
          </h4>
          <h4 style="letter-spacing: 1.5px; font-size: 34px; line-height: 35px" v-else class="text-white font-thin mt-1">
            UNREGULATED <br />LEASE
          </h4>
        </div>
      </div>
    </div>
    <!-- --------page2 -->
    <div class="pagebreak"></div>
    <!--  -->
    <div class="w-9/12 mx-auto">
      <div style="margin-top: 10%">
        <h2 :style="{ color: quote.brand.color }" style="letter-spacing: 5px" class="font-xl font-bold mb-4">
          OFFER LETTER
        </h2>
        <hr style="height: 4px" />
        <div class="mt-5">
          <div style="align-items: center" class="flex w-full items-center">
            <h3 style="font-size: 14px" class="font-semibold">DATE:</h3>
            <p style="font-size: 14px" class="">
              &nbsp;&nbsp;{{
                quote.created_at != undefined
                ? date(quote.updated_at)
                : date(Date.now())
              }}
            </p>
          </div>
          <div class="flex w-full items-center mt-5">
            <h3 style="font-size: 14px" class="font-semibold">DEAR</h3>
            <p style="font-size: 14px">&nbsp;&nbsp;{{ quote.name }}</p>
          </div>
          <p style="font-size: 14px" class="mt-6">
            We are delighted to confirm that {{ quote.brand.name }} has a
            provisional acceptance for your finance facility as detailed below.
            This offer will supersede any prior oral or written agreements,
            proposals or understandings with respect to this facility provided
            by the lender, unless otherwise stated. This offer is subject to the
            conditions set out below, appropriate documentation and any lender
            due diligence.
          </p>

          <h3 style="font-size: 14px" class="font-bold mt-8 mb-4" :class="{ 'mb-1' :quote.not_vat_items}">
            Please sign and return this letter to confirm your acceptance of the
            terms.
          </h3>
          <div class="mt-7">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  BORROWER
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  {{ quote.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  FACILITY TYPE
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  {{ quote.type == "lease" ? "Lease" : "Hire Purchase" }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  COST
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  £ {{ formatNumber(quote.cost) }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="quote.not_vat_items" class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  NOT VAT ITEMS
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  £ {{ formatNumber(quote.not_vat_items) }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="quote.not_vat_items" class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  TOTAL NET
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  £ {{ totalNetCost }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  ASSET/S
                </h3>
              </div>
              <div style="min-height: 100px" class="w-full border border-gray-400">
                <p style="font-size: 14px; padding-left: 4px">
                  {{ offer.assets }}
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  FACILITY PERIOD
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  {{ quote.term }} months
                </p>
              </div>
            </div>
          </div>
          <div v-if="quote.type != 'lease'" class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  DEPOSIT AMOUNT
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="color: #737373; font-size: 14px; padding-left: 4px">
                  £ {{ quote.deposit ? formatNumber(quote.deposit) : 0 }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="mt-4">
            <div class="flex w-full items-top">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%; margin-top: 3px"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  DEPOSIT
                </h3>
              </div>
              <div class="grid grid-cols-3 gap-3 w-full">
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      Amount
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="font-size: 14px; padding-left: 4px">
                      £ {{ formatNumber(quote.deposit) }}
                    </p>
                  </div>
                </div>
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      VAT
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="color: #737373; font-size: 14px; padding-left: 4px">
                      £
                      {{
                        quote.deposit > 0
                        ? formatNumber(
                          calculateVat(parseFloat(quote.deposit))
                        )
                        : 0
                      }}
                    </p>
                  </div>
                </div>
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      Total
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="font-size: 14px; padding-left: 4px">
                      £
                      {{
                        quote.deposit > 0
                        ? formatNumber(
                          calculateTotal(parseFloat(quote.deposit))
                        )
                        : 0
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  NET ADVANCE
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="color: #737373; font-size: 14px; padding-left: 4px">
                  £
                  {{
                    formatNumber(
                      parseFloat(quote.cost) +
                      parseFloat(quote.vat ? quote.vat : "0") -
                      parseFloat(quote.deposit ? quote.deposit : "0")
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="quote.type != 'lease'" class="mt-4">
            <div class="flex w-full items-center">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  REPAYMENTS
                </h3>
              </div>
              <div class="w-full border border-gray-400 p-0">
                <p style="color: #737373; font-size: 14px; padding-left: 4px">
                  £ {{ formatNumber(parseFloat(quote.payment)) }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="mt-4">
            <div class="flex w-full items-top">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%; margin-top: 3px"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  REPAYMENTS
                </h3>
              </div>
              <div class="grid grid-cols-3 gap-3 w-full">
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      Amount
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="font-size: 14px; padding-left: 4px">
                      £ {{ formatNumber(quote.payment) }}
                    </p>
                  </div>
                </div>
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      VAT
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="font-size: 14px; padding-left: 4px">
                      £
                      {{
                        formatNumber(calculateVat(parseFloat(quote.payment)))
                      }}
                    </p>
                  </div>
                </div>
                <div class="w-full">
                  <div :style="{ 'background-color': quote.brand.color }" style="margin-bottom: 4px"
                    class="h-6 rounded-full w-full text-center mr-3">
                    <h3 style="font-size: 14px" class="text-white font-bold">
                      Total
                    </h3>
                  </div>
                  <div class="w-full border border-gray-400 p-0">
                    <p style="color: #737373; font-size: 14px; padding-left: 4px">
                      £
                      {{
                        formatNumber(calculateTotal(parseFloat(quote.payment)))
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="flex w-full">
              <div :style="{ 'background-color': quote.brand.color }" style="width: 35%"
                class="h-6 rounded-full w-3/12 text-center mr-3">
                <h3 style="font-size: 14px" class="text-white font-bold">
                  CONDITIONS
                </h3>
              </div>
              <div style="min-height: 215px" class="w-full border border-gray-400 p-0">
                <p style="font-size: 14px; padding-left: 4px">
                  {{ offer.conditions ? offer.conditions : "" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagebreak"></div>
      <div class="mt-10">
        <p style="font-size: 14px">
          We also require the following documentation as part of the ‘Know your
          Clients’ policy at the time of signing up in respect of all
          directors/major shareholders.
        </p>
        <ul class="my-4" style="list-style-type: disc; padding-left: 2%">
          <li style="font-size: 14px">
            A copy of a valid Full UK Driving License
          </li>
          <li style="font-size: 14px">A copy of a valid Passport</li>
          <li style="font-size: 14px">
            If you are unable to provide one of these, please provide a Utility
            Bill dated within the last 3 months
          </li>
        </ul>
        <p style="font-size: 14px" class="mt-5">
          The lender reserves the right to amend or withdraw the terms set out
          herein in whole or in part (notwithstanding the acceptance of any such
          terms by the customer) at any time if there occurs any change in facts
          or circumstances or there becomes known to the lender any facts or
          circumstances which, had they existed or been known at the date here
          of, would have affected the decision to issue this letter or the terms
          set out there in. This offer is subject to all documentation being
          satisfactorily completed, clearance of any deposit, any facility fee
          and receipt of a suitable original invoice. The raising of an invoice
          does not constitute a contract between the supplier and us/the lender.
          By signing this letter you authorise {{ quote.brand.name }}
          to share all information, including personal data with the selected
          lender.
        </p>

        <p style="font-size: 10.5px" class="mt-8">
          Your first monthly rental will be subject to an additional fee
          determined by the lender.
        </p>
      </div>
      <div class="form-check mt-5">
        <input
          class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox" value="" id="flexCheckDefault" />
        <label style="font-size: 14px" class="font-bold form-check-label inline-block text-gray-800"
          for="flexCheckDefault">
          I accept the terms of this offer letter
        </label>
      </div>
      <div class="mt-8">
        <h3 style="font-size: 14px" class="font-bold">
          Signed for and on behalf of
        </h3>
        <div class="flex mt-20 justify-between">
          <div class="w-1/2 mr-4">
            <div style="padding-bottom: 10px" class="h-6 border-b-2 w-full"></div>
            <p class="font-light">SIGNATURE</p>
          </div>
          <div class="w-1/2 ml-4">
            <div style="padding-bottom: 10px" class="h-6 border-b-2 w-full">
              <p class="font-regular">
                {{
                  quote.created_at != undefined
                  ? date(quote.updated_at)
                  : date(Date.now())
                }}
              </p>
            </div>
            <p class="font-light">DATE</p>
          </div>
        </div>
        <div class="flex justify-end w-12/12 mt-36">
          <h2 :style="{ color: quote.brand.color }" style="
              font-size: 60px;
              text-align: right;
              letter-spacing: 5px;
              line-height: 55px;
            " class="font-bold text-right">
            ACHIEVE <br />
            MORE <br />
            TOGETHER
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "pdf",
  props: ["quote", "offer"],

  data() {
    return {};
  },

  methods: {
    calculateVat(num) {
      return num * process.env.VUE_APP_UK_VAT;
    },

    calculateTotal(num) {
      return num + parseFloat(this.calculateVat(num));
    },

    date(value) {
      return moment(value).format("DD/MM/yyyy");
    },

    formatNumber(x) {
      x = parseFloat(x);

      let nf = new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      nf.resolvedOptions().minimumFractionDigits = 2;
      return nf.format(x);
    },
  },
  computed:{
    totalNetCost:function(){
      var not_vat_items = parseFloat(this.quote.not_vat_items);
      var cost = parseFloat(this.quote.cost);
      //console.log(not_vat_items+cost);
      return this.formatNumber(not_vat_items+cost);
    },
  }
};
</script>
<style lang="scss">
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
  vertical-align: middle;
}

img {
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  position: relative;
}
</style>
