import router from "@/router";
import AuthService from "@/services/af-quote/AuthService";
import axios from "axios";

export const namespaced = true;

export const state = {
  user: null,
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
};

export const actions = {
  async login({ commit }, form) {
    const response = await AuthService.login(form).catch((error) => {
      throw error;
    });
    return await actions.getAuthUser({ commit });
  },

  async logout({ commit, dispatch }) {
    return await AuthService.logout()
      .then(() => {
        commit("SET_USER", null);

        if (router.currentRoute.name !== "login")
          router.push({ path: "/login" });
      })
      .catch((error) => {
        throw error;
      });
  },

  cleanUser({ commit }) {
    commit("SET_USER", null);
  },

  async getAuthUser({ commit }) {
    try {
      const response = await AuthService.getAuthUser();
      commit("SET_USER", response.data.data);
      return response.data.data;
    } catch (error) {
      commit("SET_USER", null);
      throw error;
    }
  },
};

export const getters = {
  authUser: (state) => {
    return state.user;
  },
  isAdmin: (state) => {
    return state.user && state.user.isAdmin;
  },
  loggedIn: (state) => {
    return state.user != null;
  },
};
