<template>
  <div id="login"  style="min-height: 94vh;" class="bg-img flex justify-center py-4 items-center">
      <title>Zenfiny | Login </title>
        <div class="w-full lg:w-3/6 p-4 mt-5">
            <h1 class="mb-12 text-center text-5xl lg:text-6xl">
                LOG IN
            </h1>
            <form class="w-11/12 mx-auto">
                <div v-if="invalid_login" class="bg-red-100 border border-red-400 font-medium text-red-700 px-4 py-2 rounded relative" role="alert">
                    Incorrect email or password
                </div>
                <div class="form-group mb-6">
                    <label for="exampleInputEmail1" class="form-label inline-block mb-2 px-3 text-lg lg:text-xl">Email</label>
                    <input v-model="email" type="email" class="form-control
                        block
                        w-full
                        px-3
                        py-2 lg:py-4 
                        font-normal
                        bg-white
                        border-2 border-solid border-white
                        rounded-full
                        transition
                        ease-in-out
                        m-0
                        focus:outline-none" id="exampleInputEmail1"  placeholder="Email User"
                        v-bind:class="{
                            'form-control': true,
                            'is-invalid': !validEmail(email) && already_submitted
                        }" 
                >
                <div class="invalid-feedback">Enter a valid email</div>
                        
                </div>
                <div class="form-group my-10 ">
                    <label for="exampleInputPassword1" class="form-label inline-block mb-2 px-3 text-lg lg:text-xl">Password</label>
                    <div class="relative">
                        <input  v-if="showPassword" type="text" v-model="password"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword1" placeholder="Password"
                            v-bind:class="{
                                'form-control': true,
                                'is-invalid': !validPassword(password) && already_submitted
                            }" 
                        >
                  
                        <input v-else type="password" v-model="password"  class="form-control block
                            block
                            w-full
                            px-3
                            py-2 lg:py-4 
                            text-base
                            font-normal
                            bg-white
                            border-2 border-solid border-white
                            rounded-full
                            transition
                            ease-in-out
                            m-0
                            focus:outline-none" id="exampleInputPassword1" placeholder="Password"
                            v-bind:class="{
                                'form-control': true,
                                'is-invalid': !validPassword(password) && already_submitted
                            }"
                           
                        >

                        <span v-if="!errorPass" class="button color-grey" @click="toggleShow">
                            <span class="icon is-small is-right absolute top-3 xl:top-5 right-4 cursor-pointer">
                                <i class="fas " :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }" ></i>
                            </span>
                        </span>
                        <div v-if="errorPass" class="invalid-feedback">Password is too short</div>
                    </div>
                </div>

                <div>   
                    <p class="forgot-password text-right">
                        <router-link to ="forgot-password">Forgot password?</router-link>
                    </p>
                </div>

                <div class="flex justify-center mt-10">
                    <button type="submit" v-on:click.stop.prevent="submit" class="w-2/4 lg:w-2/6 btn-primary py-2 lg:py-2 text-2xl lg:text-3xl rounded-full">
                        <i class="fas fa-sign-in-alt mr-2"></i>
                        SIGN IN 
                    </button>
                </div>
            </form>


        </div>
    </div>
</template>

<script>

//import AuthService from "../services/AuthService";
import { mapActions } from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      errorPass:false,
      email:null,
      password:null,
      already_submitted:false,
      invalid_login:false,
      showPassword: false,
      
    };
  },

  methods : {

    ...mapActions({
        signIn: "auth/login",
        
    }),
    buttonLabel() {
        return (this.showPassword) ? "Hide" : "Show";
    },

    toggleShow() {
      this.showPassword = !this.showPassword;
    },

    async submit() {
        
      this.already_submitted = true;

      //If the input login data is not valid we dont even try to log in
      if(!this.validEmail(this.email) || !this.validPassword(this.password)) {
         this.errorPass = true;
          return;
      }

      let form = {
        email:this.email,
        password:this.password,
      }
        try {
            const response = await this.signIn(form);
            this.$router.push('/');

        } catch(error) {
            this.invalid_login = true;
            console.error(error);
        }
      
    },

    validEmail(str) {
        var re = /(.+)@(.+){2,}\.(.+){2,}/;
        return str != '' && re.test(str); 
    },

    validPassword(str) {
        return str != null && str.length > 7;
    },

  }

};
</script>

