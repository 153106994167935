<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="Proposal.pdf"
      :pdf-quality="2"
      :quality="0.2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="800px"
      margin:0
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="m-0 p-0">
        <p>HOLA ESTO ES PARA TEST</p>
      </section>
    </vue-html2pdf>
    <a
      @click="generateReport()"
      class="flex justify-center mt-10 lg:mt-20 relative bottom-10 mx-auto"
    >
      <div
        class="
          h-26
          lg:h-32
          w-11/12
          mx-auto
          flex
          justify-center
          items-center
          btn-primary
          font-regular
          mt-10
          py-4
          pb-6
          text-xl
          lg:text-3xl
          rounded-full
          flex
          justify-center
          items-center
          mx-auto
          hover:text-gray-400
        "
      >
        <i class="fas fa-plus-circle color-text pr-3"></i>
      </div>
    </a>
    <div class="bg-white">
      <p>HOLA ESTO ES PARA TEST</p>
    </div>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
//import ProposalPdfVue from './ProposalPdf.vue';
export default {
  name: "testpdf",
  components: {
    VueHtml2pdf,
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>
<style lang="scss">
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
  vertical-align: middle;
}
</style>
