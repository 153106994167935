var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white"},[_vm._m(0),_c('div',{staticClass:"w-full",attrs:{"id":"firstStep"}},[_c('h3',{staticClass:"w-11/12 lg:w-3/12 mx-auto text-center text-lg lg:text-xl font-regular"},[_vm._v(" Please enter a name and select a brand for this quote ")]),_c('div',{staticClass:"form-group mt-4 w-10/12 mx-auto"},[_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-md ",attrs:{"for":"nameStep"}},[_vm._v("Name:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quote_name),expression:"quote_name"}],staticClass:"form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
          'form-control': true,
          'is-invalid': _vm.quote_name == null && _vm.already_submitted,
        },attrs:{"autocomplete":"off","type":"text","id":"nameStep"},domProps:{"value":(_vm.quote_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.quote_name=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Enter a name")]),_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-lg mt-3 lg:mt-8",attrs:{"for":"brandStep"}},[_vm._v("Brand:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.brand_id),expression:"brand_id"}],staticClass:"form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
          'form-control': true,
          'is-invalid': _vm.brand_id == null && _vm.already_submitted,
        },attrs:{"type":"select","id":"brandStep"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.brand_id=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.brands),function(brand){return _c('option',{key:brand.id,domProps:{"value":brand.id}},[_vm._v(" "+_vm._s(brand.name)+" ")])}),0),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Select type of quote")]),_c('label',{staticClass:"form-label inline-block mb-2 color-grey font-regular px-3 text-lg lg:text-lg mt-3 lg:mt-8",attrs:{"for":"quoteStep"}},[_vm._v("Quote type:")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.quote_type),expression:"quote_type"}],staticClass:"form-control block w-full px-3 py-2 text-lg font-normal text-gray-800 bg-transparent border-input rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none",class:{
          'form-control': true,
          'is-invalid': _vm.quote_type == null && _vm.already_submitted,
        },attrs:{"type":"select","id":"quoteStep"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.quote_type=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"hire_purchase"}},[_vm._v("Hire purchase")]),_c('option',{attrs:{"value":"lease"}},[_vm._v("Lease")])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Select a qutoe type")])]),_c('div',{staticClass:"fixed bottom-0 mt-10 w-full bg-secondary"},[_c('div',{staticClass:"w-11/12 lg:w-10/12 mx-auto flex justify-end items-center"},[_c('button',{staticClass:"btn-secondary text-lg lg:text-xl rounded-full py-1 lg:py-3 w-4/12 lg:w-2/12 my-3 focus:bg-transparent active:bg-transparent",attrs:{"id":"next"},on:{"click":_vm.submit}},[_vm._v(" Next "),_c('i',{staticClass:"fas fa-chevron-circle-right pl-3 color-text"})])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-11/12 mx-auto"},[_c('div',{staticClass:"progress-wrap mt-7 mx-auto"},[_c('div',{staticClass:"progress",attrs:{"id":"progress"}}),_c('div',{staticClass:"step active font-regular"},[_vm._v("1")]),_c('div',{staticClass:"step font-regular"},[_vm._v("2")]),_c('div',{staticClass:"step font-regular"},[_vm._v("3")])])])}]

export { render, staticRenderFns }