import * as API from "@/services/af-quote/API";

export default class BrandService {

    async create(form) {
        const data = await API.apiClient.post("/api/brands", form);
        return data.data.data;
    }

    async update(id, form) {
        const data = await API.apiClient.post("/api/brands/edit/" + id, form);
        return data.data.data;
    }

    async getUserBrands(id = undefined) {
        try {

            const data = await API.apiClient.get("/api/userbrand/" + (id != undefined ? id : ''));
            //console.log(data.data.data)
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async getBrand(id) {
        try {
            const data = await API.apiClient.get("/api/brands/" + id);
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async getAllBrands() {
        try {
            const data = await API.apiClient.get("api/brands/all");
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async getBrands(query = '') {
        try {
            const data = await API.apiClient.get("api/brands" + (query != '' ? '?' + query : ''));
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async addUserBrand(form) {
        try {
            let data = await API.apiClient.post("api/userbrand", form);
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async deleteUserBrand(user_id, brand_id) {
        try {
            let data = await API.apiClient.delete("api/userbrand/" + user_id + "/" + brand_id);
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async deleteBrand(brand_id) {
        try {
            let data = await API.apiClient.delete("api/brands/" + brand_id);
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

    async getImg(brand_id) {
        try {
            let data = await API.apiClient.get("api/brands/img/" + brand_id);
            return data.data.data;
        } catch (error) {
            return error;
        }
    }

};