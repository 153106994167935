import * as API from "@/services/company-house/API";

export default class CompanyHouseService {
  async getAllNamesCompanies(business_name) {
    try {
      let business_name_tourl = business_name;
      const data = await API.apiClient.get(
        "/search/companies?q=" + business_name_tourl
      );

      return data.data.items;
    } catch (error) {
      return error;
    }
  }

  async getDirectorsByCompanyNumber(company_number) {
    try {
      const data = await API.apiClient.get(
        "/company/" + company_number + "/officers"
      );
      let directorsData = [];
      data.data.items.forEach((element) => {
        //console.log(element.resigned_on);
        if (
          element.officer_role == "director" &&
          !element.resigned_on &&
          element.resigned_on == undefined
        ) {
          directorsData.push(element);
        }
      });
      return directorsData;
    } catch (error) {
      return error;
    }
  }

  async getPersonsWSC(company_number) {
    try {
      const data = await API.apiClient.get(
        "/company/" + company_number + "/persons-with-significant-control"
      );
      let directorsData = [];
      data.data.items.forEach((element) => {
        //console.log(element.resigned_on);
        if (!element.ceased && element.ceased == undefined) {
          directorsData.push(element);
        }
      });
      return directorsData;
    } catch (error) {
      return error;
    }
  }
}
